/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-use-before-define */
/* eslint-disable no-case-declarations */

import CryptoJS from 'crypto-js';
import _, { noop } from 'underscore';
import Dates from './dates/dates.es6';
// import { requireNonEmptyString } from './strings.es6';
import Strings from './strings.es6';
import {
  VarType,
  WorkflowConfig as Config,
  WorkflowConfigBuilder as ConfigBuilder,
  Target,
  DependencyScope,
  TopicDependency,
  KeyArrivalDependency,
  TimeoutType,
  Timeout,
  TaskedTimeout,
  Permission,
  ParameterSet,
  ParameterSetGroup,
} from './wf.js';
import BubbleType from './bubbletype.es6';
import MpFormulaStep, {
  MpFormulaStepType as StepType,
  isMpFormulaStep,
} from './mpfstep/mpfstep';
import Arrays from './arrays.es6';
import LinkType from './linktype.es6';
import { getUserName, getUserCompany } from '../../../../utils/authService';
import WorkflowSaveOperation, {
  WorkflowSaveFormulaContext,
  EventNames as WorkflowSaveEventNames,
  _saveConfig,
} from './mp/wf/save_operation';
import Objects, { requireObject } from './objects.es6';
import TimeZone from './timezone.es6';
import WorkflowVars from './wfvars.es6';
import ScriptVar from './script/scriptvar.es6';
import VarDataType from './script/vardatatype.es6';
import UserAction from './user_action.es6';
import UserActionTask from './user_action_task.es6';
import MpProductSel from './mpproductsel/mpproductsel.es6';
import * as CONSTANTS from './constants.es6';
import { extractTime } from './cronutils.es6';
import BubbleUtils from './BubbleUtils.es6';
import { isFunction, requireFunction } from './functions.es6';
import Numbers, {
  requireNumber,
  requireInteger,
  requireNonNegativeInteger,
  leadingZeros,
  isInteger,
} from './numbers.es6';
import { parameterSetKeyToString } from './keyutils.es6';
import MpUtils, { canUserMaintainFeeds } from './utils.es6';
import MpApi from './mpapi.es6';
import Console from './console.es6';
import Task from './task.es6';
import Text from './lang/mpdatawfgui_en-us.es6';
import Esprima from '../lib/esprima';

const ESyntax = Esprima.Syntax;
/* ***************************************************
* Private variables
* *************************************************** */
/**
 * @type {string}
*/
const _mgrIds = {};
const EOL = '\n';
const GRID_UNIT = 10;
const LINE_WIDTH = 3;
const LINE_SQUARE_WIDTH = 8;
const USER_DEF_MAX_LENGTH = 35;
const PENDING_BUBBLE_DROP = 'active-bubble-drop';
const PENDING_LINK = 'active-linking';
const BUBBLE_LINKING = 'linking';
const ACTIVE_LINE_CLASS = 'active';
const HIDDEN_CLASS = 'hidden';
const DATA_FORMULA_STEP = 'formula-step';
const MANAGER_KEY_PREFIX = 'MpDataWorkflowGui.Manager.';
const FORMULA_KEY = 'formula_id';
const SAVE_SRC_VAR = 'srcVar';
const SAVE_AS_CONTRACTS = 'asContracts';
const SAVE_DELIV_TYPE = 'delivType';
const SAVE_WITH_GAPS = 'withGaps';
const SAVE_CURVE_DATE_ADJ = 'curveDateAdj';
const SAVE_DEPENDENCY_EXPIRE_TIME = 'expire_time';
const SAVE_DEPENDENCY_EXPIRE_SET = 'expire_set';
const NOTIF_METHOD = 'protocol';
const NOTIF_SMTP_SUBJECT = 'subject';
const NOTIF_SMTP_MSG = 'msg';
const NOTIF_SMTP_TO = 'to';
const AMQP_TOPIC = 'topic';
const AMQP_PROPERTIES = 'props';
const VAR_RUN_DATE = '$RUN_DATE';
const FORM_ANALYZE_START = '/*&';
const FORM_ANALYZE_END = '*/';
const FORM_ANALYZE = 'ANALYZE';
const FORM_ANALYZE_SUSPEND = `${FORM_ANALYZE }_SUSPEND`;
const FORM_ANALYZE_RESUME = `${FORM_ANALYZE }_RESUME`;
const FORM_SECT_BUILT_IN_VARS = 'BUILT_IN_VARIABLES';
const FORM_SECT_USER_DEF_VARS = 'USER_DEFINED_VARIABLES';
const FORM_SECT_DATASETS_IN = 'INPUT_DATASET_DECLARATION';
const FORM_SECT_DATASETS_OUT = 'OUTPUT_DATASET_DECLARATION';
const FORM_SECT_BODY = 'FORMULA_BODY';
const FORM_SECT_SAVE = 'SAVE';
const TARGET_NAME_STOP = 'workflow_timeout';
const TARGET_NAME_COMPLETION = 'workflow.completion';
const ERR_PROP_START_STR = 'Message: {{formula.worker.script.err.msg|N/A}}';
const ERR_PROP_END_STR = '\nRun ID: {{workflow.run.id|N/A}}';
const ERR_PROP_STR = `${ERR_PROP_START_STR
}\n\nWorkflow Name: {{workflow.name}}`
        + '\nVariable Set: {{parameter-set.name}}'
        + '\nVariable Description: {{parameter-set.description}}'
        + '\n\n\nTechnical Details:'
        + '\nStatus: {{formula.worker.script.status|N/A}}'
        + '\nTimestamp: {{workflow.event_time|N/A}}'
        + '\nWorkflow ID: {{workflow.id|N/A}}'
        + `\nScript ID: {{uuid|N/A}}${
          ERR_PROP_END_STR}`;

const TOPIC_RUN_FORMULA = 'run.formula_script';
const TOPIC_FORMULA_COMPLETE = 'formula.worker.script.complete';
const TOPIC_SEND_EMAIL = 'email.workflow';
const TOPIC_WORKFLOW_STOP = 'workflow.stop';

let _canConstructBubble = false;
let _bubbleSeq = 0;
let _formulaSeq = 0;
let _canConstructLink = false;

const TOPIC_INDEX = _.indexBy([
  TOPIC_RUN_FORMULA,
  TOPIC_SEND_EMAIL,
  TOPIC_WORKFLOW_STOP,
]);

/** @returns {(string|int)} Returns the valueOf() of the given enum item. */
function _enumValueOf(enumItem) {
  return enumItem.valueOf();
}

/**
 * Creates a `BubbleType` filter which, given a Bubble,
 * returns a boolean for whether that bubble is of
 * one of the types given to this filter constructor.
 * @param {BubbleType} types
 * @returns {function}
 * @private
 */
function _bubbleTypeFilter(types) {
  const index = _.indexBy(arguments, _enumValueOf);
  return function (bubble) {
    const type = bubble.type();
    return (index[type.valueOf()] === type);
  };
}

/**
 * Function that returns whether the given bubble is of type FORMULA.
 * @type {function(Bubble):boolean}
 */
const FORMULA_FILTER = _bubbleTypeFilter(BubbleType.FORMULA);

/**
 * Function that returns whether the given bubble is of type FORMULA or QA.
 * @type {function(Bubble):boolean}
 */
const ALL_FORMULAS_FILTER = _bubbleTypeFilter(BubbleType.QA, BubbleType.FORMULA);

/**
 * Function that returns whether the given bubble is of type DATA or SINGLE_VAR.
 * @type {function(Bubble):boolean}
 */
const ALL_INPUTS_FILTER = _bubbleTypeFilter(BubbleType.DATA, BubbleType.SINGLE_VAR);

/**
 * Function that returns whether the given bubble is of type DATA or SAVE.
 * @type {function(Bubble):boolean}
 */
const ALL_PRODUCTS_FILTER = _bubbleTypeFilter(BubbleType.DATA, BubbleType.SAVE);

/**
 * Function that returns whether the given bubble is of type SAVE.
 * @type {function(Bubble):boolean}
 */
const SAVE_FILTER = _bubbleTypeFilter(BubbleType.SAVE);

/**
 * Function that returns whether the give bubble is od type DATA
 * @type {Function}
 */
const isData = _bubbleTypeFilter(BubbleType.DATA);

/** @type {string[]} */
const BTNS_OK_CANCEL = Object.freeze(['ok', 'cancel']);

/** @type {string[]} */
const BTNS_CLOSE = Object.freeze(['close']);

/** @type {string[]} */
const JS_FN_SAVE_LIST = Object.freeze([
  'save_series',
  'save_curve',
  'save_curve_with_gaps',
]);

/** @type {Object.<string, string>} */
const JS_FN_SAVE_MAP = Object.freeze(_.indexBy(JS_FN_SAVE_LIST));

const PRIV_PROPS_TO_SERIALIZE = [
  'name',
  'description',
  'tz',
  'duration',
  'isModified',
  'cronExpr',
  'psgBufUpdSeq',
];

/**
 * If `obj` has a property called `prop`, this method
 * returns that property's value.  Otherwise this method
 * returns `defaultVal`.
 * @param {Object} obj
 * @param {string} prop
 * @param {*} defaultVal
 * @returns {*}
 * @private
 */
function _if(obj, prop, defaultVal) {
  if (Object.hasOwnProperty.call(obj, prop)) return obj[prop];
  return defaultVal;
}

/**
 *
 * @param {string} name
 * @param {EnumItem} dataType
 * @param {string} code
 * @returns {ScriptVar}
 * @private
 */
function _newHardCodedVar(name, dataType, code) {
  const descr = _if(Text.Edit.Formula.HardCoded, name, '');
  return new ScriptVar(name, dataType, descr, code);
}

/**
 * A dictionary of known JS-formula parameters.
 * @namespace
 */
const JsFormulaParams = Object.freeze(/** @lends {JsFormulaParameters} */ {
  RUN_DATE: 'formula.run_date',
  DISABLE_SAVE: 'formula.is_saving_disabled',
  SCRIPT_NAME: 'formula.script_name',
  TIME_ZONE: 'workflow.time_zone',
  FORCE_LATEST_CURVE: 'formula.force_latest_curve',
});

// Delayed declaration of static variable to make use of `_if()` and `_safeLang()`.
/** @type {ScriptVar[]} */
const HARD_CODED_VARS = Object.freeze([
  _newHardCodedVar(
    '$TIME_ZONE',
    VarDataType.TIME_ZONE,
    `TimeZone.get(Parameters.getString('${JsFormulaParams.TIME_ZONE}'))`,
  ),
  _newHardCodedVar(VAR_RUN_DATE, VarDataType.DATE, 'get_run_date()'),
  _newHardCodedVar('$TODAY', VarDataType.DATE, 'today($TIME_ZONE)'),
  _newHardCodedVar('$NOW', VarDataType.DATE, 'now($TIME_ZONE)'),
]);

/**
 * User-action that lets users bypass a failed QA formula.
 * @type {UserAction}
 */
const USER_ACTION_APPROVE = new UserAction(
  'approve',
  Text.UserAction.approve,
  [
    new UserActionTask(
      'formula.worker.script.complete',
      { 'formula.worker.script.status': 'success' },
    ),
  ],
);

const PATT_VAR_NAME_USER_DEF = '[a-zA-Z][a-zA-Z_0-9]*';
const PATT_VAR_NAME_BUILT_IN = `\\$${ PATT_VAR_NAME_USER_DEF}`;

const REGEX_VAR_NAME_USER_DEF = new RegExp(`^${ PATT_VAR_NAME_USER_DEF }$`);
const REGEX_VAR_NAME_BUILT_IN = new RegExp(`^${ PATT_VAR_NAME_BUILT_IN }$`);

const REGEX_REL_DATE_VALUE = new RegExp('^([-+])(\\d+)([dDHm])$');
const REGEX_EXCEPT_HEADER = new RegExp('(Exception|Error):\\s*');

/**
 * Returns whether the given argument is void (null or undefined).
 * @param obj {*}
 * @returns {boolean}
 * @private
 */
function isVoid(obj) {
  return (typeof obj === 'undefined'
          || obj === null);
}

/**
 * Generates a UUID (a half-hearted attempt.)
 * @param {string} prefix
 * @returns {string}
 * @private
 */
function genuuid(prefix) {
  const e = CryptoJS.SHA1(`${prefix
  }@${ Dates.now().toString(10)
  }#${ getUserName()}`);
  return e.toString();
}

/**
 * Generates a new manager ID.
 * @returns {string}
 * @private
 */
function genMgrId(_mgrSeq) {
  return genuuid(`manager_${ (++_mgrSeq).toString(10)}`);
}

/**
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean} Whether the current user has Edit access to the workflow and
 *          whether the workflow was fully recognized by this UI.
 * @private
 */
function _canEdit(mgr) {
  const priv = mgr._priv;
  return (priv.isRecognized
    && (priv.config.permissions().contains(Permission.EDIT)
    || (priv.config.permissions().contains('formula_edit')
    || (priv.config.permissions().contains('input_edit')))));
}

/**
 * Checks that a manager can be modified, throws if it can't.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _checkCanEdit(mgr) {
  if (!_canEdit(mgr)) { throw new Error('IllegalStateException: workflow is read-only.'); }
}

/**
 * Reusable method for getting and setting data into an object.
 *
 * In setter mode, this method returns a boolean, for whether
 * the newly set value differs from the previous one.
 *
 * @param {Object.<string, *>} db
 * @param {string} name
 * @param {*} [val]
 * @returns {(boolean|*)} Getter returns the value associated with `name`;
 *          setter returns whether the value associated with `name` changed.
 * @private
 */
function _getsetData(db, name, val) {
  Strings.requireNonEmpty(name, 'name');
  if (arguments.length < 3) {
    if (Object.hasOwnProperty.call(db, name)) {
      return db[name];
    }
    return null;
  } if (typeof val === 'undefined') {
    throw new ReferenceError('val');
  } else {
    let isDiff = false;
    if (val === null) {
      isDiff = Object.hasOwnProperty.call(db, name);
      if (isDiff) {
        delete db[name];
      }
    } else {
      isDiff = (db[name] !== val);
      if (isDiff) {
        db[name] = val;
      }
    }
    return isDiff;
  }
}

/**
 * Throws an Error that describes how an RPC request didn't get its expected status code(s).
 * @param {string} expected
 * @param {int} actual
 * @private
 */
function _throwRpcStatus(expected, actual) {
  throw new Error([
    'Expected RPC results status code [', expected, '], ',
    'but got [', actual, '] instead.',
  ].join(''));
}

/**
* Validates a RPC response results status.
* If valid, this method returns the results `content`,
* otherwise it throws.
* @param {Object} result - {{ status: int, content: (Object|Array) }}
* @param {(int|int[])} expectedStatus - Expected status code(s).
* @private
*/
function _validRpcResults(result, expectedStatus) {
  if (!Object.hasOwnProperty.call(result, 'status')) { throw new Error('RPC response `result.status` is missing.'); }

  requireInteger(result.status, 'RPC response `result.status`');

  if (isInteger(expectedStatus)) {
    if (result.status !== expectedStatus) { _throwRpcStatus(expectedStatus, result.status); }
  } else if (Arrays.indexOf(result.status, expectedStatus) < 0) { _throwRpcStatus(expectedStatus.join(','), result.status); }

  return result.content;
}

/* *************************************************************
 * Process response from scheduler service by converting content to a ScheduledJob.
 * ************************************************************* */
function _processScheduledJobResponse(payload, expectedStatus) {
  /**
   * @type {{ hasOwnProperty: function, jobName: string, cronExpression: string,
   *          lastFireTime: string, nextFireTime: string, properties: Object }}
   */
  const content = _validRpcResults(payload, expectedStatus);

  requireObject(content, 'content');

  const requiredProps = ['cronExpression', 'jobName', 'properties'];
  for (let ii = 0; ii < requiredProps.length; ii++) {
    if (!content.hasOwnProperty(requiredProps[ii])) { throw new Error(`GetJobSchedule - missing property [${ requiredProps[ii] }].`); }
  }

  return new ScheduledJob(
    content.jobName,
    content.cronExpression,
    content.lastFireTime,
    content.nextFireTime,
    content.properties,
  );
}

/**
 * Validates an argument to be a Bubble instance.
 * If valid, this method returns that bubble.
 * Otherwise it throws an exception.
 * @param {Bubble} obj
 * @param {string} name
 * @returns {Bubble}
 * @private
 */
function _validBubble(obj, name) {
  if (!(obj instanceof Bubble)) { throw new TypeError(`${name }: Bubble`); }

  return obj;
}

/**
 * Adds the given error if `count > 0`, with "[count]" replaced with the actual
 * `count` argument.
 *
 * @param {string[]} errs
 * @param {string} text
 * @param {int} count
 * @private
 */
function _errIfCount(errs, text, count) {
  if (count > 0) { errs.push(text.replace('[count]', count.toString(10))); }
}

/**
 * Returns the Formula object associated with `bubble`.
 * @param {Bubble} bubble
 * @param {*} [defaultVal]
 * @returns {(Formula|*)}
 * @private
 */
function _getFormula(bubble, defaultVal) {
  const { formulas } = bubble._mgr._priv;
  const fuuid = bubble.data(FORMULA_KEY);

  if (Object.hasOwnProperty.call(formulas, fuuid)) {
    return formulas[fuuid];
  }
  if (arguments.length > 1) {
    return defaultVal;
  }
  throw new Error('IllegalArgumentException: bubble has no formula');
}

/**
 *
 * @param {Bubble} bubble
 * @param {*} fallbackValue
 * @returns {(string|*)}
 * @throws IllegalStateException - If `bubble` has no associated code
 *                                 and `fallbackValue` is not specified.
 * @private
 */
function _getFormulaUserCode(bubble, fallbackValue) {
  const f = _getFormula(bubble, null);

  if (f !== null
      && f.content !== null) {
    return f.content;
  } if (arguments.length > 1) {
    return fallbackValue;
  }
  throw new Error(`code not found for Formula/QA bubble:${ bubble.id()}`);
}

/**
 * Returns whether the given Identifier `node` represents a variable's declaration.
 * @param {Object} node - ESTree-compliant node.
 * @param {Object[]} path - Path of ESTree nodes leading to `node`, from immediate-parent (0)
 *                   to Program node (last).
 * @returns {boolean}
 * @private
 */
function _isVarDeclaration(node, path) {
  if (path.length < 1) {
    return false;
  }
  const parentNode = path[0];

  return ( // Declaration with "var"
    (parentNode.type === ESyntax.VariableDeclarator
                  && parentNode.id === node)

    // Declaration without "var"
              || (parentNode.type === ESyntax.AssignmentExpression
                  && parentNode.left === node)

    // Function argument
              || (parentNode.type === ESyntax.FunctionExpression
                  && Arrays.indexOf(node, parentNode.params) >= 0));
}

/**
 * Returns a list of Identifier nodes used but not declared within `astTop`.
 * @param {Object} astTop - ESTree-compliant AST.
 * @returns {Object[]} Array of ESTree-compliant nodes.
 * @private
 */
function _getUndeclaredRefs(astTop) {
  /*
   * Looking for references to variables that
   * have not been declared according to the running
   * call stack.
   *
   * Looking for references in function arguments as well as
   * objects on which methods are being called.
   */

  const undeclaredIds = [];

  const stack = new WorkflowVars.Stack();
  stack.push();

  const declarationConsumer = WorkflowVars.visitorOfDeclaredVar((scriptVar) => {
    stack.setVar(scriptVar);
  }, null);

  const simpleAssignConsumer = WorkflowVars.visitorOfGlobalVarAssignments((scriptVar) => {
    // Add variable in global context
    stack.bottomFrame().set(scriptVar);
  }, null);


  WorkflowVars.walkAst(astTop,

    // enter callbacks
    {

      /** Entering a function declaration. */
      FunctionExpression(node) {
        // new call frame
        stack.push();

        // add declared arguments to new frame
        for (let i = 0; i < node.params.length; i++) {
          stack.setVar(ScriptVar.fromCode(node.params[i].name, `arguments[${ i.toString(10) }]`));
        }
      },

      VariableDeclaration(node) {
        declarationConsumer(node);
      },

      AssignmentExpression(node) {
        simpleAssignConsumer(node);
      },

      Identifier(node) {
        // Found identifier.
        // Check that this isn't the identifier's declaration.

        if (!_isVarDeclaration(node, this.path())
                  && stack.get(node.name, null) === null) {
          undeclaredIds.push(node);
        }
      },
    },

    // leave callbacks
    {
      /** Leaving a function declaration. */
      FunctionExpression() {
        // pop current call frame
        stack.pop();
      },
    });

  return undeclaredIds;
}

/**
 * Returns a list of Identifier nodes representing built-in variables
 * used but not declared within `astTop`.
 * @param {Object} ast - ESTree-compliant AST.
 * @returns {Object[]} Array of ESTree-compliant nodes,
 *          all with names that start with '$'.
 * @private
 */
function _getUndeclaredBuiltInRefs(ast) {
  // all undeclared references
  const undefVars = _getUndeclaredRefs(ast);

  // only built-in variables that start with '$'
  return undefVars.filter(node => REGEX_VAR_NAME_BUILT_IN.test(node.name));
}

/**
 * Returns a formula header, including analyzer version.
 * @private
 */
function _formulaHeader() {
  return [
    `${FORM_ANALYZE_START }ANALYZE_VERSION 1.0${ FORM_ANALYZE_END}`,
    '',
    '// ///////////////////////////////////////////////////////////////////////',
    '// DO NOT MODIFY THIS FILE; IT IS MAINTAINED BY MARKETS WORKFLOW WIDGET.',
    '// ///////////////////////////////////////////////////////////////////////',
  ].join(EOL);
}

/**
 * Returns the block header of a given section of code.
 * @param {string} name
 * @returns {string}
 */
function _formulaSectionBlockHeader(name) {
  return `${FORM_ANALYZE_START + FORM_ANALYZE_SUSPEND } ${ name }${FORM_ANALYZE_END}`;
}

/**
* Returns the block footer of a given section of code.
* @param {string} name
* @returns {string}
*/
function _formulaSectionBlockFooter(name) {
  return `${FORM_ANALYZE_START + FORM_ANALYZE_RESUME } ${ name }${FORM_ANALYZE_END}`;
}

/**
 * Returns a parsable section of code within a formula.
 * @param {string} name
 * @param {string} content
 * @private
 */
function _formulaSection(name, content) {
  return [
    _formulaSectionBlockHeader(name),
    '',
    content,
    '',
    _formulaSectionBlockFooter(name),
  ].join(EOL);
}

/**
 * Returns the section of code that contains built-in variables.
 * @returns {string}
 * @private
 */
function _formulaBuiltInVars() {
  return _formulaSection(
    FORM_SECT_BUILT_IN_VARS,
    HARD_CODED_VARS.map(hcVar => `var ${hcVar.name()} = ${hcVar.code()};`).join(EOL),
  );
}

/**
 * Adds variable(s) associated with current bubble.
 * @param {(Dataset[]|SingleVar[])} list
 * @param {Bubble} bubble
 * @param {function} varGetter
 * @private
 */
function _addBubbleVars(list, bubble, varGetter) {
  const v = varGetter(bubble);
  if (v !== null) { list.push(v); }
}

/**
 * Adds variable found upstream.
 * @param {(Dataset[]|SingleVar[])} list
 * @param {Bubble} bubble
 * @param {function} varGetter
 * @returns {(Dataset[]|SingleVar[])}
 *                      Returns the given `list` argument, to which orphan variables
 *                      have been added.
 * @private
 */
function _addUpstreamVars(list, bubble, varGetter) {
  _addBubbleVars(list, bubble, varGetter);

  bubble._ins.forEach((link) => {
    _addUpstreamVars(list, link._src, varGetter);
  });

  return list;
}

/**
 * Adds orphan variables.
 * @param {(Dataset[]|SingleVar[])} list
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {function} varGetter
 * @param {function(Bubble):boolean} filterCondition
 * @returns {(Dataset[]|SingleVar[])}
 *                      Returns the given `list` argument, to which orphan variables
 *                      have been added.
 * @private
 */
function _addOrphanVars(list, mgr, varGetter, filterCondition) {
  _.chain(mgr._priv.bubbles)
    .filter(filterCondition)
    .each((bubble) => {
      if (bubble._ins.length === 0
          && bubble._outs.length === 0) {
        _addBubbleVars(list, bubble, varGetter);
      }
    });

  return list;
}

/**
 * Filters `list` to only include variables referenced in user-code.
 * @param {(Dataset[]|SingleVar[])} list
 * @param {function} doInclude
 * @returns {(Dataset[]|SingleVar[])} `list` without unused variables.
 * @private
 */
function _filterUsedVars(list, doInclude) {
  return list.filter(dsOrVar => doInclude(dsOrVar.getScriptName()));
}

/**
 * Returns a Dataset or SingleVar JS declaration.
 * @param {(Dataset|SingleVar)} dsOrVar
 * @returns {string}
 * @private
 */
function _toVarDeclaration(dsOrVar) {
  return dsOrVar.getScriptDeclaration();
}

/**
 * Returns a section of code that declares input variables
 * (Datasets or SingleVars).
 * @param {string} sectionName
 * @param {Bubble} bubble
 * @param {function} varGetter
 * @param {function} doInclude
 * @private
 */
function _formulaInputs(sectionName, bubble, varGetter, doInclude) {
  const mgr = bubble._mgr;
  let list = _addUpstreamVars([], bubble, varGetter);

  _addOrphanVars(list, mgr, varGetter, ALL_INPUTS_FILTER);

  list = _filterUsedVars(list, doInclude);

  return _formulaSection(sectionName, list.map(_toVarDeclaration).join(EOL + EOL));
}

/**
 * Returns the index where the bubble's variable is located
 * (Dataset or SingleVar) within the manager's list.
 * Returns -1 if the bubble isn't associated with a variable yet.
 *
 * @param {Bubble} bubble
 * @param {(Dataset[]|SingleVar[])} list
 * @returns {int}
 * @private
 */
function _getVarIndex(bubble, list) {
  return list.findIndex(l => l._bbl._id === bubble._id);
}

/**
 * Gets the variable associated with the given bubble (Dataset or SingleVar).
 * @param {Bubble} bubble
 * @param {(Dataset[]|SingleVar[])} list
 * @returns {?(Dataset|SingleVar)} May be `null`.
 * @private
 */
function _getVar(bubble, list) {
  const idx = _getVarIndex(bubble, list);
  if (idx >= 0) return list[idx];
  return null;
}

/**
 * Gets the single-var associated with the given bubble.
 * @param {Bubble} bubble
 * @returns {?SingleVar} May be `null`.
 * @private
 */
function _getSingleVar(bubble) {
  return _getVar(bubble, bubble._mgr._priv.singles);
}

/**
 * Returns the section of code that declares user-defined variables.
 * @param {Bubble} bubble
 * @param {function} doInclude
 * @returns {string}
 * @private
 */
function _formulaUserVarsInputs(bubble, doInclude) {
  return _formulaInputs(FORM_SECT_USER_DEF_VARS, bubble, _getSingleVar, doInclude);
}

/**
 * Gets the Dataset associated with the given bubble.
 * @param {Bubble} bubble
 * @returns {?Dataset} May be `null`.
 * @private
 */
function _getDataset(bubble) {
  return _getVar(bubble, bubble._mgr._priv.datasets);
}

/**
 * Returns the section of code that declares the input products.
 * @param {Bubble} bubble
 * @param {function} doInclude
 * @returns {string}
 * @private
 */
function _formulaDatasetInputs(bubble, doInclude) {
  return _formulaInputs(FORM_SECT_DATASETS_IN, bubble, _getDataset, doInclude);
}


/**
 * @param {Bubble} formulaBubble Formula bubble.
 * @returns {Bubble[]} Array of Save bubbles, direct descendents of the given formula bubble, may be empty.
 * @private
 */
function _getSaveBubbles(formulaBubble) {
  return formulaBubble._outs.map(link => link._target)
    .filter(target => (target.type() === BubbleType.SAVE));
}

/**
 * Returns the bubble and their dataset that represent *save* instructions.
 * @param {Bubble} formulaBubble
 * @returns {Array.<{bubble: Bubble, dataset: Dataset}>}
 * @private
 */
function _getSaveInfo(formulaBubble) {
  return _getSaveBubbles(formulaBubble).map(target => ({
    bubble: target,
    dataset: _getDataset(target),
  }))
    .filter(rv => (rv.dataset !== null));
}

/**
 * Returns the section of code that declares the output products.
 * @param {Bubble} bubble
 * @returns {string}
 * @private
 */
function _formulaDatasetOutputs(bubble) {
  const saveInfo = _getSaveInfo(bubble);
  const datasets = saveInfo.map(o => o.dataset);
  const content = datasets.map(_toVarDeclaration).join(EOL + EOL);

  return _formulaSection(FORM_SECT_DATASETS_OUT, content);
}

/**
 * Returns the JavaScript code around a date object in order
 * to give it the chosen adjustment.
 *
 * @param {string} relDateVal - Relative-date value.
 * @param {string} dateVarName - Name of the date variable.
 * @returns {string}
 * @private
 */
function _getRelDateCode(relDateVal, dateVarName) {
  const regex = REGEX_REL_DATE_VALUE; // ^([\-\+])(\d+)([dDHm])$
  const match = regex.exec(relDateVal);

  if (match === null) throw new Error(`IllegalStateException: relative-date value is unrecognized (${ relDateVal })`);

  const dir = match[1];
  let offset = match[2];
  const unit = match[3];

  if (offset === '0') return dateVarName; // no adjustment


  if (dir === '-') offset = dir + offset;

  switch (unit) {
    case 'd': // days
      return `${dateVarName }.addDays(${ offset })`;

    case 'D': // business days
      return `add_business_days(${ dateVarName }, ${ offset })`;

    case 'H': // hours
      return `${dateVarName }.addHours(${ offset }).withZone($TIME_ZONE)`;

    case 'm': // minutes
      return `${dateVarName }.addMinutes(${ offset }).withZone($TIME_ZONE)`;

    default:
      throw new Error(`IllegalStateException: unrecognized relative-date unit (${ relDateVal })`);
  }
}

/**
 * Returns the section of code that saves products.
 * @param {Bubble} bubble
 * @returns {string}
 * @private
 */
function _formulaSave(bubble) {
  const content = _getSaveInfo(bubble).map((saveInfo) => {
    const bbl = saveInfo.bubble;
    const ds = saveInfo.dataset;
    const srcVar = bbl.data(SAVE_SRC_VAR);
    const firstTwoArgs = `${srcVar }, ${ ds.getScriptName()}`;


    if (bbl.data(SAVE_AS_CONTRACTS) === true) {
      const delivType = bbl.data(SAVE_DELIV_TYPE);
      const withGaps = bbl.data(SAVE_WITH_GAPS);
      const curveDateAdj = bbl.data(SAVE_CURVE_DATE_ADJ);

      return `save_curve${ (withGaps === true) ? '_with_gaps' : ''
      }(${ firstTwoArgs
      }, ${ _getRelDateCode(curveDateAdj, VAR_RUN_DATE)
      }, '${ delivType }'`
              + ', true' // always pass `partial_update=true`
              + ');';
    }

    return `save_series(${ firstTwoArgs
    }, true` // always pass `partial_update=true`
              + ');';
  }).join(EOL);

  return _formulaSection(FORM_SECT_SAVE, content);
}

/**
 * Returns a validated formula's AST, or null if not valid.
 * A formula is valid if it has syntax that compiles, with
 * no *forbidden* operations (such as saving data.)
 * @param {string} code
 * @param {Bubble} bubble
 * @param {lim.Window} win
 * @param {?jQuery} textarea - HTMLTextAreaElement
 * @returns {?Object} ESTree-compliant object, or null.
 * @private
 */
function _getValidUserFormulaAST(code, bubble, win, textarea) {
  const TextV = Text.Edit.Formula.Validation;

  if (!Strings.isNonEmpty(code)) {
    // _showCodeErrorInTextarea(win, TextV.isEmpty, 0, textarea);
    return null;
  }

  let astTopLevel;
  try {
    // Check the syntax
    astTopLevel = WorkflowVars.jsToAst(code);
  } catch (ex) {
    // Syntax error in formula
    // _showCodeErrorInTextarea(win, ex.message, ex.index, textarea);
    return null;
  }

  // Check for calls to save functions within FORMULA_BODY section.
  const saveFnIdx = _getSaveFunctionIndex(astTopLevel);
  if (saveFnIdx >= 0) {
    if (bubble.type() !== BubbleType.FORMULA) {
      // _showCodeErrorInTextarea(win, TextV.saveNotAllowedInBubbleType, saveFnIdx, textarea);
      return null;
    }
  }

  return astTopLevel;
}

/**
 * Generates the full code for a formula bubble.
 * @param {Bubble} formulaBubble
 * @param {boolean} includeAllVars - Whether to include all upstream variables even
 *        if they're not referenced.
 * @returns {string}
 * @private
 */
function _getFullSavedFormulaCode(formulaBubble, includeAllVars) {
  if (!(formulaBubble instanceof Bubble)) {
    throw new TypeError('formulaBubble: bubble');
  }

  const formula = _getFormula(formulaBubble, null);
  if (formula === null || formula.content === null) {
    return '';
  }
  return _getFullFormulaCode(
    formulaBubble,
    formula.content,
    WorkflowVars.jsToAst(formula.content),
    includeAllVars,
  );
}


/**
 * Generates the full code for a formula bubble.
 * @param {Bubble} formulaBubble
 * @param {string} userCode - User code (aka formula body)
 * @param {Object} userCodeAST - ESTree-compliant AST of `userCode`
 * @param {boolean} includeAllVars - Whether to include all upstream variables even
 *        if they're not referenced.
 * @returns {string}
 * @private
 */
function _getFullFormulaCode(formulaBubble, userCode, userCodeAST, includeAllVars) {
  const undefBuiltIns = _getUndeclaredBuiltInRefs(userCodeAST);
  const udefIndexed = _.indexBy(undefBuiltIns, node => node.name);
  const doInclude = function (name) {
    return (includeAllVars || Object.hasOwnProperty.call(udefIndexed, name));
  };

  return [
    '', _formulaHeader(),
    '', _formulaBuiltInVars(),
    '', _formulaUserVarsInputs(formulaBubble, doInclude),
    '', _formulaDatasetInputs(formulaBubble, doInclude),
    '', _formulaDatasetOutputs(formulaBubble),
    '', _formulaSection(FORM_SECT_BODY, userCode),
    '', _formulaSave(formulaBubble),
    '',
  ].join(EOL);
}

function _getFullFormulaCodeWithoutAST(formulaBubble, userCode, includeAllVars, vue) {
  const undefBuiltIns = _getUndeclaredBuiltInRefs(WorkflowVars.jsToAst(userCode));
  const udefIndexed = _.indexBy(undefBuiltIns, node => node.name);
  const doInclude = function (name) {
    return (includeAllVars || Object.hasOwnProperty.call(udefIndexed, name));
  };

  return [
    '', _formulaHeader(),
    '', _formulaBuiltInVars(),
    '', _formulaUserVarsInputs(formulaBubble, doInclude),
    '', _formulaDatasetInputs(formulaBubble, doInclude),
    '', _formulaDatasetOutputs(formulaBubble),
    '', _formulaSection(FORM_SECT_BODY, userCode),
    '', _formulaSave(formulaBubble, vue),
    '',
  ].join(EOL);
}
/**
 * Returns the index position where user code starts within full formula,
 * after FORMULA_BODY header.
 * @param {string} fullCode
 * @returns {int} Returns -1 if FORMULA_BODY header is not found.
 * @private
 */
function _formulaBodyStartIdx(fullCode) {
  const hdr = _formulaSectionBlockHeader(FORM_SECT_BODY);
  const idx = fullCode.indexOf(hdr);

  if (idx < 0) return idx;

  // This is based on `_formulaSection()` method, above.
  return idx + hdr.length + (EOL.length * 2);
}

/**
 * Returns an Error object similar to those thrown by `esprima.parse()`.
 * @param {string} msg
 * @param {int} index
 * @param {string} srcCode
 * @returns {Error}
 * @private
 */
function _newError(msg, index, srcCode) {
  // TODO: show error msg
  const coord = Strings.getCursorPosition(srcCode, index);
  const err = new Error(`Line ${ coord.row }: ${ msg}`);

  err.index = index;
  err.column = coord.col;
  err.lineNumber = coord.row;

  return err;
}

/**
 * Returns the text of a JS_Parse_Error object, without the stack.
 * @param {Error} jsParseErr
 * @returns {string}
 * @private
 */
function _jsParseErrorToString(jsParseErr) {
  return jsParseErr.message;
}

/**
 * Sets the error status of a bubble.
 * @param {Bubble} bubble
 * @param {boolean} hasError
 * @private
 */
function _setBubbleError(bubble, hasError, vue, operation) {
  // console.log('bubble has error', hasError, bubble, vue);
  if (hasError && vue) {
    if (!vue.badFormulaBubbles.includes(bubble._id)) {
      vue.badFormulaBubbles.push(bubble._id);
    }
    vue.showError = true;
    vue.errorMessage = `${vue.badFormulaBubbles.length } bubble have error`;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (vue) {
      if (vue.badFormulaBubbles && vue.badFormulaBubbles.length > 0) {
        const index = vue.badFormulaBubbles.indexOf(bubble._id);
        if (index > -1) {
          if (vue.nodes) {
            vue.nodes[`${bubble._id}`].data.hasError = false;
          }
          vue.badFormulaBubbles.splice(index, 1);
          vue.errorMessage = '';
        }
      }
    }
  }
  if (operation === 'edit') {
    vue.braodcastFormulaErrorMsg();
  }
}

/**
 * Sets the error status of a save bubble, based on
 * whether its source variable is valid.
 * @param {Bubble} saveBubble
 * @param {Object.<string, ScriptVar>} availVars
 * @returns {boolean} Whether `saveBubble` references an unrecognized
 *                    variable.
 * @private
 */
function _setSaveErrorFlag(saveBubble, availVars, vue, operation) {
  // console.log('saveBubble, availVars', saveBubble, availVars);
  const srcVar = saveBubble.data(SAVE_SRC_VAR);
  const isBadVar = (Strings.isNonEmpty(srcVar)
                  && !Object.hasOwnProperty.call(availVars, srcVar));
  _setBubbleError(saveBubble, isBadVar, vue, operation);

  return isBadVar;
}

/**
 * Return the first Error encountered while scanning a formula's full code
 * for bad syntax, undeclared built-in variables, etc.  (Built-in
 * variables represent Data, SingleVar or Publish bubbles.)
 *
 * @param {Bubble} bubble
 * @param {string} [code] - A formula's full code or user-code.
 *                 If not provided, this method dynamically computes
 *                 the full formula code.
 * @param {boolean} [isUserCode=false] - Whether `code` represents a
 *                  formula's user-code (true) or full code (false).
 * @returns {?Error} The first error found within a formula's full code,
 *          or null.
 * @private
 */
function _getFirstFormulaError(bubble, code, isUserCode, vue) {
  let userCode = null;
  let userCodeAst = null;
  let fullCode = null;
  const numArgs = arguments.length;

  if (numArgs < 3) {
    isUserCode = false;
  }

  if (numArgs >= 2
      && isUserCode === false) {
    fullCode = code;
  } else {
    // `fullCode` not provided, compute its value.
    if (numArgs >= 2) {
      userCode = code;
    } else {
      userCode = _getFormulaUserCode(bubble, '');
    }
    try {
      userCodeAst = WorkflowVars.jsToAst(userCode);
    } catch (ex) {
      // An error occurred while parsing user-code, return that error instead.
      return ex;
    }
    fullCode = _getFullFormulaCode(bubble, userCode, userCodeAst, false);
  }

  let astTopLevel;
  try {
    astTopLevel = WorkflowVars.jsToAst(fullCode);
  } catch (ex) {
    return ex;
  }

  const errorNodes = _getUndeclaredBuiltInRefs(astTopLevel);
  if (errorNodes.length > 0) {
    // Consider that "formula body" is the only visible section;
    // Adjust token index accordingly.
    const errNode = errorNodes[0];
    const errIdx = errNode.range[0];
    const userCodeIdx = _formulaBodyStartIdx(fullCode);
    const userCode1 = fullCode.substring(userCodeIdx);
    const adjErrIdx = Math.max(0, errIdx - userCodeIdx);

    if (errNode) {
      if (vue) {
        vue.errorMessage = _newError(`Undeclared variable ${ errNode.name}`, adjErrIdx, userCode1);
      }
    }

    return _newError(`Undeclared variable ${ errNode.name}`, adjErrIdx, userCode1);
  }
  return null;
}

/**
 * Scan for references to save functions - such as save_series(), save_curve(),
 * timeSeries.save(), timeSeries.saveAsContracts() - and returns the index
 * of the first reference found, or -1 if none found.
 *
 * This function also checks for situations where the `save` and `saveAsContracts`
 * functions of a timeSeries object would be assigned to variables, in case users
 * try to circumvent a basic check.
 *
 * @param {Object} astTopLevel - ESTree-compliant object.
 * @returns {int} Returns -1 if no reference to a save function is found.
 * @private
 */
function _getSaveFunctionIndex(astTopLevel) {
  let saveFnIdx = -1;

  const chkSaveMemberExpr = function (node) {
    if (node !== null
          && node.type === 'MemberExpression') {
      const p = node.property;
      if (p.type === ESyntax.Identifier
              && (p.name === 'save' // ts.save(...) -or- ts.save
                  || p.name === 'saveAsContracts' // ts.saveAsContracts(...) -or- ts.saveAsContracts
                  || p.name === 'saveAsContractsWithGaps')) { // ts.saveAsContractsWithGaps(...) -or- ts.saveAsContractsWithGaps
        [saveFnIdx] = p.range;
        return true;
      }
    }

    return false;
  };

  WorkflowVars.walkAst(astTopLevel, {

    /**
       * Look for save_series(), save_curve(), timeSeries.save(), timeSeries.saveAsContracts().
       * @param node
       */
    CallExpression(node) {
      const c = node.callee;
      if (c.type === ESyntax.Identifier) {
        if (Object.hasOwnProperty.call(JS_FN_SAVE_MAP, c.name)) {
          [saveFnIdx] = node.range;
          this.stop();
        }
      } else if (chkSaveMemberExpr(c)) this.stop();
    },

    /**
       * Look for something like `var s = timeSeries.save;`.
       * The risk is, a subsequent statement could then call `s(...)`
       * to execute a save command.
       */
    VariableDeclarator(node) {
      if (chkSaveMemberExpr(node.init)) this.stop();
    },

    /**
       * Similar to above except without "var " prefix,
       * look for something like `s = timeSeries.save;`.
       */
    AssignmentExpression(node) {
      if (chkSaveMemberExpr(node.right)) this.stop();
    },

  });

  return saveFnIdx;
}

/**
 * Returns a list of unique values found throughout the
 * parameter-set-group for variable `paramName`.
 * @param {Object[]} psgBuf
 * @param {int} firstRow Non-negative.
 * @param {int} lastRow Non-negative.
 * @param {string} paramName
 * @returns {string[]}
 * @private
 */
function _uniqParamVal(psgBuf, firstRow, lastRow, paramName) {
  return _.chain(Arrays.slice(psgBuf, firstRow, lastRow + 1))
    .pluck(paramName)
    .filter(Strings.isNonEmpty)
    .uniq(false)
    .value();
}

/**
 * Returns the index where the bubble's single-var is located
 * within the manager's list.  Returns -1 if the
 * bubble isn't associated with a single-var yet.
 * @param {Bubble} bubble
 * @returns {int}
 * @private
 */
function _getSingleVarIndex(bubble) {
  return _getVarIndex(bubble, bubble._mgr._priv.singles);
}

/**
 * Returns the index where the bubble's dataset is located
 * within the manager's Dataset list.  Returns -1 if the
 * bubble isn't associated with a Dataset yet.
 * @param {Bubble} bubble
 * @returns {int}
 * @private
 */
function _getDatasetIndex(bubble) {
  return _getVarIndex(bubble, bubble._mgr._priv.datasets);
}

/**
 * @param {Object.<string, *>} ps - Parameter-set.
 * @returns {boolean} Whether the given parameter set contains any value.
 * @private
 */
function _isNonEmptyParamSet(ps) {
  const props = Object.keys(ps);
  const numProps = props.length;

  // "priv.uuid" is not something users can control; if "priv.uuid" is
  // the sole property, we consider that parameter-set to be empty.

  return (numProps > 1
          || (numProps === 1
              && props[0] !== CONSTANTS.PARAM_SET_UUID));
}

/**
 * Creates a hash key from all values within a parameter set,
 * for the purpose of finding duplicates.
 * @param {Object} ps
 * @returns {string}
 * @private
 */
function _paramSetHash(ps) {
  const props = Objects.properties(ps, true);
  Arrays.remove(CONSTANTS.PARAM_SET_UUID, props);
  Arrays.remove(CONSTANTS.PARAM_SET_NAME, props);
  Arrays.remove(CONSTANTS.PARAM_SET_DESCR, props);
  return props.map(prop => `[${prop}=${ps[prop]}]`).join(',');
}

/**
 * Validates the paramter-set-group of a manager,
 * inserting any and all errors into `errs`.
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string[]} errs
 * @private
 */
function _validateMgrPsg(mgr, errs) {
  const TextV = Text.Validation;
  const priv = mgr._priv;
  const psgBuf = priv.psgBuffer.filter(_isNonEmptyParamSet);

  // Check: at least one parameter-set.
  if (psgBuf.length === 0) { errs.push(TextV.noParamSet); } else {
    // Check: parameter-set without names.
    const namedPs = psgBuf.filter(ps => Strings.isNonEmpty(ps[CONSTANTS.PARAM_SET_NAME]));

    _errIfCount(errs, TextV.paramSetWithoutName, psgBuf.length - namedPs.length);

    // Check: incomplete parameter-sets (only check the named ones since `name` is part of the error message.

    namedPs.forEach((ps) => {
      const incompleteDatasets = priv.datasets.filter(dataset => (
        CONSTANTS.DATASET_SUFFIXES.some(suffix => !Object.hasOwnProperty.call(ps, dataset.paramPrefix() + suffix))
      ));
      const incompleteSingleVars = priv.singles.filter(singleVar => !Object.hasOwnProperty.call(ps, singleVar.paramName()));

      if (incompleteDatasets.length > 0
              || incompleteSingleVars.length > 0) {
        const fullList = [];
        Arrays.addAll(fullList, incompleteDatasets);
        Arrays.addAll(fullList, incompleteSingleVars);

        const varList = fullList.map(dsOrVar => dsOrVar.getScriptName());
        errs.push(TextV.paramSetIncomplete.replace('[name]', ps[CONSTANTS.PARAM_SET_NAME])
          .replace('[var_list]', varList.join(', ')));
      }
    });

    // Check: duplicate parameter-sets (only check the named ones since `name` is part of the error message.

    const dup = _.filter(
      _.groupBy(namedPs, _paramSetHash),
      group => (group.length > 1),
    );

    if (dup.length > 0) {
      Arrays.addAll(errs, dup.map((group) => {
        const names = _.map(group, ps => ps[CONSTANTS.PARAM_SET_NAME]);

        return TextV.paramSetDuplicate.replace('[group]', names.join(', '));
      }));
    }
  }
}

/**
 * @param {Bubble} bubble
 * @param {Dataset} dataset
 * @returns {MpProductSel.Type[]} List of product types, to restrict product selection.
 * @private
 */
function _getProdTypeFilter(bubble, dataset) {
  const MpProdType = MpProductSel.Type;

  switch (bubble.type()) {
    case BubbleType.SAVE:
      const isCurve = (bubble.data(SAVE_AS_CONTRACTS) === true);
      const prodType = ((isCurve) ? MpProdType.ROOTS : MpProdType.KEYS);

      return [prodType];

    case BubbleType.DATA:

      // This is a hacked-up way of determining the type of product.
      // Ultimately, product type should be stored in the bubble itself, similar to SAVE.

      if (dataset === null) { return Arrays.EMPTY; }

      const mgr = bubble._mgr;
      const psgBuf = mgr._priv.psgBuffer;
      const keyProp = dataset.paramPrefix() + CONSTANTS.DATASET_SUFFIX_KEY_ROOTS;
      const uniqKeys = _uniqParamVal(psgBuf, 0, Math.max(0, psgBuf.length - 1), keyProp);
      const firstChars = uniqKeys.map(k => k.charAt(0));
      const uniqFirsts = _.uniq(firstChars, false);

      return uniqFirsts.map((firstChar) => {
        switch (firstChar) {
          case '[': return MpProdType.ROOTS;
          case '{': return MpProdType.KEYS;
          default:
            throw new Error(`IllegalStateException: unrecognized key type ('${ firstChar }')`);
        }
      });

    default:
      throw new Error(`IllegalArgumentException: unsupported bubble type (${ bubble.type().toString() })`);
  }
}

/**
 * Exported for unit-testing only.
 * @param {int} expireTime Expiration time, in millis-since-midnight.
 * @param {int} wakeupTime Time a workflow wakes up, in millis-since-midnight.
 * @param {int} duration Time a workflow keeps running, in millis-since-midnight.
 * @returns {boolean} Whether `expireTime` is within a workflows up-time.
 */
function $isExpirationWithinUpTime(expireTime, wakeupTime, duration) {
  // We want to make sure that dependency-timeouts are within workflow's scheduled up-time.
  // Also, it's possible that Workflow Worker might be behind schedule due to being overloaded
  // with work.  To make this feature more robust, we prevent users from setting timeouts within
  // 2 minute of the workflow start time.

  // One more: formulas take a while to run; data also takes a while to be processed.  Each can take
  // a few seconds to a few minutes.  We also force users to schedule their expiration at least 2 minutes
  // from the scheduled shutdown, to give a reasonable chance for formulas, TS workers, ATDB workers to complete.
  const START_TOLERANCE_MILLIS = 2 * Dates.MILLIS_PER_MINUTE;
  const END_TOLERANCE_MILLIS = 2 * Dates.MILLIS_PER_MINUTE;

  const shutdownTime = wakeupTime + duration;
  let expTime = expireTime;

  if (shutdownTime >= Dates.MILLIS_PER_DAY
      && expireTime < wakeupTime) {
    // If workflow starts before midnight and ends the next day,
    // AND dependency timeout is set before wake-up time, we assume
    // timeout is meant for next day.
    expTime = expireTime + Dates.MILLIS_PER_DAY;
    // Workflow starts and ends within the same day.
  }
  return (expTime >= wakeupTime + START_TOLERANCE_MILLIS
          && expTime <= shutdownTime - END_TOLERANCE_MILLIS);
}


/**
 * Validate that Data bubbles with a dependency timeout will trigger within the workflow's scheduled up-time.
 * @param {string[]} errs Error queue.
 * @param {string} cronExpr Cron expression.
 * @param {int} duration Workflow duration in millis.
 * @param {Bubble[]} bubbles All bubbles within the manager.
 * @private
 */
function _validateDataDependencyTimeouts(errs, cronExpr, duration, bubbles) {
  const TextV = Text.Validation;
  const wakeupTime = extractTime(cronExpr);

  bubbles
    .filter(_bubbleTypeFilter(BubbleType.DATA))
    .filter(bubble => (bubble.data(SAVE_DEPENDENCY_EXPIRE_SET) === true))
    .filter(bubble => (bubble.data(SAVE_DEPENDENCY_EXPIRE_TIME) !== ''))
    .forEach((bubble) => {
      const ds = _getDataset(bubble);
      const expireTime = Dates.stringToTime(bubble.data(SAVE_DEPENDENCY_EXPIRE_TIME), null);
      if (expireTime === null) {
        // This is just a sanity check, but should never happen.
        errs.push(TextV.noDataExpTime.replace('[name]', ds.getScriptName()));
      } else if (!$isExpirationWithinUpTime(expireTime, wakeupTime, duration)) {
        errs.push(TextV.outOfBoundExpireTime.replace('[name]', ds.getScriptName()));
      }
    });
}

/**
 * Validates the given manager for whether its ready to be saved, and
 * returns all error(s) found.
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {string[]}
 * @private
 */
function _validateMgr(mgr, vue) {
  const TextV = Text.Validation;
  const priv = mgr._priv;
  const bubbles = Object.values(priv.bubbles);
  const errs = [];

  // Check: user has named this workflow.
  if (!Strings.isNonEmpty(priv.name)) {
    errs.push(TextV.noName);
  }

  // Check: user has set a schedule.
  if (priv.cronExpr === null) {
    errs.push(TextV.noSchedule);
  }

  // Check: user has set a max duration.
  if (priv.duration <= 0) {
    errs.push(TextV.noDuration);
  }

  // Check: all *task* bubbles have a trigger.
  const taskBubbles = bubbles.filter(_bubbleTypeFilter(
    BubbleType.QA,
    BubbleType.FORMULA,
    BubbleType.SAVE,
    BubbleType.NOTIFICATION,
    BubbleType.AMQP_TASK,
  ));
  _errIfCount(errs, TextV.freeStanding, taskBubbles.filter(bubble => (bubble._ins.length === 0)).length);

  // Check: all formula bubbles have some code attached to them.
  let formulaBubbles = bubbles.filter(ALL_FORMULAS_FILTER);
  _errIfCount(errs, TextV.emptyFormulas, formulaBubbles.filter((bubble) => {
    const formula = _getFormula(bubble, null);
    return (formula === null
              || !Strings.isNonEmpty(formula.content));
  }).length);

  // Check: All formulas contain valid JS, no bad-ref error, etc.
  Arrays.addAll(errs, formulaBubbles
    .map(b => _getFirstFormulaError(b))
    .filter(e => !isVoid(e))
    .map(_jsParseErrorToString));

  let cntExplicitSaves = 0;

  // Check: all Publish bubbles have valid variable references
  formulaBubbles = bubbles.filter(FORMULA_FILTER);
  _errIfCount(errs, TextV.saveHasBadVariable, formulaBubbles.reduce((memo, formulaBubble) => {
    const code = _getFormulaUserCode(formulaBubble, '');
    let topLevel; let
      availVars;

    try {
      topLevel = WorkflowVars.jsToAst(code);
      availVars = WorkflowVars.getVariablesAtLevel(topLevel, code);
    } catch (ex) {
      // JS parsing error, report it.
      errs.push(_jsParseErrorToString(ex));
      availVars = {}; // Continue with validation, causing all dependent *Save* bubbles to show errors.
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const target of _getSaveBubbles(formulaBubble)) {
      // console.log('in validatemanager');
      if (_setSaveErrorFlag(target, availVars, vue)) {
        memo++;
      }
    }
    if (_getSaveFunctionIndex(topLevel) >= 0) {
      cntExplicitSaves++;
    }
    return memo;
  }, 0));

  // Check: all Var bubbles have a SingleVar.
  const varBubbles = bubbles.filter(_bubbleTypeFilter(BubbleType.SINGLE_VAR));
  _errIfCount(errs, TextV.missingSingleVar, varBubbles.filter(bubble => (_getSingleVarIndex(bubble) < 0)).length);

  // Check: all Data and Publish bubbles have a Dataset.
  const dsBubbles = bubbles.filter(ALL_PRODUCTS_FILTER);
  _errIfCount(errs, TextV.missingDataset, dsBubbles.filter(bubble => (_getDatasetIndex(bubble) < 0)).length);

  // Check: all Data and Publish Datasets are have identical product type throughout the parameter-sets.
  dsBubbles.forEach((bubble) => {
    const ds = _getDataset(bubble);
    const prodTypes = _getProdTypeFilter(bubble, ds);

    if (prodTypes.length > 1) {
      errs.push(TextV.datasetConflict.replace('[name]', ds.getScriptName())
        .replace('[bubble_type]', bubble._type.text()));
    }
  });

  // Check: all Notification bubbles have a protocol.
  const notifBubbles = bubbles.filter(_bubbleTypeFilter(BubbleType.NOTIFICATION));
  _errIfCount(errs, TextV.missingNotif, notifBubbles.filter(bubble => (bubble.data(NOTIF_METHOD) === null)).length);

  const amqpBubbles = bubbles.filter(_bubbleTypeFilter(BubbleType.AMQP_TASK));
  _errIfCount(errs, TextV.missingAmqpInfo, amqpBubbles.filter(bubble => (bubble.data(AMQP_TOPIC) === null
              || bubble.data(AMQP_PROPERTIES) === null)).length);

  // Validating parameter-set-group.
  // _validateMgrPsg(mgr, errs);

  if (priv.cronExpr !== null) {
    // We already have an error if `cronExpr == null`, but this additional validation (here)
    // can only proceed if we have a schedule.

    // Validate that Data bubbles with a dependency timeout will trigger within the workflow's scheduled up-time.
    _validateDataDependencyTimeouts(errs, priv.cronExpr, priv.duration, bubbles);
  }

  // Check: there's at least one Publish or Notification bubble, or an explicit
  // call to `save_curve()` or `save_series()`.
  const operationBubbleFilter = _bubbleTypeFilter(
    BubbleType.SAVE,
    BubbleType.NOTIFICATION,
    BubbleType.AMQP_TASK,
  );
  if (!bubbles.some(operationBubbleFilter) && cntExplicitSaves === 0) {
    errs.push(TextV.doesNothing);
  }

  return errs;
}

/**
 * Generates a new formula ID.
 * @returns {string}
 * @private
 */
function _genFormulaId() {
  return _genuuid(`formula_${ (++_formulaSeq).toString(10)}`);
}

/**
 * Creates a new, registered Formula object and associates it with
 * the given bubble. If bubble already is already associated
 * with a Formula, this method throws an exception.
 *
 * @param {Bubble} bubble
 * @returns {Formula}
 * @private
 */
function _newFormula(bubble) {
  const mgr = bubble._mgr;
  const fId = bubble.data(FORMULA_KEY);

  if (fId !== null) { throw new Error('IllegalStateException: two formulas for same bubble'); }

  const id = _genFormulaId();
  const formula = _newFormulaObj(mgr, id);

  formula.isNew = true;

  bubble.data(FORMULA_KEY, id);

  return formula;
}

/**
 * @param {Bubble} bubble Formula bubble being validated.
 * @param {lim.Window} win Window that owns the bubble, used to display errors or prompt for more info.
 * @param {jQuery} textbox JQuery wrapper of a TEXTAREA element.
 * @returns {?string} Validated user-code, null if not valid.
 * @private
 */
function _getValidatedFreeformCode(bubble, win, code, vue) {
  // const code = textbox.val();
  const analyzeInstrIdx = code.indexOf(FORM_ANALYZE_START + FORM_ANALYZE);
  if (analyzeInstrIdx >= 0) {
    // TODO: show error in textbox
    console.error('_showCodeErrorInTextarea', code);
    vue.showFormulaValidationBanner = true;
    // _showCodeErrorInTextarea(
    //   win,
    //   Text.Edit.Formula.ValidationFreeform.foundAnalyzeInstr,
    //   analyzeInstrIdx,
    //   textbox,
    // );
    return null;
  }
  return _getValidatedUserCode(bubble, code, win, null, (err) => {
    // _showCodeErrorInTextarea(win, err.message, err.index, textbox);
  }, vue);
}

/**
 * Callback for when user closes freeform Formula Edit dialog-box.
 * @param {string} btnName "ok", "cancel"
 * @param {{ mgr: MpDataWorkflowGui.Manager,
*           bubble: Bubble,
*           ui: {
*               top: jQuery,
*               varTable: jQuery,
*               textbox: jQuery,
*               statusBar: jQuery,
*               cursor: jQuery
*           } }} data
* @returns {boolean} Returns *false* if dialog-box must remain open.
* @private
*/
function _editFormulaFreeformCB(btnName, data) {
  const { mgr } = data;
  const { ui } = data;
  const win = mgr._win;
  const { bubble } = data;
  const { textbox } = ui;

  if (btnName === 'preview') {
    const code = _getValidatedFreeformCode(bubble, win, textbox);
    if (code === null) {
      return false;
    }
    _promptFormulaPreview(bubble, win, code);
    return false; // prevents dialog-box from closing itself.
  } if (btnName === 'ok') {
    const code = _getValidatedFreeformCode(bubble, win, textbox);
    if (code === null) {
      return false;
    }
    if (!_saveFormulaCodeInBubble(code, bubble, ui, mgr)) {
      return false;
    }
    _setBubbleError(bubble, false);
  }

  // Delay in case an error occurs, preventing the dialog-box from closing
  // after everything is done.
  Functions.delay(() => {
    ui.top.remove();
  });

  return true;
}

/**
 * Set the bubble's formula code and assigns necessary flags.
 * @param {string} code
 * @param {Bubble} bubble
 * @param {Object} ui
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean}
 * @private
 */
function _saveFormulaCodeInBubble(code, bubble, mgr, bubbleName, bubbleDescr, vue, bubbleType) {
  let formula = _getFormula(bubble, null);

  if (formula === null) { formula = _newFormula(bubble); }

  formula.content = code;
  formula.isModified = true;

  // https://jira01.lim.com/browse/CMD-1302:
  // The formula will be saved with a different uuid
  // so we set config as modified so it is saved and references the new uuid.
  mgr._priv.isModified = true;

  bubble.data(CONSTANTS.BUBBLE_NAME, bubbleName);
  bubble.data(CONSTANTS.BUBBLE_DESCR, bubbleDescr);

  // bubble.text(bubbleName, bubbleDescr);

  // _notifChg(mgr);
  if (bubbleType === 'formulaBubble') {
    _scanSaveErrors(bubble, vue, 'edit');
    const err = _getFirstFormulaError(bubble);
    _flagFormulaError(bubble, (err !== null), vue, 'edit');
  } else if (bubbleType === 'qaBubble') {
    const err = _getFirstFormulaError(bubble);
    _flagFormulaError(bubble, (err !== null), vue, 'edit');
  }

  return true;
}


/**
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {WorkflowSaveFormulaContext[]} Formula context for modified formulas, for the purpose of saving them.
 * @private
 */
function _getModifiedFormulasToSave(mgr) {
  return Object.values(mgr._priv.bubbles)
    .filter(ALL_FORMULAS_FILTER)
    .filter(bubble => _getFormula(bubble).isModified)
    .map(bubble => new WorkflowSaveFormulaContext(
      _getFullSavedFormulaCode(bubble, false),
      { bubble, formula: _getFormula(bubble) },
    ));
}

/**
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean} Whether the schedule has changed, needs to be saved.
 * @private
 */
function _isNewSchedule(mgr) {
  const priv = mgr._priv;
  return (
    priv.job === null
      || priv.job.cronExpression() !== priv.cronExpr
      || priv.config === null
      || !priv.config.isPersisted()
      || priv.config.timezone() !== priv.tz
      || _isModifiedPsg(mgr, true)
  );
}

/**
 * Generates the JS code formula from a list of MpFormulaStep objects.
 * @param {{stepTable: jQuery}} ui
 * @param {function} mappingFn - Mapping function, to convert a step into (string) code.
 * @returns {string} Entire JS formula *body* section.
 * @private
 */
function _genFormulaCode(steps, mappingFn) {
  const codeA = steps.map(mappingFn);

  return codeA.join(EOL + EOL);
}

/**
 *
 * @param {Bubble} bubble
 * @param {string} userCode
 * @param {lim.Window} win
 * @param {jQuery} textbox
 * @param {Function.<Error>} errorHighlighter
 * @returns {?string} `userCode`, null if invalid.
 * @private
 */
function _getValidatedUserCode(bubble, userCode, win, textbox, errorHighlighter, vue) {
  const ast = _getValidUserFormulaAST(userCode, bubble, win, textbox);
  if (ast === null) {
    return null;
  }

  const err = _getFirstFormulaError(bubble, _getFullFormulaCode(bubble, userCode, ast, false), true, vue);
  if (err !== null) {
    errorHighlighter(err);
    return null;
  }
  return `${userCode.trim()}\n`;
}

/**
 * Generates the user-code from Formula UI and returns it if
 * it passes validation.  If validation fails, this method
 * returns `null`.
 *
 * Validation errors are shown to the user via UI indicators
 * (red background, red font, etc.)
 *
 * @param {MpDataWorkflowGui.FormulaTemplatesUI} ui - Context object for template Formula UI.
 * @returns {?string} Validated user-code or `null`.
 * @private
 */
function _getValidatedFormulaUiCode(bubble, steps) {
  // _clearCodeErrorInUI(ui);

  // const { win } = ui;
  const code = _genFormulaCode(steps, (step) => {
    const name = `${step.type().toString()
    } ${
      step.name()}`;
    return _formulaSection(name, step.scriptSnippet());
  });
  return _getValidatedUserCode(bubble, code, null, null, (err) => {
    // TODO: show error in Vue UI
    // _showCodeErrorInUI(win, ui, err, code);
  });
}

function checkPsgNeedUpdate(manager) {
  const updateModel = manager._priv.updateParameterSetData;

  if (updateModel.add && updateModel.add.length > 0) {
    return true;
  }
  if (updateModel.update && updateModel.update.length > 0) {
    return true;
  }
  if (updateModel.delete && updateModel.delete.paramIds && updateModel.delete.paramIds.length > 0) {
    return true;
  }
  if (updateModel.delete && updateModel.delete.psgIds && updateModel.delete.psgIds.length > 0) {
    return true;
  }
  if (updateModel.psgToRename && updateModel.psgToRename.length > 0) {
    return true;
  }
  return false;
}

/**
 * Check what's *modified*, builds necessary payload and
 * submit these payloads, in sequence, until everything is saved.
 *
 * The order of things being saved are:
 * 1) parameter-set-group - because it's harmless;
 * 2) formulas - new ones are created each time, for two reasons:
 *     a) running workflows are not affected;
 *     b) current user is not getting a failure trying to update
 *        a formula owned by someone else.
 * 3) workflow config - after formulas because it needs formula UUIDs;
 * 4) schedule - after workflow config and PSG, because it needs both IDs.
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {function} callback
 * @private
 */
async function _saveNow(mgr, vue) {
  // *Wait* state is already set, don't set it again.
  // We do need to clear it once the operation completes, however.

  const win = mgr._win;
  const priv = mgr._priv;
  const saveOper = new WorkflowSaveOperation(priv.name, (() => _buildConfig(mgr, false, vue)));

  saveOper.setFormulas(_getModifiedFormulasToSave(mgr));
  saveOper.setParameterSetGroup(
    _isModifiedPsg(mgr, true)
      ? _buildPsg(mgr)
      : priv.paramSetGroup,
  );
  if (_isNewSchedule(mgr)) {
    saveOper.setSchedule(priv.cronExpr);
  }

  // Save formulas first
  await vue.saveFormula(saveOper);

  if (vue.manager.deletedRunIds > 0) {
    await vue.stopRunningJobs();
  }
  // save psg if modified
  if (checkPsgNeedUpdate(vue.manager)) {
    await vue.updateInputs();
  }

  // save wf config
  const savedWfConfig = await _saveConfig(saveOper, saveOper._psg, vue);

  if (!isVoid(saveOper._cronExpr) && !isVoid(savedWfConfig)) {
    // save schedule
    await vue.saveSchedule(savedWfConfig, saveOper._cronExpr, savedWfConfig.psgId);
  }

  // fetch status
  // await vue.fetchWorkflow(savedWfConfig.id);
  // vue.makeMarketsManager();
  // vue.manager._priv.updateParameterSetData = {
  //   currentPsgVersion: vue.workflowParameters.workFlowJobModel.psgVersion,
  //   add: [],
  //   update: [],
  //   psgToRename: [],
  //   delete: {
  //     psgIds: [],
  //     paramIds: [],
  //   },
  // };
  vue.resetWorkflowData(savedWfConfig.id);
  // vue.$emit('resetWorkflowEdit', savedWfConfig.id);
  // vue.showLoader = false;
  // saveOper.events
  //   .bind(
  //     WorkflowSaveEventNames.ERROR,
  //     /** @param {string} errorMsg */
  //     (errorMsg) => {
  //       win.error(errorMsg);
  //     },
  //   )
  //   .bind(
  //     WorkflowSaveEventNames.SAVED_FORMULA,
  //     /** @param {WorkflowSaveFormulaContext} formulaContext */
  //     (formulaContext) => {
  //       const newUuid = formulaContext.getUuid();
  //       /** @type {{formula: Formula, bubble: Bubble}} */
  //       const localCtx = formulaContext.getContext();
  //       const { formula } = localCtx;
  //       const { bubble } = localCtx;
  //       const prevUuid = formula.id;

  //       formula.id = newUuid;
  //       formula.isNew = false;
  //       formula.isModified = false;

  //       // Reset bubble's pointer from new formula uuid.
  //       bubble.data(FORMULA_KEY, newUuid);

  //       delete priv.formulas[prevUuid];
  //       priv.formulas[newUuid] = formula;
  //     },
  //   )
  //   .bind(
  //     WorkflowSaveEventNames.SAVED_PARAMETER_SET_GROUP,
  //     /** @param {ParameterSetGroup} psg */
  //     (psg) => {
  //       priv.paramSetGroup = psg;
  //       priv.psgBufUpdSeqSaved = priv.psgBufUpdSeq; // Indicate that we match the server.
  //     },
  //   )
  //   .bind(
  //     WorkflowSaveEventNames.SAVED_SCHEDULE,
  //     /** @param {ScheduledJob} job */
  //     (job) => {
  //       priv.job = job;
  //     },
  //   )
  //   .bind(
  //     WorkflowSaveEventNames.SAVED_WORKFLOW,
  //     /** @param {WorkflowConfig} wfConfig */
  //     (wfConfig) => {
  //       priv.config = wfConfig;
  //     },
  //   );

  // saveOper.execute((isSuccess) => {
  //   win.wait(false);
  //   if (isSuccess) {
  //     priv.isModified = false;
  //     callback(mgr, priv.config);
  //   }
  // });
}

/**
 * Callback for checking the uniqueness of a workflow name.
 * @param {(int|ServerError|WorkflowConfig[])} ownedList
 * @private
 */
function _configNameUniqueCB(ownedList, vue) {
  const { mgr } = this;
  const priv = mgr._priv;
  const win = mgr._win;
  const nameLC = priv.name.toLowerCase();
  const { callback } = this;

  if (_isHandledError(ownedList, win)) {
    callback(mgr);
  } else if (ownedList.some(config => (config.name.toLowerCase() === nameLC && config.id !== priv.config._wf._id))) {
    vue.showLoader = false;
    // TODO: show duplicate name error
    // win.warn(Text.Validation.nameIsTaken.replace('[name]', priv.name));
    callback(mgr, priv.config);
  } else {
    _saveNow(mgr, vue);
  }
}

/**
 * Initiates the save sequence.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {function} callback
 * @returns {boolean} Whether *save* operation was successfully initiated.
 * @private
 */
async function _save(mgr, errors, vue) {
  const TextV = Text.Validation;
  const win = mgr._win;
  // convert max duration milliseconds to minutes and compare with intra day interval
  const maxDur = (mgr.duration() / 60000);
  const minsArr = mgr._priv.cronExpr.split(' ')[1].split('/');
  if (minsArr[1] && minsArr[1] < maxDur) {
    errors.push('Max duration cannot be greater than intra day interval');
    return false;
  }
  if (!_canEdit(mgr)) {
    // Caller should be aware not to call this method, but as a last resort,
    // if they do call us, we abort anyway.
    win.warn(Text.cantSaveReadOnlyWorkflow);
    return false;
  }

  const errs = _validateMgr(mgr, vue);

  if (errs.length > 0) {
    // We may need to truncate this message if it gets beyond 40-line long (or something like that.)
    errors.push(TextV.header.replace('[errors]', `\t- ${ errs.join('\n\t- ')}`));
    return false;
  }

  const messages = BubbleUtils.hasOverwriteData(mgr._priv.datasets, mgr._priv.psgBuffer);
  if (messages) {
    const errorMsg = messages['error'] || '';
    const warnMsg = messages['warn'] || '';

    if (errorMsg.length) {
      errors.push(...errorMsg);
      return false;
    }

    if (warnMsg.length) {
      errors.push(...warnMsg);
    }
  }

  vue.showLoader = true;

  if (Strings.equalsIgnoreCase(mgr._priv.config.owner(), getUserName())) {
    // Ensure workflow name is unique within owner's list before saving.
    const ownedWfList = (await vue.getAllWorkflowsForDiagram()).data
      .filter(config => Strings.equalsIgnoreCase(config.owner, getUserName()));
    _configNameUniqueCB.bind({ mgr, callback: noop })(ownedWfList, vue);
  } else {
    _saveNow(mgr, vue);
  }

  return true;
}

/**
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {boolean} treatNewAsModified Whether to treat a new workflow as modified.
 * @returns {boolean} Whether the manager's parameter-set-group has been modified.
 * @private
 */
function _isModifiedPsg(mgr, treatNewAsModified) {
  const priv = mgr._priv;
  return (priv.psgBufUpdSeq > priv.psgBufUpdSeqSaved
          || (priv.paramSetGroup === null
              && treatNewAsModified === true));
}

/**
 * Finds the workflow "duration" within the Timeout target.
 * If the Timeout target cannot be found, or is not of type DURATION,
 * or the `after` parameter cannot be parsed, this method returns zero (0).
 * @param {WorkflowConfig} config
 * @returns {int} Workflow max. duration, in milliseconds.
 * @private
 */
function _getTimeoutDuration(config) {
  let duration = 0;
  const target = config.target(TARGET_NAME_STOP, null);

  if (target !== null) {
    const timeout = target.timeout();
    if (timeout !== null
          && timeout.type() === TimeoutType.DURATION
          && timeout.contains('after')) {
      duration = Dates.stringToTime(timeout.parameter('after'), 0);
    }
  }

  return duration;
}

/**
 * Returns whether the workflow config information - name, timezone -
 * have been modified.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean}
 * @private
 */
function _isModifiedConfig(mgr) {
  // Not worrying about case where `config === null`
  // because so far, config is already provided.

  const priv = mgr._priv;
  const { config } = priv;

  return (config !== null
          && (priv.name !== config.name()
              || priv.tz !== config.timezone()
              || priv.duration !== _getTimeoutDuration(config)));
}

/**
 * Returns whether a formula has been modified by user.
 * @param {Formula} formula
 * @returns {boolean}
 * @private
 */
function _isModifiedFormula(formula) {
  return formula.isModified;
}

/**
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean} Whether the given manager has been modified by user.
 * @private
 */
function _isModifiedMgr(mgr) {
  const priv = mgr._priv;
  return (priv.isModified
          || _isModifiedPsg(mgr, false)
  // || _isModifiedSchedule(mgr)
          || _isModifiedConfig(mgr)
          || Object.values(priv.formulas).some(_isModifiedFormula)
  );
}

/**
 * Returns a manager ID unique (to this user).
 * @returns {string}
 * @private
 */
function newMgrId() {
  let id = genMgrId(1);
  while (Object.hasOwnProperty.call(_mgrIds, id)) { id = genMgrId(); }

  _mgrIds[id] = true;

  return id;
}

/**
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {Bubble} bubble
 * @param {boolean} isActive
 * @private
 */
function _setActiveBubble(mgr, bubble, isActive) {
  const { activeBubbles } = mgr._priv;

  if (!isActive) {
    bubble._elm.removeClass('active');
    Arrays.remove(bubble, activeBubbles);
  } else if (Arrays.addIfMissing(bubble, activeBubbles)) { bubble._elm.addClass('active'); }
}

/**
 * Creates a ghost (HTML element) bubble of the given type. This ghost can
 * be used to follow the mouse as a user decides where to *drop* that bubble.
 * @param {BubbleType} bubbleType
 * @returns {jQuery} A jQuery wrapper around an HTMLElement object.
 * @private
 */
function _newGhost(bubbleType) {
  return null;
  // return $('<div>').addClass('mpdatawfgui-ghost bubble').addClass(bubbleType.valueOf())
  //   .appendTo($('body'))
  //   .text(bubbleType.text());
}

/**
 * Deactivates the manager's only active link, if applicable.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean} Whether there was an active link that got
 *                    deactivated.
 * @private
 */
function _deactivateLink(mgr) {
  const priv = mgr._priv;
  const { activeLink } = priv;

  if (activeLink === null) return false;


  activeLink.isActive(false);
  priv.activeLink = null;
  return true;
}

/**
 * Deactivates all bubbles within a manager.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _deactivateBubbles(mgr) {
  const activeList = Arrays.removeAll(mgr._priv.activeBubbles);
  for (let i = 0; i < activeList.length; i++) { _setActiveBubble(mgr, activeList[i], false); }

  // Called here for convenience.
  _deactivateLink(mgr);
}

/**
 * Returns whether we have a framework that allows us to persist
 * some object's state.
 * @returns {boolean}
 * @private
 */
function _canPersist() {
  return Objects.hasRuntimeObj('userSettings');
}

/**
 * Sets basic info from the workflow config object.
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {WorkflowConfig} config
 * @private
 */
function _setBasicFromConfig(mgr, config) {
  const priv = mgr._priv;
  const tz = config.timezone();

  priv.name = config.name();
  priv.tz = ((tz !== null) ? tz : TimeZone.getDefault());
  priv.description = config.description();
}

/**
 * @param {(KeyArrivalDependency|*)} dependency
 * @returns {boolean} Whether `dependency` is a KeyArrivalDependency object.
 * @private
 */
function _isRecognizedKADependency(dependency) {
  return (dependency instanceof KeyArrivalDependency);
}

/**
* @param {(TopicDependency|*)} dependency
* @returns {boolean} Whether `dependency` TopicDependency recognized by this UI.
* @private
*/
function _isRecognizedTopicDependency(dependency) {
  if (!(dependency instanceof TopicDependency)) return false;


  const topic = dependency.topic();
  return (topic === TOPIC_FORMULA_COMPLETE
              || Strings.startsWith(topic, 'filearrival.delta.')); // legacy dependency
}

/**
 * @param {Dependency} dependency
 * @returns {boolean} Whether `dependency` is fully recognized by this UI.
 * @private
 */
function _isRecognizedDependency(dependency) {
  return (_isRecognizedKADependency(dependency)
          || _isRecognizedTopicDependency(dependency));
}

/**
 * @param {WorkflowConfig} config
 * @returns {boolean} Whether any of the targets found in `config` are unrecognized by this UI.
 * @private
 */
function _hasUnrecognizedTargets(config) {
  const ui = config.ui();

  return !config.targets().every(
    /** @param {Target} target */
    (target) => {
      if (!target.dependencies().every(_isRecognizedDependency)) {
        return false;
      }

      return target.tasks().every(
        /** @param {Task} task */
        (task) => {
          if (Object.hasOwnProperty.call(TOPIC_INDEX, task.topic())) return true;

          return ui.bubbles.some(bubble => (bubble.type === BubbleType.AMQP_TASK.valueOf()
                                  && bubble.props.topic === task.topic()
                                  && Objects.areEqual(bubble.props.props,
                                    task.properties())));
        },
      );
    },
  );
}

/**
 * Analyzes the payload to see if it represents a server error.
 * If so, it shows the error to the user (in the given widget)
 * and returns true.  Otherwise, if not a server error,
 * this method returns false.
 *
 * @param {Object} payload
 * @param {lim.Window} win
 * @returns {boolean}
 * @private
 */
function _isHandledError(payload, win) {
  const rv = MpUtils.isHandledError(payload, win);

  if (rv) { win.wait(false); }

  return rv;
}

/**
 * @param {?string} uuid
 * @param {string} name
 * @param {string} descr
 * @returns {Object} New POJO with default properties for parameter-set uuid, name, descr.
 * @private
 */
function _newParamSetObj(uuid, name, descr) {
  const o = {};
  o[CONSTANTS.PARAM_SET_UUID] = uuid;
  o[CONSTANTS.PARAM_SET_NAME] = name;
  o[CONSTANTS.PARAM_SET_DESCR] = descr;

  return o;
}

/**
 * Converts a parameter-set to a plain object,
 * as is used locally.
 * @param {ParameterSet} ps
 * @returns {Object}
 * @private
 */
function _psToPlain(ps) {
  return Object.assign(
    _newParamSetObj(
      ps.uuid(),
      ps.name(),
      ps.description(),
    ),
    ps.parameters(),
  );
}

/**
 * Converts a parameter-set-group to an array of plain objects,
 * as is used locally.
 * @param {ParameterSetGroup} psg
 * @returns {Object[]}
 * @private
 */
function _psgToPlain(psg) {
  return psg.parameterSets().map(_psToPlain);
}

/**
 * Loads a ParameterSetGroup into the manager's buffer.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {ParameterSetGroup} psg
 * @private
 */
function _loadPsg(mgr, psg) {
  const priv = mgr._priv;

  priv.paramSetGroup = psg;
  priv.psgBuffer = _psgToPlain(psg);
}

/**
 * Checks whether the instance as *ready*.  That is,
 * all load requests have arrived.  If ready,
 * this method sends a *ready* event.
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {boolean} Whether the instance is ready.
 * @private
 */
function _checkReady(mgr) {
  const priv = mgr._priv;
  priv.loadCnt++;

  const isReady = (priv.loadCnt >= priv.loadReq);
  if (isReady) {
    // small delay, to let caller do post processing.
    // Functions.delay(() => {
    //   mgr.events.send('ready', mgr);
    // });
  }

  return isReady;
}

/**
 * Generates a UUID (a half-hearted attempt.)
 * @param {string} prefix
 * @returns {string}
 * @private
 */
function _genuuid(prefix) {
  const e = CryptoJS.SHA1(`${prefix
  }@${ Dates.now().toString(10)
  }#${ getUserName()}`);
  return e.toString();
}

/**
 * Returns a unique bubble ID.
 * @return {string}
 * @private
 */
function _newBubbleId() {
  return _genuuid(`bubble_${ (++_bubbleSeq).toString(10)}`);
}

/**
 * Creates a new bubble within this manager.
 * The bubble is not visible until it is given coordinates.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {BubbleType} type
 * @param {string} [id] The bubble's UUID.
 * @returns {Bubble}
 */
function _newBubble(mgr, type, id) {
  if (id === null) {
    id = _newBubbleId();

    // Just to be 100% safe, in case of SHA-1 collision...
    while (Object.hasOwnProperty.call(mgr._priv.bubbles, id)) { id = _newBubbleId(); }
  }

  _canConstructBubble = true;
  const bubble = new Bubble(mgr, id, type);

  mgr._priv.bubbles[id] = bubble;

  return bubble;
}

/**
 * Restores a list of persisted bubbles.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {SerializedBubble[]} serializedBubbles
 * @private
 */
function _restoreBubbles(mgr, serializedBubbles) {
  serializedBubbles.forEach((bubbleInfo) => {
    const type = BubbleType.valueOf(bubbleInfo.type);
    const { coord } = bubbleInfo;
    const bubble = _newBubble(mgr, type, bubbleInfo.id);

    Object.assign(bubble._data, bubbleInfo.props);

    // LIMM-7797: Adding name field and description tooltip to QA and Formula bubbles.
    const bubbleName = bubble.data(CONSTANTS.BUBBLE_NAME);
    const bubbleDescr = bubble.data(CONSTANTS.BUBBLE_DESCR);

    if (Strings.isNonEmpty(bubbleName)) {
      bubble.text(bubbleName, bubbleDescr);
    }

    // bubble.centerAt(coord.y, coord.x);
  });
}


/* ***************************************************
 * Class: Link
 * *************************************************** */
/**
 * @constructor
 * @name Link
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {Bubble} source
 * @param {Bubble} target
 * @param {LinkType} linkType
 */
function Link(mgr, source, target, linkType, linkId) {
  if (!_canConstructLink) { throw new Error('UnsupportedOperationException: private constructor'); }

  // Allow just one instance to be constructed.
  _canConstructLink = false;

  this._mgr = mgr;
  this._src = _validBubble(source, 'source');
  this._target = _validBubble(target, 'target');
  this._type = LinkType.valueOf(linkType);
  this._linkId = linkId;
  // this._line = new Line(mgr, linkType)
  //   .startAt(source.centerY(), source.centerX())
  //   .endAt(target.centerY(), target.centerX());

  source._outs.push(this);
  target._ins.push(this);

  Object.freeze(this);
}

/**
* Removes a link.
* @param {Link} link
* @private
*/
function _removeLink(link) {
  Arrays.remove(link, link._src._outs);
  Arrays.remove(link, link._target._ins);
  Arrays.remove(link, link._mgr._priv.links);
}

Link.prototype = /** @lends Link.prototype */ {
  constructor: Link,

  /**
   * Returns the *source* bubble.
   * @returns {Bubble}
   */
  source() { return this._src; },

  /**
   * Returns the *target* bubble.
   * @returns {Bubble}
   */
  target() { return this._target; },

  /**
   * Returns the type of link used between *source* and *target*.
   * @returns {LinkType}
   */
  type() { return this._type; },

  /**
   * Remove the link from its manager.
   */
  remove() {
    const mgr = this._mgr;

    _removeLink(this);

    mgr._priv.isModified = true;
    _notifChg(mgr);
  },

  /**
   * Gets or sets the active state of a link.  When active,
   * a link shows atop everything else, and shows little
   * squares at its extremeties.
   *
   * @param [isActive] {boolean}
   * @returns {(boolean|Link)}
   */
  isActive(isActive) {
    const line = this._line;
    const canvas = line._canvas;

    if (arguments.length < 1) return canvas.hasClass(ACTIVE_LINE_CLASS);

    if (typeof isActive !== 'boolean') throw new TypeError('isActive: Boolean');

    else {
      if (isActive) canvas.addClass(ACTIVE_LINE_CLASS);
      else canvas.removeClass(ACTIVE_LINE_CLASS);

      _drawLine(line);
      return this;
    }
  },
};

Object.freeze(Link);
Object.freeze(Link.prototype);

/* ***************************************************
 * Class: SingleVar
 * *************************************************** */

/**
 * A single variable.
 * @param {Bubble} bubble
 * @param {string} name - The name given to this parameter
 * @param {(string|VarType)} varType
 *
 * @constructor
 * @name SingleVar
 */
function SingleVar(bubble, name, varType) {
  this._bbl = _validBubble(bubble, 'bubble');
  this._name = _validVarName(name);

  /** @type {VarType} */
  this._type = VarType.valueOf(varType);

  Object.freeze(this);
}

SingleVar.prototype = /** @lends SingleVar.prototype */ {
  constructor: SingleVar,

  /**
   * Returns the name given to this variable within a
   * JavaScript script.  Essentially, `'$' + name()`.
   * @returns {string}
   */
  getScriptName() {
    return _getScriptVarName(this._name);
  },

  /**
   * Returns the parameter name used within parameter-sets.
   * @returns {string}
   */
  paramName() {
    return CONSTANTS.PARAM_PREFIX_SINGLE_VAR + this._name;
  },

  /**
   * Returns a line of script valid within MP's JS-Engine that
   * declares and assigns this single variable.
   * @returns {string}
   */
  getScriptDeclaration() {
    return `let ${ this.getScriptName()
    } = ${ this._type.jsEngineMethod()
    }('${ this.paramName() }');`;
  },

};

Object.freeze(SingleVar);
Object.freeze(SingleVar.prototype);

/**
 * Returns the variable name within a JavaScript script, given `name`.
 * Essentially, `'$' + varName`.
 * @param {string} varName
 * @returns {string}
 */
function _getScriptVarName(varName) {
  // We prefix it with '$' to avoid conflicts with built-in functions.
  return `$${ varName}`;
}

/* ***************************************************
* Class: Dataset
* *************************************************** */

/**
* Link between parameters and bubbles (Data or Save).
*
* @param {Bubble} bubble Bubble that links to this variable.
* @param {string} name Valid variable name.
*
* @constructor
* @name Dataset
*/
function Dataset(bubble, name, vue) {
  this._bbl = _validBubble(bubble, 'bubble');
  this._name = _validVarName(name, vue);

  Object.freeze(this);
}

Dataset.prototype = /** @lends Dataset.prototype */ {
  constructor: Dataset,

  /**
   * Returns the name given to this variable within a
   * JavaScript script.  Essentially, '$' + name().
   * @returns {string}
   */
  getScriptName() {
    return _getScriptVarName(this._name);
  },

  /**
   * Returns the parameter prefix used within parameter-sets.
   * @returns {string}
   */
  paramPrefix() {
    return BubbleUtils.datasetPrefix(this._name);
  },

  /**
   * Returns a line of script valid within MP's JS-Engine that
   * declares and assigns this product variable.
   * @returns {string}
   */
  getScriptDeclaration() {
    const prefix = this.paramPrefix();

    return [
      `var ${ this.getScriptName() } = morn.Product.create(`,
      `    Parameters.getString('${ prefix }${CONSTANTS.DATASET_SUFFIX_FEED }'),`,
      `    Parameters.getJson('${ prefix }${CONSTANTS.DATASET_SUFFIX_KEY_ROOTS }'),`,
      `    Parameters.getJson('${ prefix }${CONSTANTS.DATASET_SUFFIX_COLS }')`,
      ');',
    ].join(EOL);
  },
};

Object.freeze(Dataset);
Object.freeze(Dataset.prototype);

/**
 *  Checks if the given data set is forward curve or time series  and returns true if it's forward curve
 * @param {Dataset} ds
 * @param {Object[]} psgBuf
 * @return {boolean}
 * @private
 */
function _isDataSetForwardCurve(ds, psgBuf) {
  const prefix = ds.paramPrefix();
  const uniqKeyOrRoots = _uniqParamVal(psgBuf, 0, 0, prefix + CONSTANTS.DATASET_SUFFIX_KEY_ROOTS);
  const isForwardCurve = false;

  if (uniqKeyOrRoots.length === 1) {
    const keyOrRoots = JSON.parse(uniqKeyOrRoots[0]);
    return (keyOrRoots instanceof Array);
  }
  return isForwardCurve;
}

/**
 * Returns an object map with parameter set name as key and boolean value indicating if it's forward curve
 * @param {Bubble}bubble
 * @return {Object.<string, boolean>} Dictionary of dataset names and whether they represent a forward curve.
 * @private
 */
function _getDataSetVarMap(bubble) {
  const list = [];
  const mgr = bubble._mgr;

  _addOrphanVars(list, mgr, _getDataset, isData);
  _addUpstreamVars(list, bubble, _getDataset);

  return list.reduce((dict, ds) => {
    dict[ds.getScriptName()] = _isDataSetForwardCurve(ds, mgr._priv.psgBuffer);
    return dict;
  }, {});
}

/**
 * Generates the default forward curve code for the give parameter set name
 * @param {string} paramSetName
 * @private
 */
function _getDefaultForwardCurveCode(paramSetName) {
  const fwdCurveCode = StepType.defaultFwdCurveCode(paramSetName.substring(1),
    'forward_curve',
    paramSetName,
    '-0d',
    'month');

  const name = `${StepType.FORWARD_CURVE.toString() } ${ paramSetName.substring(1)}`;

  return _formulaSection(name, fwdCurveCode);
}

/**
 *  Generates the default time series code for the give parameter set name
 * @param {string} paramSetName
 * @private
 */
function _getDefaultTimeSeriesCode(paramSetName) {
  const timeSeriesCode = StepType.defaultTimeSeriesCode(paramSetName.substring(1),
    paramSetName,
    '-0d',
    'time_series',
    false,
    null);
  const name = `${StepType.TIME_SERIES.toString() } ${ paramSetName.substring(1)}`;
  return _formulaSection(name, timeSeriesCode);
}

/**
 * @param {Bubble} bubble Formula bubble for which to produce default JS code.
 * @returns {string} Default formula code produced from linked or free-standing Data bubbles.
 * @private
 */
function _getDefaultFormulaCode(bubble) {
  const datasetMap = _getDataSetVarMap(bubble);
  const code = [];
  _.each(datasetMap, (isForwardCurve, key) => {
    if (isForwardCurve) {
      code.push(_getDefaultForwardCurveCode(key));
    } else {
      code.push(_getDefaultTimeSeriesCode(key));
    }
  });
  return code.join('');
}

/**
 * Compares two script variables by their name, for the purpose of
 * sorting them alphabetically.
 * @param {ScriptVar} v1
 * @param {ScriptVar} v2
 * @returns {number}
 * @private
 */
function _compareScriptVarName(v1, v2) {
  return Strings.compare(v1.name(), v2.name());
}

/**
 * Returns a list of ScriptVar objects that represent:
 *   - variables created upstream;
 *   - orphan variables;
 *   - hard-coded variables.
 *
 * @param {Bubble} bubble
 * @returns {ScriptVar[]} List of variables, sorted by name.
 * @private
 */
function _getVarList(bubble) {
  const TextF = Text.Edit.Formula;
  const list = [];
  const listDs = [];
  const listVars = [];

  _addUpstreamVars(listDs, bubble, _getDataset);
  _addUpstreamVars(listVars, bubble, _getSingleVar);

  _addOrphanVars(listDs, bubble._mgr, _getDataset, ALL_INPUTS_FILTER);
  _addOrphanVars(listVars, bubble._mgr, _getSingleVar, ALL_INPUTS_FILTER);

  Arrays.addAll(list, listDs.map(dataset => new ScriptVar(dataset.getScriptName(), VarDataType.PRODUCT, TextF.userDef)));

  Arrays.addAll(list, listVars.map(singleVar => new ScriptVar(
    singleVar.getScriptName(),
    VarDataType.valueOf(singleVar._type.valueOf()),
    TextF.userDef,
  )));

  const hardCodedVars = Arrays.slice(HARD_CODED_VARS);

  list.sort(_compareScriptVarName);
  hardCodedVars.sort(_compareScriptVarName);

  Arrays.addAll(list, hardCodedVars);

  return list;
}

/**
 * Filters the list of steps to only return the Data steps.
 * In other words, this method filters out the QA checks
 * from the given list of steps.
 * @param {MpFormulaStep[]} steps
 * @returns {MpFormulaStep[]}
 * @private
 */
function _dataStepsOnly(steps) {
  return steps.filter(step => step.type().isDataFunction());
}

/**
 * Returns the language-specific text associated with `val`
 * within `langObj`.  If `val` is not defined in the dictionary,
 * it - `val` - is returned.
 * @param {Object} langObj
 * @param {string} val
 * @returns {string}
 * @private
 */
function _safeLang(langObj, val) {
  return _if(langObj, val, val);
}

/**
 * Generates a list of all available variables up to this point.
 * @param {{ availVars: ScriptVar[],
*           stepTable: jQuery,
*           selectedStep: ?(MpFormulaStep) }} ui
* @returns {ScriptVar[]}
* @private
*/
function _availVars(availVars, stepTable, stopAtIdx) {
  const list = Arrays.slice(availVars);
  let steps = _dataStepsOnly(stepTable);

  if (stopAtIdx >= 0) { steps = Arrays.slice(steps, 0, stopAtIdx); }

  const localVars = steps.map(step => new ScriptVar(
    step.name(),
    VarDataType.TIME_SERIES,
    _safeLang(Text.Edit.FormulaGui.StepType,
      step.type().valueOf()),
    step.codeOnly(),
  ));

  Arrays.addAll(list, localVars);
  return list;
}

/**
 * Returns whether a formula was entered using the freeform text editor.
 * @param {string} code
 * @returns {boolean}
 * @private
 */
function _isFreeformFormula(code) {
  return (Strings.isNonEmpty(code)
          && code.indexOf(FORM_ANALYZE_START + FORM_ANALYZE_SUSPEND) < 0);
}

/**
 * Returns the code sections recognized within `code`, in order
 * they were found.
 *
 * This function assumes there is no code in-between sections;
 * such code, if it exists, is ignored.
 *
 * @param {string} code
 * @returns {MpFormulaStep[]}
 * @private
 */
function _listFormulaSteps(code) {
  const steps = [];
  const stepStart = `${FORM_ANALYZE_START + FORM_ANALYZE_SUSPEND } `;
  const stepStartLen = stepStart.length;
  let idxStart = code.indexOf(stepStart);

  while (idxStart >= 0) {
    const innerStart = code.indexOf(FORM_ANALYZE_END, idxStart);
    const name = code.substring(idxStart + stepStartLen, innerStart);
    const idx = name.indexOf(' ');
    let varType = name;
    let varName = '';

    if (idx >= 0) {
      varType = name.substring(0, idx);
      varName = name.substring(idx + 1);
    }

    const stepEnd = _formulaSectionBlockFooter(name);
    const idxEnd = code.indexOf(stepEnd, idxStart);

    if (idxEnd < 0) {
      idxStart = -1;
    } else {
      // "+3" is to account for "*/\n".  "-1" is to account for "\n".
      const rawStep = code.substring(innerStart + 3, idxEnd - 1);

      steps.push(new MpFormulaStep(
        StepType.valueOf(varType),
        varName,
        rawStep,
      ));

      idxStart = code.indexOf(stepStart, idxEnd + stepEnd.length);
    }
  }

  return steps;
}

/**
 * Deletes parameters starting with the given prefix from all parameter-sets
 * within the given parameter-set-group range.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} prefix
 * @param {int} firstRow
 * @param {int} lastRow
 * @private
 */
function _delPsg(mgr, prefix, firstRow, lastRow) {
  const priv = mgr._priv;
  const narrow = priv.psgBuffer.slice(firstRow, lastRow + 1);
  let isDeleted = false;

  narrow.forEach((ps) => {
    for (const prop in ps) {
      if (Object.hasOwnProperty.call(ps, prop)
              && Strings.startsWith(prop, prefix)) {
        delete ps[prop];
        isDeleted = true;
      }
    }
  });

  if (isDeleted) { priv.psgBufUpdSeq++; }
}

/**
 * Removes a Dataset from its manager.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {Dataset} dataset
 * @private
 */
function _delDataset(mgr, dataset) {
  const varPrefix = dataset.paramPrefix();
  const priv = mgr._priv;

  Arrays.remove(dataset, priv.datasets);

  _delPsg(mgr, varPrefix, 0, priv.psgBuffer.length - 1);
}

/**
 * Marks the formula associated with the given bubble
 * as *modified*.
 * @param {Bubble} formulaBubble
 * @private
 */
function _setModifiedFormula(formulaBubble) {
  const f = _getFormula(formulaBubble, null);
  if (f !== null) { f.isModified = true; }
}

/**
 * Removes a SingleVar from its manager.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {SingleVar} singleVar
 * @private
 */
function _delSingleVar(mgr, singleVar) {
  const priv = mgr._priv;

  Arrays.remove(singleVar, priv.singles);

  _delPsg(mgr, singleVar.paramName(), 0, priv.psgBuffer.length - 1);
}

/**
 * Callback for *remove* menu in bubble context menu.
 * @private
 */
function _removeBubbleMenuCB(bubble, vue, operation) {
  /** @type {Bubble} */
  const mgr = bubble._mgr;
  const priv = mgr._priv;
  const ds = _getDataset(bubble);
  const sv = _getSingleVar(bubble);
  const fId = bubble.data(FORMULA_KEY);

  // `bubble.remove()` also removes all links to/from this bubble, so retrieve
  // dependents and dependencies before calling it.
  /** @type {Bubble[]} */
  const dependents = bubble._outs.map(link => link._target);
  /** @type {Bubble[]} */
  const dependencies = bubble._ins.map(link => link._src);

  bubble.remove();

  if (ds !== null) {
    _delDataset(mgr, ds);

    // Removing Save bubble, must regenerate its original formula code,
    // to remove Datasets from its declarations.
    if (bubble.type() === BubbleType.SAVE) {
      dependencies.forEach((formula) => {
        _setModifiedFormula(formula);
      });
    }

    // Scan all formulas for reference to now-deleted dataset.
    _scanFormulaErrors(mgr, vue, operation);
  }

  if (sv !== null) {
    _delSingleVar(mgr, sv);

    // Scan all formulas for reference to now-deleted dataset.
    _scanFormulaErrors(mgr);
  }

  if (fId !== null) {
    const formula = priv.formulas[fId];
    delete priv.formulas[fId];

    if (!formula.isNew) {
      // Track persisted formulas.  Maybe we want to recycle them,
      // maybe we want to delete them from the server.
      priv.formulaMeta.deleted.push({
        uuid: formula.id,
        when: Dates.now(),
      });
    }


    // Scan dependent Save bubbles, flag them with "invalid input"
    _.chain(dependents)
      .filter(SAVE_FILTER)
      .each((saveBubble) => {
        _setBubbleError(saveBubble, true, vue, operation);
      });
  }

  priv.isModified = true;
  // _notifChg(mgr);
}

/**
* Returns the list of step types available to users, based on bubble type.
* @param {BubbleType} bubbleType
* @returns {MpFormulaStepType[]}
* @private
*/
function _listFormulaStepTypes(bubbleType) {
  switch (bubbleType) {
    case BubbleType.FORMULA:
      return [
        StepType.FORWARD_CURVE,
        StepType.TIME_SERIES,
        StepType.BASIC_MATH,
        StepType.EXTRAPOLATION,
        StepType.MERGE,
        StepType.FILL,
        StepType.FREE_TEXT,
      ];
    case BubbleType.QA:
      return [
        StepType.FORWARD_CURVE,
        StepType.TIME_SERIES,
        StepType.MISSING_DATA_QA,
        StepType.SPIKE_QA,
        StepType.IN_RANGE_QA,
        StepType.MERGE,
        StepType.FILL,
      ];

    default:
      return [];
  }
}


/**
 * Creates a new bubble within this manager.
 * The bubble is not visible until it is given coordinates.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {Bubble} source
 * @param {Bubble} target
 * @param {LinkType} type
 * @returns {Link}
 */
function _newLink(mgr, source, target, type, linkId) {
  _canConstructLink = true;
  const existingLink = mgr._priv.links.find(l => l._src._id === source._id && l._target._id === target._id);
  const link = new Link(mgr, source, target, type, linkId);
  if (existingLink) {
    return existingLink;
  }
  mgr._priv.links.push(link);

  return link;
}

/**
 * Restores a list of persisted links.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {SerializedLink[]} serializedLinks
 * @private
 */
function _restoreLinks(mgr, serializedLinks) {
  serializedLinks.forEach((linkInfo) => {
    const { bubbles } = mgr._priv;
    const src = bubbles[linkInfo.source];
    const target = bubbles[linkInfo.target];
    const type = LinkType.valueOf(linkInfo.type);

    _newLink(mgr, src, target, type);
  });
}

/**
 *
 * @param {Bubble} bubble
 * @param {(Dataset|SingleVar)} varObj
 * @param {(Dataset[]|SingleVar[])} mgrList
 * @private
 */
function _registerVar(bubble, varObj, mgrList) {
  const foundAt = _getVarIndex(bubble, mgrList);
  if (foundAt < 0) {
    mgrList.push(varObj);
  } else {
    mgrList[foundAt] = varObj;
  }

  bubble.text(varObj.getScriptName());
}

/**
 * Creates a new Dataset instance and associates it with
 * the given bubble. If bubble already is already associated
 * with a Dataset, the association is removed and that Dataset
 * is removed from the manager.
 *
 * This methods does not maintain the parameter-set-group.
 *
 * @param {Bubble} bubble
 * @param {string} varName
 * @returns {Dataset}
 * @private
 */
function _newDataset(bubble, varName, vue) {
  const ds = new Dataset(bubble, varName, vue);

  _registerVar(bubble, ds, bubble._mgr._priv.datasets);

  return ds;
}

/**
 * Restores a list of persisted Datasets.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {SerializedDataset[]} serializedDatasets
 * @private
 */
function _restoreDatasets(mgr, serializedDatasets) {
  serializedDatasets.forEach((datasetInfo) => {
    _newDataset(
      mgr._priv.bubbles[datasetInfo.bubbleId],
      datasetInfo.varName,
    );
  });
}

/**
 * Creates a new SingleVar instance and associates it with
 * the given bubble. If bubble already is already associated
 * with a SingleVar, the association is removed and that SingleVar
 * is removed from the manager.
 *
 * This methods does not maintain the parameter-set-group.
 *
 * @param {Bubble} bubble
 * @param {string} varName
 * @param {VarType} varType
 * @returns {SingleVar}
 * @private
 */
function _newSingleVar(bubble, varName, varType) {
  const sv = new SingleVar(bubble, varName, varType);

  _registerVar(bubble, sv, bubble._mgr._priv.singles);

  return sv;
}

/**
 * Restores a list of persisted Datasets.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {?SerializedVariable[]} serializedVars
 * @private
 */
function _restoreSingleVars(mgr, serializedVars) {
  if (Array.isArray(serializedVars)) {
    serializedVars.forEach(
      /** @param {SerializedVariable} varInfo */
      (varInfo) => {
        _newSingleVar(
          mgr._priv.bubbles[varInfo.bubbleId],
          varInfo.varName,
          VarType.valueOf(varInfo.varType),
        );
      },
    );
  }
}

/**
 * Scans the formula associated with a given bubble for
 * variables created within the formula.
 * @param {Bubble} bubble
 * @param {*} [fallbackValue]
 * @returns {(Object.<string, ScriptVar>|*)} An object in which properties are variable names
 *                                        and values are the code to the right of the
 *                                        last assignment for that variable, or `fallbackValue`.
 * @throws Error - If formula code contains a syntax error and
 *                 `fallbackValue` is not provided.
 * @private
 */
function _getVarsInFormula(bubble, fallbackValue) {
  // console.log(bubble._mgr);
  const code = _getFormulaUserCode(bubble, '');
  try {
    return WorkflowVars.getTopLevelVariables(code);
  } catch (ex) {
    if (arguments.length > 1) {
      Console.warn(
        'Failed to parse JS formula, returning `fallbackValue` instead of throwing [{}]',
        _jsParseErrorToString(ex),
      );
      return fallbackValue;
    }
    throw ex;
  }
}

/**
 * Scans this bubble's formula and makes sure that dependent
 * *Save* bubbles still have valid *source variables*.
 *
 * If source variable is no longer valid, the dependent *Save*
 * bubble will be flagged with error style.
 *
 * @param {Bubble} formulaBubble - Bubble of type FORMULA.
 * @private
 */
function _scanSaveErrors(formulaBubble, vue, operation) {
  const availVars = _getVarsInFormula(formulaBubble, {});
  for (const saveBubble of _getSaveBubbles(formulaBubble)) {
    _setSaveErrorFlag(saveBubble, availVars, vue, operation);
  }
}

/**
 * Raises the error flag of a Formula (or QA) bubble.
 * @param {Bubble} formulaBubble
 * @param {boolean} hasError
 * @private
 */
function _flagFormulaError(formulaBubble, hasError, vue, operation) {
  _setBubbleError(formulaBubble, hasError, vue, operation);

  // Flag the Save bubbles too
  _scanSaveErrors(formulaBubble, vue, operation);
}

/**
 * Scans all formula bubbles for formulas which did not restore,
 * formulas with no code, formulas with syntax errors,
 * or formulas with invalid references to built-in variables.
 *
 * When found, the bubble's "error" indicator is raised
 * (i.e. we highlight the bubble with a red background.)
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _scanFormulaErrors(mgr, vue, operation) {
  Object.values(mgr._priv.bubbles).filter(ALL_FORMULAS_FILTER).forEach((bubble) => {
    const formula = _getFormula(bubble, null);
    // console.log(formula);
    if (formula !== null) {
      if (formula.content === null) {
        formula.content = '';
        _flagFormulaError(bubble, true, vue, operation);
      } else if (Strings.isEmpty(formula.content)) {
        _flagFormulaError(bubble, true, vue, operation);
      } else {
        const err = _getFirstFormulaError(bubble);
        _flagFormulaError(bubble, (err !== null), vue, operation);
      }
    }
  });
}

/**
 * Actions to take after a workflow has been deserialized, whether it be
 * from Markets or Marketplace.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _finalizeLoad(mgr, vue) {
  // Flag Save bubbles which have lost their *source variable*.

  const allBubbles = mgr._priv.bubbles;

  _scanFormulaErrors(mgr, vue);

  // Look for Save bubbles without a valid input.
  _.chain(allBubbles)
    .filter(FORMULA_FILTER)
    .each(_scanSaveErrors);

  // Look for Save bubbles without a input link, raise their Error flag.
  _.chain(allBubbles)
    .filter(SAVE_FILTER)
    .each((saveBubble) => {
      if (saveBubble._ins.length < 1
            && Strings.isNonEmpty(saveBubble.data(SAVE_SRC_VAR))) _setBubbleError(saveBubble, true);
    });
}

/**
 * Restores UI information from Workflow config file.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _restoreFromMp(mgr, vue) {
  // mgr._win.wait(false);

  const priv = mgr._priv;
  const { config } = priv;
  const serialized = config.ui();

  _setBasicFromConfig(mgr, config);

  _restoreBubbles(mgr, serialized.bubbles);
  _restoreLinks(mgr, serialized.links);
  _restoreDatasets(mgr, serialized.datasets);
  _restoreSingleVars(mgr, serialized.single_vars);

  if (Object.hasOwnProperty.call(serialized, 'formula_meta')) {
    Objects.copyProperties(priv.formulaMeta,
      serialized.formula_meta,
      ['deleted'],
      true);
  }

  priv.duration = _getTimeoutDuration(config);

  _finalizeLoad(mgr, vue);
}

/**
 *
 * @param {{ schedule: ScheduledJob,
*           paramSetGroup: ParameterSetGroup }} payload
* @this {MpDataWorkflowGui.Manager}
* @private
*/
function _loadParamsAndSchedule(payload, vue) {
  const mgr = this;
  const win = mgr._win;
  const priv = mgr._priv;

  // if (win.isDestroyed()) {
  //   return;
  // }

  if (!_isHandledError(payload, win)) {
    const sched = payload.schedule;
    const psg = payload.paramSetGroup;

    priv.job = sched;
    priv.cronExpr = ((sched !== null) ? sched.cronExpression() : null);
    _loadPsg(mgr, psg);

    const psgId = priv.config.psgId() || priv.config.ui().psg_id;
    const isValidPsgId = Numbers.isNonNegativeInteger(psgId);

    // if (psg.id() < 0) {
    //   if (isValidPsgId) {
    //     priv.loadReq++;
    //     _getAH().call('lim.MpApi.GetParameterSetGroup',
    //       _psgCB.bind(mgr),
    //       psgId);
    //   }
    // } else if (isValidPsgId
    //             && psg.id() !== psgId) { Console.warn('Conflict in parameter-set-group IDs: scheduler has {} and workflow config has {}.', psg.id(), psgId); }

    if (_checkReady(mgr)) { _restoreFromMp(mgr, vue); }
  }
}

/**
 * Creates a new, registered Formula object.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} id
 * @returns {Formula}
 * @private
 */
function _newFormulaObj(mgr, id) {
  const formula = {
    id,
    owner: null,
    isNew: false,
    isModified: false,
    content: '',
  };

  mgr._priv.formulas[id] = formula;

  return formula;
}

/**
 * Parses a section from full formula code.
 * @param {string} code Formula code.
 * @param {string} name Section name.
 * @returns {string} The section, "" if not found.
 * @private
 */
function _parseFormulaSection(code, name) {
  const hdr = _formulaSectionBlockHeader(name);
  const footer = _formulaSectionBlockFooter(name);
  const start = code.indexOf(hdr);
  const end = code.indexOf(footer, start + 1);

  if (start >= 0
      && end >= 0) {
    // +1 and -1 are to account for extra EOL characters inserted during the "build".
    return code.substring(start + hdr.length + 1, end - 1);
  }
  return '';
}

/**
 * Callback for MpApi.GetFormulaByUuid.
 * @param {{uuid: string, formula: string, type: string, userName: string, version: int}} formulaInfo
 * @this {{mgr: MpDataWorkflowGui.Manager, uuid: string}}
 * @private
 */
function _loadFormula(formulaInfo) {
  const ctx = this;
  const { mgr } = ctx;
  const { uuid } = ctx;

  // if (mgr._win.isDestroyed()) {
  //   return;
  // }

  const formula = _newFormulaObj(mgr, uuid);
  formula.isNew = false;
  formula.isModified = false;

  if (!_isHandledError(formulaInfo, mgr._win)) {
    formula.content = _parseFormulaSection(formulaInfo.formula, FORM_SECT_BODY);
    formula.owner = formulaInfo.userName;
  } else { formula.content = null; } // Temporary, will reset to "" in `_restoreFromMp()`

  if (_checkReady(mgr)) { _restoreFromMp(mgr); }
}

/**
 * Loads bubbles, links, parameter-sets, etc. from MP workflow config.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @private
 */
function _loadConfig(mgr, wfSchAndPsgPayload, formulas, vue) {
  const win = mgr._win;
  const priv = mgr._priv;
  const { config } = priv;
  const id = config.id();
  const ui = config.ui();

  _setBasicFromConfig(mgr, config);

  if (!Objects.hasProperties(ui) && id > 0) {
    // Legacy workflows will have an empty ui and id greater than 0.
    win.warn(Text.updatingLegacyWorkflow.replace('[name]', config.name()));
  } else if (id > 0) {
    if (_hasUnrecognizedTargets(config)) {
      // The workflow has been modified hacked, likely by a Morningstar employee.
      // Display it anyway but make sure this UI doesn't corrupt it.

      priv.isRecognized = false;
      win.warn(Text.updatingHackedWorkflow.replace('[name]', config.name()));
    }


    // This is an existing workflow with a ui component. Let's load it up.
    // TODO: loader i guess
    // win.wait(true);
    priv.loadReq++;
    // TODO: fetch from api
    // _getAH().call('lim.MpApi.GetWorkflowParameterSetGroup',
    //   _loadParamsAndSchedule.bind(mgr),
    //   config);

    // Load formulas
    ui.bubbles.forEach((uiBubble) => {
      if ((uiBubble.type === 'qa'
                  || uiBubble.type === 'formula')
              && Object.hasOwnProperty.call(uiBubble.props, 'formula_id')) {
        const uuid = uiBubble.props['formula_id'];
        const ctx = {
          mgr,
          uuid,
        };

        priv.loadReq++;
        // TODO: load formula from API
        // _getAH().call('lim.MpApi.GetFormulaByUuid',
        _loadFormula.bind(ctx)(formulas.find(fm => fm.uuid === uuid));
        //   uuid);
      }
    });

    _loadParamsAndSchedule.bind(mgr)(wfSchAndPsgPayload, vue);
  }
}

/**
 * Creates a Dataset row appender function, which will append
 * all datasets to the given row.  The datasets are compressed
 * to a single string - its simplest form for human consumption
 * (but not parsable).
 *
 * @param {Dataset[]} datasets
 * @returns {Function}
 * @private
 */
function _newDatasetRowAppender(datasets) {
  const numDs = datasets.length;
  const empty = '';

  /**
   * @param {string[]} row
   * @param {Object} ps
   */
  return function (row, ps) {
    for (let i = 0; i < numDs; i++) {
      const ds = datasets[i];
      const prefix = ds.paramPrefix();
      const nameFeed = prefix + CONSTANTS.DATASET_SUFFIX_FEED;
      const nameKeyRoots = prefix + CONSTANTS.DATASET_SUFFIX_KEY_ROOTS;
      const nameCols = prefix + CONSTANTS.DATASET_SUFFIX_COLS;
      const nameProv = prefix + CONSTANTS.DATASET_SUFFIX_PROVIDER;
      const nameSrc = prefix + CONSTANTS.DATASET_SUFFIX_SOURCE;
      let text = empty;

      // Not having "[...].feed" means we have nothing.
      if (typeof ps[nameFeed] === 'string') {
        text = Text.datasetText.replace('[feed]', ps[nameFeed])
          .replace('[cols]', parameterSetKeyToString(ps[nameCols]))
          .replace('[key_or_roots]', parameterSetKeyToString(ps[nameKeyRoots]))
          .replace('[provider]', ps[nameProv])
          .replace('[source]', ps[nameSrc]);
      }

      row.push(text);
    }
  };
}

/**
 * Creates a Variable row appender function, which will append
 * all variable values to the given row.
 *
 * @param {SingleVar[]} singleVars
 * @returns {Function}
 * @private
 */
function _newVarRowAppender(singleVars) {
  const numVars = singleVars.length;
  const empty = '';

  /**
   * @param {string[]} row
   * @param {Object} ps
   */
  return function (row, ps) {
    for (let i = 0; i < numVars; i++) {
      const singleVar = singleVars[i];
      const varName = singleVar.paramName();
      let text = empty;

      if (!isVoid(ps[varName])) { text = ps[varName].toString(); }

      row.push(text);
    }
  };
}

/**
 * Converts a parameter-set-group into a 2D array.
 * Each row represents a parameter-set and is structured
 * as follow:
 *    row[0]: parameter-set name
 *    row[1]: parameter-set description
 *    row[2-n]: dataset(s)
 *    row[n-m]: variable(s)
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {string[][]}
 * @private
 */
function _psgTo2dArray(mgr) {
  const priv = mgr._priv;
  const datasetAppender = _newDatasetRowAppender(priv.datasets);
  const varAppender = _newVarRowAppender(priv.singles);

  return priv.psgBuffer.map((ps) => {
    const row = [ // Start with parameter-set name and description.
      _if(ps, CONSTANTS.PARAM_SET_NAME, ''),
      _if(ps, CONSTANTS.PARAM_SET_DESCR, ''),
    ];

    datasetAppender(row, ps);
    varAppender(row, ps);

    return row;
  });
}

/**
 * Returns a Dataset or SingleVar object associated with the column index,
 * as displayed in the parameter-set grid.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {int} colIdx Non-negative
 * @param [defaultVal] {*}
 * @returns {(Dataset|SingleVar|*)}
 * @private
 */
function _dsOrVar(mgr, colIdx, defaultVal) {
  const priv = mgr._priv;
  const { datasets } = priv;
  const singleVars = priv.singles;
  const numDs = Array.isArray(datasets) ? datasets.length : 0;

  if (colIdx > 1 && colIdx - 2 < numDs) return datasets[colIdx - 2];

  if (colIdx > 1 && colIdx - 2 - numDs < (Array.isArray(singleVars) ? singleVars.length : 0)) return singleVars[colIdx - 2 - numDs];

  if (arguments.length > 2) return defaultVal;

  throw new Error('ArrayIndexOutOfBountException: colIndex does not match a Dataset or SingleVar object.');
}

/**
 * Returns the first argument that's not void, or throws.
 * @param {...*} args
 * @returns {*} The first non-void argument.
 * @throws {Error} If all arguments are void (or no arguments were passed).
 * @private
 */
function _firstNonVoid(args) {
  for (let i = 0, len = arguments.length; i < len; i++) {
    if (!Objects.isVoid(arguments[i])) { return arguments[i]; }
  }
  throw new Error('All arguments are void.');
}

/**
 * Validates a variable name, and returns it if valid.
 * Otherwise, this method throws.
 * @param {string} varName
 * @returns {string}
 * @private
 */
function _validVarName(varName, vue) {
  if (typeof varName !== 'string'
      || varName.length > 30) {
    if (vue) {
      vue.showError = true;
      vue.errorMessage = 'varName: String, 30-chars max.';
    }
  }

  if (!REGEX_VAR_NAME_USER_DEF.test(varName)) {
    if (vue) {
      vue.showError = true;
      vue.errorMessage = 'varName: String, 30-chars max.';
    }
  }

  return varName;
}

/**
 * Returns a parameter-set-group without empty parameter-sets.
 * @param {Object[]} psgBuf
 * @returns {Object[]}
 * @private
 */
function _cleanPsg(psgBuf) {
  return psgBuf.filter(_isNonEmptyParamSet);
}

/**
 * Creates a new ParameterSetGroup instance from the
 * current values within the given manager.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {ParameterSetGroup}
 * @private
 */
function _buildPsg(mgr) {
  const priv = mgr._priv;
  const name = ParameterSetGroup.newNameFromWorkflowName(priv.name);
  const paramSets = _cleanPsg(priv.psgBuffer).map((ps) => {
    const clone = Objects.clone(ps);
    delete clone[CONSTANTS.PARAM_SET_UUID];
    delete clone[CONSTANTS.PARAM_SET_NAME];
    delete clone[CONSTANTS.PARAM_SET_DESCR];

    return new ParameterSet(
      -1,
      _if(ps, CONSTANTS.PARAM_SET_UUID, null),
      ps[CONSTANTS.PARAM_SET_NAME],
      clone,
      _if(ps, CONSTANTS.PARAM_SET_DESCR, ''),
    );
  });

  return new ParameterSetGroup(-1, name, paramSets);
}

/**
 * Adds change a formula UUID to have a pattern that indicates
 * a UUID that's not been saved to the server yet.
 * @param {string} fuuid
 * @returns {string}
 * @private
 */
function _localFormulaId(fuuid) {
  return `<<local:${ fuuid }>>`;
}

/**
 * Creates a data dependency.
 * @param {Bubble} bubble
 * @param {EnumItem} scope {@link DependencyScope}
 * @returns {KeyArrivalDependency}
 * @private
 */
function _genDataDependency(bubble, scope, vue) {
  const dataset = _getDataset(bubble);
  const prefix = dataset.paramPrefix();
  let key = null;
  let roots = null;
  const prodTypes = _getProdTypeFilter(bubble, dataset);
  const timeoutStr = bubble.data(SAVE_DEPENDENCY_EXPIRE_TIME);
  let timeout = null;

  if (prodTypes.length !== 1) {
    vue.showError = true;
    vue.errorMessage = 'IllegalStateException: Data or Save bubble with multiple product types across parameter-sets.';
    throw new Error('IllegalStateException: Data or Save bubble with multiple product types across parameter-sets.');
  }

  const ph = `{{${ prefix }${CONSTANTS.DATASET_SUFFIX_KEY_ROOTS }}}`;
  if (prodTypes[0] === MpProductSel.Type.ROOTS) {
    roots = ph;
  } else {
    key = ph;
  }

  if (bubble.data(SAVE_DEPENDENCY_EXPIRE_SET)
      && !isVoid(Dates.stringToTime(timeoutStr, null))) {
    timeout = new Timeout(TimeoutType.TIME_OF_DAY, { time: timeoutStr });
  }

  return new KeyArrivalDependency(
    `{{${ prefix }${CONSTANTS.DATASET_SUFFIX_FEED }}}`,
    key,
    roots,
    `{{${ prefix }${CONSTANTS.DATASET_SUFFIX_COLS }}}`,
    timeout,
    scope,
  );
}

/**
 * Creates a dependency on a formula run.
 * @param {Bubble} bubble
 * @param {LinkType} linkType
 * @param {boolean} isDebug
 * @returns {TopicDependency}
 * @private
 */
function _genFormulaDependency(bubble, linkType, isDebug) {
  const FORMULA_STATUS = 'formula.worker.script.status';

  const formula = _getFormula(bubble);
  let fuuid = formula.id;

  if (formula.isNew) {
    if (!isDebug) { throw new Error('IllegalStateException: formula is new, need UUID'); }
    fuuid = _localFormulaId(fuuid);
  }

  const props = {
    uuid: fuuid,
  };

  switch (linkType) {
    case LinkType.SUCCESS:
      props[FORMULA_STATUS] = 'success';
      break;

    case LinkType.FAILURE:
      props[FORMULA_STATUS] = 'failed';
      break;

    case LinkType.QA_FAILURE:
      props[FORMULA_STATUS] = 'failed';
      props['formula.worker.script.err.cause'] = 'JavascriptQAException';
      break;

    default:
      throw new Error(`UnsupportedOperationException: Formula dependency from link type ${ linkType.toString()}`);
  }

  return new TopicDependency(TOPIC_FORMULA_COMPLETE, props);
}

/**
 * Creates a Dependency object based on a (source) bubble and a
 * link type.
 * @param {Bubble} srcBubble
 * @param {EnumItem} linkType
 * @param {boolean} isDebug
 * @returns {Dependency}
 * @private
 */
function _genDependency(srcBubble, linkType, isDebug, vue) {
  const srcType = srcBubble.type();
  switch (srcType) {
    case BubbleType.DATA:
      return _genDataDependency(srcBubble, DependencyScope.GLOBAL, vue);

    case BubbleType.SAVE:
      return _genDataDependency(srcBubble, DependencyScope.LOCAL, vue);

    case BubbleType.QA:
    case BubbleType.FORMULA:
      return _genFormulaDependency(srcBubble, linkType, isDebug);

    default:
      throw new Error(`UnsupportedOperationException: dependency from ${ srcType.toString() } bubble`);
  }
}

/**
 * Generic task-target appender.
 * @param {Target[]} targets
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {boolean} isDebug
 * @param {function} filter
 * @param {string} targetNamePattern
 * @param {function(Bubble, boolean): Task} taskGenerator
 * @private
 */
function _addTaskTargets(targets, mgr, isDebug, filter, targetNamePattern, taskGenerator, vue) {
  let seq = 0;

  _.chain(mgr._priv.bubbles)
    .filter(filter)
    .each((bubble) => {
      const task = taskGenerator(bubble, isDebug);
      let isRequired = true;
      const dependencies = bubble._ins.map((link) => {
        if (link.type() !== LinkType.SUCCESS) isRequired = false;

        return _genDependency(link.source(), link.type(), isDebug, vue);
      });
      const name = targetNamePattern.replace('#', (++seq).toString(10));
      const builder = new Target.Builder(name)
        .tasks([task])
        .dependencies(dependencies)
        .isRequired(isRequired);

      targets.push(builder.build());
    });
}

/**
 * Returns a notification task created from a notification bubble.
 * @param {Bubble} bubble - Of type NOTIFICATION.
 * @param {boolean} isDebug
 * @returns {Task}
 * @private
 */
function _genNotifTask(bubble, isDebug) {
  const meth = bubble.data(NOTIF_METHOD);
  switch (meth) {
    case 'smtp':
      return new Task(TOPIC_SEND_EMAIL, {
        recipients: bubble.data(NOTIF_SMTP_TO),
        subject: bubble.data(NOTIF_SMTP_SUBJECT),
        body: bubble.data(NOTIF_SMTP_MSG),
      });

    default:
      throw new Error(`Unsupported notification protocol: ${ meth}`);
  }
}

/**
 * Returns a formula task created from a formula bubble.
 * @param {Bubble} bubble - Of type FORMULA or QA.
 * @param {boolean} isDebug
 * @returns {Task}
 * @private
 */
function _genFormulaTask(bubble, isDebug) {
  const formula = _getFormula(bubble);
  let fuuid = formula.id;

  if (formula.isNew) {
    if (!isDebug) { throw new Error('IllegalStateException: formula is new, need UUID'); }

    fuuid = _localFormulaId(fuuid);
  }

  return new Task(TOPIC_RUN_FORMULA, {
    uuid: fuuid,
    bypass_formula_schedule: 'true',
  });
}

/**
 * Generic task-target appender.
 * @param {Target[]} targets
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {boolean} isDebug
 * @param {function} filter
 * @param {string} targetNamePattern
 * @param {UserAction[]} userActions - User actions.
 * @private
 */
function _addUserActionTargets(targets, mgr, isDebug, filter, targetNamePattern, userActions) {
  let seq = 0;

  _.chain(mgr._priv.bubbles)
    .filter(filter)
    .each((bubble) => {
      const name = targetNamePattern.replace('#', (++seq).toString(10));
      const builder = new Target.Builder(name)
        .dependencies([_genDependency(bubble, LinkType.FAILURE, isDebug)])
        .userActions(userActions)
        .isRequired(false);

      targets.push(builder.build());
    });
}

/**
 * Returns an AMQP task created from an AMQP bubble.
 * @param {Bubble} bubble - Of type AMQP_TASK.
 * @param {boolean} isDebug
 * @returns {Task}
 * @private
 */
function _genAmqpTask(bubble, isDebug) {
  return new Task(
    bubble.data(AMQP_TOPIC),
    bubble.data(AMQP_PROPERTIES),
  );
}

/**
 * Returns a list of action bubbles - QA, Formula or Save - for which Success
 * is not being monitored.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {Bubble[]} Action-bubbles with no *success* follow-up.
 * @private
 */
function _getDeadEndBubbles(mgr) {
  /** @type {Bubble[]} */
  const bubbles = Object.values(mgr._priv.bubbles);
  const candidates = bubbles.filter(bubble => (bubble._type._val === 'save'
    || bubble._type._val === 'qa' || bubble._type._val === 'formula'));

  return candidates.filter(bubble => !bubble._outs.some(link => (link._type === LinkType.SUCCESS)));
}

/**
 * Generates a completion target.  That is, a target made of dependencies that
 * represent action-bubbles where *success* does not flow into another bubble
 * (aka dead-end bubbles).
 * @param {Bubble[]} deadEndBubbles
 * @param {boolean} isDebug
 * @returns {Target} Completion target.
 * @throws {Error} If `deadEndBubbles` is empty.
 * @private
 */
function _genCompletionTarget(deadEndBubbles, isDebug) {
  if (_.isEmpty(deadEndBubbles)) { throw new Error('`deadEndBubbles` is empty'); }

  const builder = new Target.Builder(TARGET_NAME_COMPLETION);

  return builder.isRequired(true)
    .dependencies(deadEndBubbles.map(bubble => _genDependency(bubble, LinkType.SUCCESS, isDebug)))
    .build();
}

/**
 * Creates a task for shutting down a workflow.
 * @param {string} [descr=""]
 * @returns {Task}
 * @private
 */
function _genShutdownTask(descr) {
  if (arguments.length < 1) { descr = ''; } else if (typeof descr !== 'string') { throw new TypeError('descr: String'); }

  return new Task(TOPIC_WORKFLOW_STOP, {
    'run.id': '{{RUN_ID}}',
    'run.result': descr,
  });
}

/**
 * Creates a timeout target based on manager's duration.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {Target}
 * @private
 */
function _genTimeoutTarget(mgr) {
  const { duration } = mgr._priv;
  if (duration <= 0) { throw new Error('IllegalStateException: no duration, cannot create shutdown target.'); }

  // This works as long as `duration` represents a period less than 24h.
  const tmpDate = new Date(duration);
  const timeStr = Dates.getFormatter('H:mm', true)(tmpDate);

  const task = _genShutdownTask(`Auto-stop after ${ timeStr.replace(':', 'h') }m`);
  const timeout = new TaskedTimeout(TimeoutType.DURATION, { after: timeStr }, [task]);
  const builder = new Target.Builder(TARGET_NAME_STOP);

  return builder.isRequired(false)
    .timeout(timeout)
    .build();
}

/**
 * Returns an object representing the serialized information of a bubble.
 * @param {Bubble} bubble
 * @returns {SerializedBubble}
 * @private
 */
function _persistBubbleWithCoords(bubble, coordMap) {
  return {
    id: bubble.id(),
    type: bubble.type().valueOf(),
    props: _.clone(bubble._data),
    coord: {
      y: coordMap[bubble.id()].y,
      x: coordMap[bubble.id()].x,
    },
  };
}

/**
 * Returns an object representing the serialized information of a bubble.
 * @param {Bubble} bubble
 * @returns {SerializedBubble}
 * @private
 */
function _persistBubble(bubble) {
  return {
    id: bubble.id(),
    type: bubble.type().valueOf(),
    props: _.clone(bubble._data),
  };
}

/**
 * Returns an object representing the serialized information of a link.
 * @param {Link} link
 * @returns {SerializedLink}
 * @private
 */
function _persistLink(link) {
  return {
    source: link.source().id(),
    target: link.target().id(),
    type: link.type().valueOf(),
  };
}

/**
 * Returns an object representing the serialized information for a dataset.
 * @param {Dataset} dataset
 * @returns {SerializedDataset}
 * @private
 */
function _persistDataset(dataset) {
  return {
    varName: dataset._name,
    bubbleId: dataset._bbl._id,
  };
}

/**
 * Returns an object representing the serialized information for a single-var.
 * @param {SingleVar} singleVar
 * @returns {SerializedVariable}
 * @private
 */
function _persistSingleVar(singleVar) {
  return {
    varName: singleVar._name,
    varType: singleVar._type.toString(),
    bubbleId: singleVar._bbl._id,
  };
}

/**
 * Returns an object to be passed as the `ui` property
 * of a workflow config file.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {Object}
 * @private
 */
function _persistToMp(mgr, isDebug, coordMap) {
  const priv = mgr._priv;
  return {
    bubbles: Object.values(priv.bubbles).map(bubble => (isDebug
      ? _persistBubbleWithCoords(bubble, coordMap) : _persistBubble(bubble))),
    links: priv.links.map(_persistLink),
    datasets: priv.datasets.map(_persistDataset),
    single_vars: priv.singles.map(_persistSingleVar),
    formula_meta: Objects.clone(priv.formulaMeta),
  };
}

/**
 * Builds a workflow config payload.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {boolean} isDebug
 * @returns {WorkflowConfigBuilder}
 * @private
 */
function _buildConfig(mgr, isDebug, vue) {
  const targets = [];

  _addTaskTargets(targets, mgr, isDebug,
    _bubbleTypeFilter(BubbleType.NOTIFICATION),
    'notif_#',
    _genNotifTask, vue);

  _addTaskTargets(targets, mgr, isDebug,
    _bubbleTypeFilter(BubbleType.QA),
    'qa_#',
    _genFormulaTask, vue);

  // Add a failure target for every QA bubble.
  _addUserActionTargets(targets, mgr, isDebug,
    _bubbleTypeFilter(BubbleType.QA),
    'qa_#_failed',
    [USER_ACTION_APPROVE], vue);

  _addTaskTargets(targets, mgr, isDebug,
    FORMULA_FILTER,
    'formula_#',
    _genFormulaTask, vue);

  _addTaskTargets(targets, mgr, isDebug,
    _bubbleTypeFilter(BubbleType.AMQP_TASK),
    'amqp_#',
    _genAmqpTask, vue);

  const deadEndBubbles = _getDeadEndBubbles(mgr);
  if (deadEndBubbles.length > 0) {
    targets.push(_genCompletionTarget(deadEndBubbles, isDebug));
  }

  targets.push(_genTimeoutTarget(mgr));

  const priv = mgr._priv;
  const builder = new ConfigBuilder(priv.name, priv.config);

  builder.timezone(priv.tz)
    .ui(_persistToMp(mgr, isDebug, vue.coordMap))
    .targets(targets)
    .description(priv.description)
    .psgId((priv.config && priv.config.psgId()) || 0)
    .psgVersion((priv.updateParameterSetData && priv.updateParameterSetData.currentPsgVersion) || 0)
    .correctionDays((priv.correctionDays) || 0);

  return builder;
}

/**
 * Remove unsaved data from the server.
 * @param {string} id
 * @private
 */
function _unpersistMgr(id) {
  if (_canPersist()) {
    // Runtime.userSettings.set(MANAGER_KEY_PREFIX + id, null);
  }
}

/**
 * @class
 * @name MpDataWorkflowGui.Manager
 */
class Manager {
  /**
     * @param {jQuery} canvas - jQuery wrapper around HTMLCanvas element.
     * @param {lim.Window} win - Window constructed to edit this workflow.
     * @constructor
     */
  constructor() {
    // if (isVoid(canvas)
    //         || typeof canvas.selector !== 'string'
    //         || canvas.length !== 1
    //         || typeof canvas[0].nodeName !== 'string') {
    //   throw new TypeError('canvas: JQuery, wrapper to one HTMLElement object');
    // }

    // if (!(win instanceof lim.Window)) { throw new TypeError('win: lim.Window'); }

    // this._canvas = canvas;
    // this._win = win;
    this._data = {};
    this._priv = {

      /** @type {string}  */ id: newMgrId(),
      /** @type {int} */ loadReq: 0,
      /** @type {int} */ loadCnt: 0,
      /** @type {int} */ bottom: 0, // bottom edge
      /** @type {int} */ right: 0, // right edge

      /** @type {string}  */ name: '',
      /** @type {string}  */ description: '',
      /** @type {?string}  */ tz: null,

      /**
             * Workflow duration - how long does it run (in milliseconds).
             * Zero (0) means duration has not been set. Workflow worker
             * doesn't let workflows run for more than 24h; current UI allows
             * maximum value of 23h55m.
             * @type {int}
             */
      duration: 0,

      /** @type {boolean} */ isModified: false,
      /** @type {boolean} */ isRecognized: true,

      /** @type {WorkflowConfig} */
      config: null,

      /** @type {Object} */
      bubbles: {},

      /** @type {Link[]} */
      links: [],

      /** @type {Bubble[]} */
      activeBubbles: [],

      /** @type {?Link} */
      activeLink: null,

      linkInfo: null,

      /** @type {?ParameterSetGroup} */
      paramSetGroup: null,

      /** @type {Object[]} */
      psgBuffer: [],

      /** @type {int} */
      psgBufUpdSeq: 0,

      /** @type {int} */
      psgBufUpdSeqSaved: 0,

      /** @type {SingleVar[]} */
      singles: [],

      /** @type {Dataset[]} */
      datasets: [],

      /**
             * Formulas, indexed by ID.
             * @type {Object.<string, Formula>}
             */
      formulas: {},

      /** @type {{deleted: DeletedFormula[]}} */
      formulaMeta: {
        deleted: [],
      },

      /** @type {?ScheduledJob} */
      job: null,

      /**
             * @type {?string}
             * @see http://www.quartz-scheduler.org/documentation/quartz-2.x/tutorials/crontrigger.html
             */
      cronExpr: '0 0 8 ? * MON-FRI *', // Default to 8 AM Monday through Friday.

      /** @type {?lim.JsCodeWindow} */
      jsWidget: null,
    };

    // this._ctxMenuHandler = modernizeEventHandler(_onCtxMenu.bind(this));
    // this._dblClickHandler = _dblClickHandler.bind(this);

    // this.events = new Events(
    //   'new-bubble', 'new-link',
    //   'change', 'ready',
    //   'min-width-changed', 'min-height-changed',
    // );

    // Intentionally not freezing `_data` and `_priv`.
    // Object.freeze(this);

    // canvas.addClass('mpdatawfgui')
    //   .on('mousedown', null, this, _mousedown)
    //   .on('contextmenu', null, null, this._ctxMenuHandler)
    //   .on('dblclick', null, null, this._dblClickHandler);

    // if (canvas.css('position') === 'static') { canvas.css('position', 'relative'); }
  }

  /**
     * Gets or sets this manager's UUID.
     * @param [id] {string}
     * @returns {(string|MpDataWorkflowGui.Manager)}
     */
  id(id) {
    const priv = this._priv;
    if (arguments.length < 1) {
      return priv.id;
    }
    Strings.requireNonEmpty(id, 'id');
    if (priv.id !== id) {
      if (Object.hasOwnProperty.call(_mgrIds, id)) {
        throw new Error('IllegalOperationException: id reserved by other manager');
      }
      delete _mgrIds[priv.id]; // unregister prev ID
      _mgrIds[id] = true; // register new ID
      priv.id = id;
    }

    return this;
  }

  /**
     * Attach some arbitrary data to this bubble manager,
     * and retrieve it later.
     * @param {string} name
     * @param {*} [val]
     * @returns {(boolean|*)} Getter returns the value associated with `name`;
     *          setter returns whether the value associated with `name` changed.
     */
  data(name, val) {
    return _getsetData(this._data, ...arguments);
  }

  /**
     * Returns whether this manager instance contains modifications
     * made by the user.
     * @returns {boolean}
     */
  isModified() {
    return _isModifiedMgr(this);
  }

  /**
     * @returns {boolean} Whether the workflow can be modified - user has Edit access
     *          AND workflow is fully validated/recognized.
     */
  canEdit() {
    return _canEdit(this);
  }

  /**
     * Destroys this manager.  Mainly, remove any unsaved data from the server.
     */
  destroy() {
    const priv = this._priv;
    const { jsWidget } = priv;

    // this._canvas.off('mousedown', null, _mousedown)
    //   .off('contextmenu', null, this._ctxMenuHandler)
    //   .off('dblclick', null, this._dblClickHandler);

    // if (jsWidget !== null) {
    //   jsWidget.destroy();
    //   priv.jsWidget = null;
    // }

    // _unpersistMgr(this.id());
  }

  /**
     * Returns whether a link between `source` and `target`
     * already exists.
     * @param {Bubble} source
     * @param {Bubble} target
     * @return {boolean}
     */
  isLinked(source, target) {
    _validBubble(source, 'source');
    _validBubble(target, 'target');
    return this._priv.links.some(link => (
      link.source() === source
            && link.target() === target
    ));
  }

  /**
     * Initiates the process of adding a bubble.  This method creates a ghost bubble
     * to follow the mouse around the browser, until user decides where to drop that
     * bubble.
     * @param {BubbleType} bubbleType
     * @param {jQuery.Event} event
     * @returns {MpDataWorkflowGui.Manager}
     */
  startBubbleDrop(bubbleType, event) {
    if (!(bubbleType instanceof BubbleType)) { throw new TypeError('bubbleType: MpDataWorkflowGui.BubbleType'); }

    _checkCanEdit(this);

    this._canvas.addClass(PENDING_BUBBLE_DROP);

    _deactivateBubbles(this);

    const ghost = _newGhost(bubbleType);

    const ctx = {
      mgr: this,
      type: bubbleType,
      ghost,
      handlers: null,
    };

    // const mouseMove = _dropMove.bind(ctx);
    // const mouseUp = _dropStop.bind(ctx);

    // ctx.handlers = {
    //   mousemove: mouseMove,
    //   mouseup: mouseUp,
    // };

    // EventLastCall.bind('mousemove', mouseMove)
    //   .bind('mouseup', mouseUp);

    // Position the ghost under the mouse.
    // mouseMove(event);

    return this;
  }

  /**
     * Initiates linkage operation.  This prevents bubbles from
     * being moved around, and links them together instead.
     * @this {MpDataWorkflowGui.Manager}
     * @param {LinkType} linkType
     * @param [onComplete] {Function}
     * @returns {MpDataWorkflowGui.Manager}
     */
  startLink(linkType, onComplete) {
    if (!LinkType.isEnumOf(linkType)) { throw new TypeError('linkType: MpDataWorkflowGui.LinkType'); }

    if (arguments.length > 1
            && typeof onComplete !== 'function') { throw new TypeError('onComplete: Function'); }

    this._canvas.addClass(PENDING_LINK);

    _deactivateBubbles(this);

    // _highlightLinkSources(Object.values(this._priv.bubbles), linkType);

    // const mouseDown = _linkMousedownOutside.bind(this);

    this._priv.linkInfo = {
      type: linkType,
      line: null,
      startBubble: null,
      handlers: {
        // mousedown: mouseDown,
        mousemove: null,
        mouseup: null,
      },
      onComplete,
    };

    // EventLastCall.bind('mousedown', mouseDown);

    return this;
  }

  /**
     * Returns whether the instance is ready for UI requests
     * or is currently loading its state.
     * @returns {boolean} Returns *true* if the instance is ready
     *                    for UI requests.  Otherwise *false*.
     */
  isReady() {
    const priv = this._priv;
    return (priv.loadCnt >= priv.loadReq);
  }

  /**
     * Loads the content of `config`, unless unsaved work is found in
     * (Markets) server.
     * @this {MpDataWorkflowGui.Manager}
     * @param {WorkflowConfig} config
     * @returns {MpDataWorkflowGui.Manager}
     */
  load(config, wfSchAndPsgPayload, formulas, vue) {
    if (!(config instanceof Config)) { throw new TypeError('config: Config'); }

    this._priv.config = config;
    _loadConfig(this, wfSchAndPsgPayload, formulas, vue);
    return this;
  }

  /**
     * Initiates the save sequence.  This method performs basic validation
     * before launching the save operation - complete parameter-sets, name,
     * duration, etc.  If validation fails, this method returns *false*,
     * after showing an error message to user.  In this case, `callback`
     * will not execute.
     *
     * Even after this method returns *true*, it still needs to validate
     * the workflow name for uniqueness within the owner's list,
     * asynchronously.  In this case, `callback` will execute, but listeners
     * should not assume that the save operation was successful.  Use
     * `isModified()` to check whether saving was successful.
     *
     * @this {MpDataWorkflowGui.Manager}
     * @param {function} [callback] Callback to be executed when save is
     *                              complete, or errors out.
     * @returns {boolean} Whether *save* operation was successfully initiated.
     */
  save(errors, vueVars) {
    return _save(this, errors, vueVars);
  }

  /**
     * Returns the scheduled job currently associated
     * with this manager (active edits are not included.)
     * @this {MpDataWorkflowGui.Manager}
     * @returns {?ScheduledJob}
     */
  getScheduledJob() {
    return this._priv.job;
  }

  /**
     * Returns the parameter-set-group currently associated
     * with this manager (active edits are not included.)
     * @returns {?ParameterSetGroup}
     */
  getPsg() {
    return this._priv.paramSetGroup;
  }

  /**
     * Returns the content of a parameter-set-group as a 2D array.
     * The 2D array comes along with a *last-modified* flag, which
     * can be used for next call to this method.  If `lastModified`
     * is provided and the parameter-set-group has not changed,
     * this method returns `null`.
     * @param [lastModified] {int} Last-modified value previously
     *                       retrieved from this method.
     * @returns {{payload: string[][], lastModified: int}}
     */
  getPsgMatrix(lastModified) {
    // We only need this method because lim.Grid resides outside
    // of MpDataWorkflowGui.Manager. Once we can more all GUI
    // into this Manager, we can better integrate to lim.Grid and
    // remove this public methods.

    const priv = this._priv;
    if (arguments.length > 0) {
      requireInteger(lastModified, 'lastModified');
      if (lastModified >= priv.psgBufUpdSeq) {
        return null;
      }
    }

    return {
      lastModified: priv.psgBufUpdSeq,
      payload: _psgTo2dArray(this),
    };
  }

  /**
     * Returns the column header that corresponds to each column
     * returned by `getPsgGrid()`.
     * @param {int} colIdx Non-negative (>= 0).
     * @returns {string}
     */
  psgColHeader(colIdx) {
    // We only need this method because lim.Grid resides outside
    // of MpDataWorkflowGui.Manager. Once we can more all GUI
    // into this Manager, we can better integrate to lim.Grid and
    // remove this public methods.
    requireNonNegativeInteger(colIdx, 'colIdx');

    if (colIdx === 0) {
      return Text.paramSetName;
    } if (colIdx === 1) {
      return Text.paramSetDescr;
    }
    const obj = _dsOrVar(this, colIdx, null);
    if (obj !== null) {
      return obj.getScriptName()
                    + _firstNonVoid(Text.paramSetSuffix[obj._bbl._type], '');
    }
    return Text.paramSetUnassigned;
  }

  /**
     * Launch the edit dialog for the given parameter-set name or
     * description, dataset, or (single) variable.
     *
     * Calling this method with a `row` value greater than the
     * current size of the parameter-set-group causes the
     * parameter-set-group buffer to grow.
     *
     * Calling this method with a `col` value greater than
     * the current size of a parameter-set is a no-op.
     *
     * @param {lim.Grid} grid
     * @param [callback] {Function} Callback if provided receives
     *                    the new text value to display in the grid.
     *                    The callback function will not be executed
     *                    if user aborts the editing operation.
     * @returns {boolean} Whether a dialog-box was launched.
     */
  editPsg(grid, callback) {
    // We only need this method because lim.Grid resides outside
    // of MpDataWorkflowGui.Manager. Once we can more all GUI
    // into this Manager, we can better integrate to lim.Grid and
    // remove this public methods.

    // TODO:
    // _validGrid(grid);

    if (arguments.length > 2
            && typeof callback !== 'function') throw new TypeError('callback: Function');

    const win = this._win;
    const range = grid.getSelection(0);
    if (range.colspan() > 1) {
      win.info(Text.multiColEditNotAvail);
      return false;
    }

    const rowStart = range.rowStart();
    const rowEnd = range.rowEnd();
    const col = range.colStart();
    const obj = _dsOrVar(this, col, null);
    const size = rowEnd + 1;

    if (obj instanceof Dataset) {
      const prodTypes = _getProdTypeFilter(obj._bbl, obj);

      _editDataset(this, obj, obj._bbl, rowStart, rowEnd, prodTypes, callback);
      return true;
    }

    if (obj instanceof SingleVar) {
      const dataTypeFilter = _getVarTypeFilter(obj, rowStart, rowEnd);

      _editSingleVar(this, obj, obj._bbl, rowStart, rowEnd, dataTypeFilter, callback);
      return false;
    }

    if (col > 1) return false; // Nothing that far.

    if (rowStart !== rowEnd) {
      win.info(Text.multiRowEditNotAvail);
      return false;
    }

    if (!_canEdit(this)) // For View mode, don't bother opening a dialog
    { return false; } // for param-set name or description.

    if (col === 0) {
      return _editParamSetMD(_ensurePsgSize(this, size),
        rowStart,
        CONSTANTS.PARAM_SET_NAME,
        _isValidParamSetName,
        callback);
    }

    if (col === 1) {
      return _editParamSetMD(_ensurePsgSize(this, size),
        rowStart,
        CONSTANTS.PARAM_SET_DESCR,
        _isValidParamSetDescr,
        callback);
    }
  }

  /**
     * Deletes the selected parameters from the grid.
     * @this {MpDataWorkflowGui.Manager}
     * @param {lim.Grid} grid
     * @returns {MpDataWorkflowGui.Manager}
     */
  deletePsg(grid) {
    // We only need this method because lim.Grid resides outside
    // of MpDataWorkflowGui.Manager. Once we can more all GUI
    // into this Manager, we can better integrate to lim.Grid and
    // remove this public methods.

    _validGrid(grid);

    _checkCanEdit(this);

    const mgr = this;
    const priv = mgr._priv;
    const range = grid.getSelection(0);
    const rowStart = range.rowStart();
    const rowEnd = range.rowEnd();
    const colStart = range.colStart();
    const colEnd = range.colEnd();
    const seqBefore = priv.psgBufUpdSeq;
    const prefixes = [];

    for (let col = colStart; col <= colEnd; col++) {
      const obj = _dsOrVar(mgr, col, null);

      if (obj instanceof Dataset) prefixes.push(obj.paramPrefix());
      else if (obj instanceof SingleVar) prefixes.push(obj.paramName());
      else if (col === 0) prefixes.push(CONSTANTS.PARAM_SET_NAME);
      else if (col === 1) prefixes.push(CONSTANTS.PARAM_SET_DESCR);
    }

    _.each(prefixes, (prefix) => {
      _delPsg(mgr, prefix, rowStart, rowEnd);
    });

    _trimPsg(priv.psgBuffer);

    if (priv.psgBufUpdSeq > seqBefore) {
      // We normally wouldn't send a *change* event this way.
      // lim.Grid should have been created internally,
      // so let's pretend it's this way...
      _notifChg(mgr);
    }

    return this;
  }

  /**
     * Binds to the Schedule component, so changes made to the schedule
     * UI will be monitored by this manager.
     *
     * @param {ScheduleUI} scheduleUi
     * @return {MpDataWorkflowGui.Manager}
     */
  bindScheduleUi(scheduleUi) {
    requireScheduleUi(scheduleUi, 'scheduleUi');

    const mgr = this;
    const priv = mgr._priv;

    scheduleUi.events.bind('change', (cronExpr) => {
      _checkCanEdit(mgr);

      priv.cronExpr = ((scheduleUi.isFormValid())
        ? cronExpr
        : null);
      priv.tz = scheduleUi.timeZone();
      priv.isModified = true;

      // We normally wouldn't send a *change* event this way.
      // ScheduleUI should have been created internally,
      // so let's pretend it's this way...
      _notifChg(mgr);
    });

    return this;
  }

  /**
     * Gets or sets the workflow's name in this manager's buffer.
     * @param {string} [name]
     * @returns {(string|MpDataWorkflowGui.Manager)}
     */
  name(name) {
    const priv = this._priv;
    if (arguments.length < 1) {
      return priv.name;
    }
    requireString(name, 'name');
    if (priv.name !== name) {
      priv.name = name;
      priv.isModified = true;
      // _persistMgrToMmce(this);
    }
    return this;
  }

  /**
     * Gets or sets the workflow's description in this manager's buffer.
     * @param {string} [description]
     * @returns {(string|MpDataWorkflowGui.Manager)}
     */
  description(description) {
    const priv = this._priv;
    if (arguments.length < 1) {
      return priv.description;
    }
    requireString(description, 'description');
    if (priv.description !== description) {
      priv.description = description;
      priv.isModified = true;
      // _persistMgrToMmce(this);
    }
    return this;
  }

  /**
     * Gets or sets the duration of this workflow (aka its *timeout*).
     * This method only affects the manager's buffer.
     * @param {int} [duration] - Non-negative.
     * @returns {(int|MpDataWorkflowGui.Manager)}
     */
  duration(duration) {
    const priv = this._priv;
    if (arguments.length < 1) {
      return priv.duration;
    }
    requireNonNegativeInteger(duration, 'duration');
    if (priv.duration !== duration) {
      priv.duration = duration;
      priv.isModified = true;
      // _persistMgrToMmce(this);
    }
    return this;
  }

  /**
     * Gets or sets the timezone of this workflow.
     * This method only affects the manager's buffer.
     * @param {string} [timezone]
     * @returns {(string|MpDataWorkflowGui.Manager)}
     */
  timezone(timezone) {
    const priv = this._priv;
    if (arguments.length < 1) {
      return priv.tz;
    }
    Strings.requireNonEmpty(timezone, 'timezone');
    if (priv.tz !== timezone) {
      priv.tz = timezone;
      priv.isModified = true;
      // _persistMgrToMmce(this);
    }
    return this;
  }

  /**
     * Gets the current cron expression from the buffer.
     * @returns {string}
     */
  cronExpression() {
    if (arguments.length < 1) {
      return this._priv.cronExpr;
    }
    throw new Error('cronExpression is not a setter');
  }

  /**
     * Returns the current validation messages, if any.
     * @returns {string[]}
     */
  getValidationMessages() {
    return _validateMgr(this);
  }

  /**
     * Returns payloads as they would be sent to Marketplace APIs,
     * for debugging purposes.
     * @returns {string}
     */
  getDebugPayloads(vue) {
    /** @type {MpDataWorkflowGui.Manager} */
    const mgr = this;

    const debug = [];
    const newSection = function (name, content) {
      debug.push(
        '',
        '=================================================================',
        name,
        '',
        content,
        '',
      );
    };
    const toString = function (o) {
      return JSON.stringify(o, null, 2);
    };

    // Parameter-set-group
    const psg = _buildPsg(mgr);
    newSection('PARAMETER-SET-GROUP', toString(MpApi.SetParameterSetGroup.getPayload(psg)));

    // Formulas
    _.each(_.filter(mgr._priv.bubbles, ALL_FORMULAS_FILTER), (bubble) => {
      const formula = _getFormula(bubble);
      let fuuid = formula.id;
      if (formula.isNew) fuuid = _localFormulaId(fuuid);

      newSection(`FORMULA -- ${ fuuid}`, _getFullSavedFormulaCode(bubble, false));
    });

    // Workflow config
    const builder = _buildConfig(mgr, true, vue);
    newSection('WORKFLOW CONFIG', toString(builder.toJson()));

    return debug.join(EOL);
  }

  /**
     * Destroys this manager ID.  Mainly, remove any unsaved data from the server.
     */
  static destroy(id) {
    _unpersistMgr(Strings.requireNonEmpty(id, 'id'));
  }
}

/* ***************************************************
 * Class: Bubble
 * *************************************************** */
/**
 * A bubble on screen.
 *
 * @constructor
 * @name Bubble
 * @private
 *
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} id
 * @param {BubbleType} type
 */
function Bubble(mgr, id, type) {
  // Enforce private constructor
  if (!_canConstructBubble) { throw new Error('UnsupportedOperationException: private constructor'); }

  // Allow just one instance to be constructed.
  _canConstructBubble = false;

  if (!(mgr instanceof Manager)) { throw new TypeError('mgr: MpDataWorkflowGui.Manager'); }

  /** @type {string} */
  this._id = id;

  /** @type {MpDataWorkflowGui.Manager} */
  this._mgr = mgr;

  /** @type {BubbleType} */
  this._type = BubbleType.valueOf(type);

  this._elm = `$('<div>').addClass('bubble no-text').addClass(type.valueOf())
    .data('bubble', this)
    .appendTo(mgr._canvas)
    .text(this._type.text())`;
  this._textElm = '$(\'<div>\').appendTo(this._elm)';

  /** @type {Link[]} */
  this._ins = [];

  /** @type {Link[]} */
  this._outs = [];

  /** @type {Object} */
  this._data = {
    _isNewNode: true,
  };

  Object.freeze(this);
}


/**
* Returns a CSS property of the Bubble's main HTML element
* as a number.
* @param {Bubble} bubble
* @param {string} prop
* @returns {Number}
* @private
*/
function _coord(bubble, prop) {
  return parseFloat(bubble._elm.css(prop));
}

/**
* Returns a bubble's top coordinate, as a number.
* @param {Bubble} bubble
* @returns {number}
* @private
*/
function _top(bubble) {
  return _coord(bubble, 'top');
}

/**
* Returns a bubble's left coordinate, as a number.
* @param {Bubble} bubble
* @returns {number}
* @private
*/
function _left(bubble) {
  return _coord(bubble, 'left');
}

/**
* Returns the height of a bubble.
* @param {Bubble} bubble
* @returns {number}
* @private
*/
function _height(bubble) {
  return bubble._elm.outerHeight(true);
}

/**
* Returns the width of a bubble.
* @param {Bubble} bubble
* @returns {number}
* @private
*/
function _width(bubble) {
  return bubble._elm.outerWidth(true);
}


/**
* Searches for a circular reference, returns *true* if found.
* @param {Bubble} src
* @param {Bubble} target
* @returns {boolean}
* @private
*/
function _isCircRef(src, target) {
  if (src === target) { return true; }

  let isCircRef = false;
  for (let i = 0, len = target._outs.length;
    i < len && isCircRef === false;
    i++) {
    if (_isCircRef(src, target._outs[i]._target)) { isCircRef = true; }
  }

  return isCircRef;
}

/**
* @param {Bubble[]} bubbles
* @param {...BubbleType} bubbleTypes
* @returns {boolean} Whether at least one bubble is found with (one of) the given type(s).
* @private
*/
function _canFindType(bubbles, bubbleTypes) {
  return bubbles.some(_bubbleTypeFilter.apply(this, Arrays.slice(arguments, 1)));
}

/**
* @param {Bubble} bubble
* @returns {boolean} Whether the bubble is a Save bubble linked from a Formula.
* @private
*/
function _isLinkedSaveBubble(bubble) {
  return (SAVE_FILTER(bubble)
          && bubble._ins.length === 1);
}

/**
* @param {Bubble} bubble
* @returns {boolean} Whether this bubble is a Formula that's linked to a (target) Save bubble.
* @private
*/
function _isSavingFormula(bubble) {
  return (ALL_FORMULAS_FILTER(bubble)
          && (_canFindType(_getLinkTarget(bubble), BubbleType.SAVE)
              || _isSavingCode(_getFormulaUserCode(bubble, ''))));
}

/**
* Filters the bubble of linktype success and returns the target bubble
* @param {Bubble} bubble
* @returns {Bubble[]} returns target bubble
* @private
*/
function _getLinkTarget(bubble) {
  return bubble._outs
    .filter(link => (link.type() === LinkType.SUCCESS))
    .map(link => link.target());
}

/**
* Returns the first saving bubble
* @param bubble
* @return returns first saving bubble
* @private
*/
function _getFirstSavingBubble(bubble) {
  return (_.findWhere(_getLinkTarget(bubble), { _type: BubbleType.SAVE }));
}

/**
 * Snaps one coordinate to the grid.
 * @param {number} num
 * @returns {number}
 * @private
 */
function _snapToGrid(num) {
  return Math.round(num / GRID_UNIT) * GRID_UNIT;
}

/**
* Finds the dataset name of the bubble.
* @param {Bubble} bubble
* @returns {?string} dataset name of the saving bubble
* @private
*/
function _getSavingDataSetName(bubble) {
  const savingBubble = _getFirstSavingBubble(bubble);
  if (savingBubble instanceof Bubble) {
    const savingDataSet = _getVar(savingBubble, bubble._mgr._priv.datasets);
    if (savingDataSet instanceof Dataset) {
      return savingDataSet._name;
    }
  }
  return null;
}
Bubble.prototype = /** @lends Bubble.prototype */ {
  constructor: Bubble,

  /**
   * Returns the bubble's unique ID.
   * @returns {string}
   */
  id() { return this._id; },

  /**
   * Returns the bubble's type.
   * @returns {BubbleType}
   */
  type() { return this._type; },

  /**
   * Removes the bubble from its manager, UI, and removes
   * all links to it.
   */
  remove() {
    const bId = this._id;
    const mgr = this._mgr;
    const bs = mgr._priv.bubbles;

    if (!Object.hasOwnProperty.call(bs, bId)) throw new Error('IllegalStateException: already removed this Bubble');

    delete bs[bId];
    Arrays.remove(this, mgr._priv.activeBubbles);

    // this._elm.remove();

    // Remove all links from/to this bubble.
    const links = Arrays.slice(this._ins);
    Arrays.addAll(links, this._outs);

    links.forEach(_removeLink);
  },

  /**
   * Gets or sets the text to be displayed under the bubble type.
   * @param [text] {string}
   * @param [tooltip] {string}
   * @returns {(string|Bubble)}
   */
  text(text, tooltip) {
    const elm = this._elm;
    const textElm = this._textElm;

    if (arguments.length < 1) return textElm.text();

    if (typeof text !== 'string') throw new TypeError('text: String');

    else {
      // textElm.text(text);

      // if (Strings.isNonEmpty(tooltip)) {
      //   // textElm.prop('title', tooltip);
      // } else {
      //   textElm.prop('title', undefined);
      // }

      // if (Strings.trim(text) === '') elm.addClass('no-text');
      // else elm.removeClass('no-text');

      // // Re-center if new text changed the width of the bubble.
      // const y = this.centerY();
      // const x = this.centerX();

      // this.centerAt(y, x);

      return this;
    }
  },

  /**
   * Attach some arbitrary data to this bubble,
   * and retrieve it later.
   * @param {string} name
   * @param {*} [val]
   * @returns {(boolean|*)} Getter returns the value associated with `name`;
   *          setter returns whether the value associated with `name` changed.
   */
  data(name, val) {
    return _getsetData(this._data, ...arguments);
  },

  /**
   * Positions this bubble so that its center is at the given coordinates.
   * @param {number} y
   * @param {number} x
   * @returns {Bubble}
   */
  centerAt(y, x) {
    requireNumber(y, 'y');
    requireNumber(x, 'x');

    const top = y - (_height(this) / 2);
    const left = x - (_width(this) / 2);

    this._elm.css({
      top,
      left,
    });

    // Move the links
    const { links } = this._mgr._priv;
    for (let i = 0, len = links.length; i < len; i++) {
      const link = links[i];
      const line = link._line;

      if (link._src === this) line.startAt(y, x);

      else if (link._target === this) line.endAt(y, x);
    }

    return this;
  },

  /**
   * Returns the number that represents the Y coordinate of this
   * bubble's center.
   * @returns {number}
   */
  centerY() {
    return _top(this) + (_height(this) / 2);
  },

  /**
   * Returns the number that represents the X coordinate of this
   * bubble's center.
   * @returns {number}
   */
  centerX() {
    return _left(this) + (_width(this) / 2);
  },

  /**
   * Returns the number that represents the Y coordinate of this
   * bubble's top edge.
   * @returns {number}
   */
  top() {
    return _top(this);
  },

  /**
   * Returns the number that represents the Y coordinate of this
   * bubble's bottom edge.
   * @returns {number}
   */
  bottom() {
    return _top(this) + _height(this);
  },

  /**
   * Returns the number that represents the X coordinate of this
   * bubble's left edge.
   * @returns {number}
   */
  left() {
    return _left(this);
  },

  /**
   * Returns the number that represents the X coordinate of this
   * bubble's right edge.
   * @returns {number}
   */
  right() {
    return _left(this) + _width(this);
  },
};

/**
* Snaps one bubble to the grid.
* @param {Bubble} bubble
* @private
*/
function _snapBubbleToGrid(bubble) {
  bubble.centerAt(_snapToGrid(bubble.centerY()),
    _snapToGrid(bubble.centerX()));
}

/**
* Snaps multiple bubbles to the grid.
* @param {Bubble[]} bubbles
* @private
*/
function _snapBubblesToGrid(bubbles) {
  bubbles.forEach(_snapBubbleToGrid);
}

/**
 * Loads bubbles, links, parameter-sets, etc. from Markets'
 * USER_SETTINGS table.
 *
 * @param {Object} payload An object that contains a key for the requested
 *                manager ID.
 * @this {MpDataWorkflowGui.Manager}
 * @private
 */
function _restoreFromMmce(payload) {
  const mgr = this;
  // const win = mgr._win;
  const priv = mgr._priv;
  const { config } = priv;
  const key = MANAGER_KEY_PREFIX + mgr.id();

  if (_isHandledError(payload)) { return; }

  // win.wait(false);

  // If we didn't receive any *unsaved* data from the server,
  // load the config information.
  if (isVoid(payload)
      || isVoid(payload[key])) {
    _loadConfig(mgr);

    _checkReady(mgr);
    return;
  }

  const serialized = payload[key];

  _setBasicFromConfig(mgr, config);

  _restoreBubbles(mgr, serialized.bubbles);
  _restoreLinks(mgr, serialized.links);
  _restoreDatasets(mgr, serialized.datasets);
  _restoreSingleVars(mgr, serialized.single_vars);

  serialized.psg.forEach((ps) => {
    priv.psgBuffer.push(_.clone(ps));
  });

  Object.assign(priv.formulas, _.indexBy(serialized.formulas.map(_.clone), 'id'));
  if (Object.hasOwnProperty.call(serialized, 'formula_meta')) {
    Objects.copyProperties(priv.formulaMeta,
      serialized.formula_meta,
      ['deleted'],
      true);
  }

  if (Object.hasOwnProperty.call(serialized, 'config')) {
    Objects.copyProperties(priv, serialized.config, PRIV_PROPS_TO_SERIALIZE);
  }

  _finalizeLoad(mgr);

  _checkReady(mgr);
}


/**
 * Creates a parameter name based on the chosen script variable.
 * This method returns the value of `varToSave` unless user
 * has already used that name in which case a numeric sequence
 * is appended to it.
 * @param {string} varToSave
 * @param {?Dataset} dataset
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {string}
 * @private
 */
function _goodVarName(varToSave, dataset, mgr) {
  const maxLen = USER_DEF_MAX_LENGTH - 3;
  // Allow for "_##" suffix.
  const base = varToSave;
  let varName = base;
  let cnt = 1;

  while (_isVarNameTaken(mgr, varName, dataset)) { varName = `${base }_${ ++cnt}`; }

  return varName;
}

/**
 * Returns the Dataset or SingleVar object associated with `varName`.
 * The search for the Dataset or SingleVar is case-insensitive.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} varName variable name.
 * @param [defaultValue] {*} Default value to return if no *param* is found for the given name.
 * @returns {(Dataset|SingleVar|*)}
 * @throws IllegalArgumentException - If a variable named after `varName` is not found
 *                                    in the manager and `defaultValue` is not provided.
 * @private
 */
function _getNamedParam(mgr, varName, defaultValue) {
  const priv = mgr._priv;
  const { singles } = priv;
  const { datasets } = priv;
  let idx = Arrays.indexOf(varName, singles, '_name', Strings.equalsIgnoreCase);

  if (idx >= 0) return singles[idx];

  idx = Arrays.indexOf(varName, datasets, '_name', Strings.equalsIgnoreCase);
  if (idx >= 0) return datasets[idx];

  if (arguments.length > 2) return defaultValue;

  throw new Error(`IllegalArgumentException: variable not found (${ varName })`);
}


/**
 * Returns whether a variable name is valid.
 * This method never throws, it only returns *false*.
 * @param {string} varName
 * @returns {boolean}
 * @private
 */
function _isValidVarName(varName, vue) {
  let isOk = false;
  try {
    _validVarName(varName, vue);
    isOk = true;
  } catch (ignore) { }

  return isOk;
}

/**
 * Returns whether the given variable name already exists
 * in the manager.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} varName
 * @param {?(Dataset|SingleVar)} dsOrVar
 * @returns {boolean} Returns *true* if `varName` is already taken, *false* otherwise.
 * @private
 */
function _isVarNameTaken(mgr, varName, dsOrVar) {
  const other = _getNamedParam(mgr, varName, null);
  return (other !== null
          && other !== dsOrVar);
}

/**
 * Validates a variable name element and shows errors to users if needed.
 * If valid, this method returns the variable name.  Otherwise, `null`.
 * @param {jQuery} varNameElm HTMLInputElement
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {?(Dataset|SingleVar)} dsOrVar
 * @returns {?string} Whether the variable name, or `null`.
 * @private
 */
function _validateVarNameElm(varNameElm, mgr, dsOrVar, vue) {
  const TextVar = Text.Edit.VariableName;
  const varName = varNameElm;

  if (Strings.isEmpty(varName)) {
    vue.showError = true;
    vue.errorMessage = TextVar.isRequired;
    return null;
  }

  if (!_isValidVarName(varName, vue)) {
    vue.showError = true;
    vue.errorMessage = TextVar.isInvalid;
    return null;
  }

  if (_isVarNameTaken(mgr, varName, dsOrVar)) {
    vue.showError = true;
    vue.errorMessage = TextVar.inUseByOther;
    return null;
  }

  return varName;
}

/**
 * Extracts the raw error message, without "Error:", "TypeError:"
 * "NullPointerException:", "IllegalArgumentException:" or
 * "IllegalStateException:" header.
 * @param {string} exText
 * @returns {string}
 * @private
 */
function _exceptToRaw(exText) {
  let clean = exText;
  let m = REGEX_EXCEPT_HEADER.exec(clean);

  while (m !== null) {
    clean = clean.substring(m.index + m[0].length);
    m = REGEX_EXCEPT_HEADER.exec(clean);
  }

  return clean;
}

/**
     * Returns whether all values have been provided to create a
     * MpProduct object.
     * @returns {boolean}
     */
function isFilled(mpProdSel) {
  const priv = mpProdSel;
  return (priv.columns.length !== 0
          && (priv.isKeySet === 'true' ? (priv.key[priv.columns[0]] !== '') : (priv.key.length !== 0))
          && priv.feed.dataSource !== ''
          && priv.feed.name !== '');
}

/**
 * Returns the index position of the item found in `list`.
 * @param {string} name - Name of the sought item - field or root. Case-insensitive.
 * @param {(MpField[]|MpRoot[])} list - List to look through.
 * @returns {int} Index position of the item found in `list`.
 * @private
 */
function _getItemIndex(name, list) {
  return Arrays.indexOf(name, list, 'name()', Strings.equalsIgnoreCase);
}


/**
 * Returns whether the currently selected feed is contract-enabled, which means its
 * key is comprised of three (3) fields: Root, DeliveryStart, DeliveryEnd.
 * @param {MpProductSel} mpProdSel
 * @returns {boolean} Whether the current feed is contract-enabled, false if *fields*
 *          have not arrived yet.
 * @private
 */
function _isContractEnabledFeed(mpProdSel) {
  const { keys } = mpProdSel.feed.keys;
  return (keys !== null
          && keys.length === 3
          && _getItemIndex('root', keys) >= 0
          && _getItemIndex('deliverystart', keys) >= 0
          && _getItemIndex('deliveryend', keys) >= 0);
}

/**
   * Returns whether the currently selected feed is contract-enabled, which means its
   * key is comprised of three (3) fields: Root, DeliveryStart, DeliveryEnd.
   * @returns {boolean} Whether the current feed is contract-enabled, false if *fields*
   *          have not arrived yet.
   */
function isContractEnabledFeed(mpProdSel) {
  return _isContractEnabledFeed(mpProdSel);
}


/**
 * Gets the selected product.  If everything validates
 * correctly, this method returns a MpProduct object,
 * or `null` if user didn't change anything.
 *
 * This method returns `false` if an error occurred.
 * @param {MpProductSel} mpProdSel
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {boolean} isSave - Whether this product is being
 * @returns {?(boolean|MpProduct)}
 * @private
 */
function _getMpProduct(mpProdSel, mgr, isSave, vue) {
  if (!isFilled(mpProdSel)) {
    vue.showError = true;
    vue.errorMessage = Text.Edit.ProductSel.incomplete;
    return false;
  }
  if (isSave
          && mpProdSel.type === 'Type.ROOTS'
          && !isContractEnabledFeed(mpProdSel)) {
    vue.showError = true;
    vue.errorMessage = Text.Edit.ProductSel.notContractEnabled;
    return false;
  }

  try {
    /* We use try/catch because there's a slight
    * possibility that what's displayed in MpProductSel
    * - columns, keys, roots - is not available anymore,
    * which could cause an error.  If that happens,
    * we catch the error and show it to user. */

    return mpProdSel;
  } catch (ex) {
    vue.showError = true;
    vue.errorMessage = _exceptToRaw(ex.toString());
    return false;
  }
}

/**
 * Ensures this parameter-set-group buffer has at least row,
 * and returns it.
 * @param {Object[]} psgBuffer
 * @returns {Object[]}
 * @private
 */
function _initPsg(psgBuffer) {
  if (psgBuffer.length === 0) {
    psgBuffer.push(_newParamSetObj(
      null,
      Text.paramSetDefaultName,
      Text.paramSetDefaultDescr,
    ));
  }

  return psgBuffer;
}

/**
 * Renames the given parameter in all parameter-sets.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} prevName
 * @param {string} newName
 * @private
 */
function _renPsg(mgr, prevName, newName) {
  const priv = mgr._priv;

  priv.psgBuffer.forEach((ps) => {
    ps[newName] = ps[prevName];
    delete ps[prevName];
  });

  priv.psgBufUpdSeq++;
}

/**
 * Updates the value of the given parameter name to the given value,
 * in all parameter-sets.
 * @param {MpDataWorkflowGui.Manager} mgr
 * @param {string} name
 * @param {string} val
 * @param {int} firstRow Non-negative.
 * @param {int} lastRow Non-negative.
 * @private
 */
function _updPsg(mgr, name, val, firstRow, lastRow) {
  const priv = mgr._priv;
  const narrow = Arrays.slice(priv.psgBuffer, firstRow, lastRow + 1);

  narrow.forEach((ps) => {
    ps[name] = val;
  });

  priv.psgBufUpdSeq++;
}

function getKeyOrRootsString(keyOrRoots) {
  if (typeof keyOrRoots === 'string') {
    return keyOrRoots;
  }
  return JSON.stringify(keyOrRoots);
}

/**
 * Saves a dataset into this manager's cache and returns whether
 * anything was changed.
 *
 * @param {{ mgr: MpDataWorkflowGui.Manager,
*           ui: DatasetBubbleUi,
*           bubble: Bubble,
*           dataset: Dataset,
*           displayed: Object,
*           firstRow: int,
*           lastRow: int,
*           callerCallback: function }} data
* @param {string} varName
* @param {?MpProduct} mpProduct
* @returns {boolean}
* @private
*/
function _saveDataset(data, varName, mpProduct, vue) {
  const ds = data.dataset;
  const { mgr } = data;
  const newPrefix = BubbleUtils.datasetPrefix(varName);
  let isDiff = false;

  _initPsg(mgr._priv.psgBuffer);

  if (!(ds instanceof Dataset)) {
    _newDataset(data.bubble, varName, vue);
    isDiff = true;
  } else if (ds._name !== varName) {
    const prevPrefix = BubbleUtils.datasetPrefix(ds._name);

    _newDataset(ds._bbl, varName, vue);

    CONSTANTS.DATASET_SUFFIXES.forEach((suffix) => {
      _renPsg(mgr, prevPrefix + suffix, newPrefix + suffix);
    });

    isDiff = true;
  }

  if (mpProduct !== null) {
    // We're not checking whether the new product is different
    // than the previous one, because it's possible
    // the previous product only applied to some of the
    // selected rows, but we need to apply it to all selected rows.

    // I M P O R T A N T ! ! !
    //
    // The Workflow Worker (aka Reactor) reads some of these
    // parameter names and values to catch circular references.
    //
    // DO NOT CHANGE THE NAME OR FORMAT OF ANY PARAMETER DEFINED BELOW
    // WITHOUT FIRST CHECKING THE REACTOR.

    const { firstRow } = data;
    const { lastRow } = data;
    const { feed } = mpProduct;
    const cols = JSON.stringify(mpProduct.columns);
    const keyOrRoots = ((mpProduct.isKeySet)
      ? mpProduct.key.toString()
      : getKeyOrRootsString(mpProduct.key)
    );

    _updPsg(mgr, newPrefix + CONSTANTS.DATASET_SUFFIX_FEED, feed.name, firstRow, lastRow);
    _updPsg(mgr, newPrefix + CONSTANTS.DATASET_SUFFIX_KEY_ROOTS, keyOrRoots, firstRow, lastRow);
    _updPsg(mgr, newPrefix + CONSTANTS.DATASET_SUFFIX_COLS, cols, firstRow, lastRow);
    _updPsg(mgr, newPrefix + CONSTANTS.DATASET_SUFFIX_PROVIDER, feed.provider, firstRow, lastRow);
    _updPsg(mgr, newPrefix + CONSTANTS.DATASET_SUFFIX_SOURCE, feed.dataSource, firstRow, lastRow);

    isDiff = true;
  }

  return isDiff;
}

// create a new paramsetgroup with new variable names
function _renameVarInPsg(mgr, prevName, currentName) {
  const { paramSetGroup } = mgr._priv;

  const newParamsets = paramSetGroup._paramsets.map((ps) => {
    const clone = JSON.parse(JSON.stringify(ps));

    const regex = new RegExp(`udef.(((ds).(${prevName}).\\w+)|(var.${prevName}))`);
    Object.keys(clone._params).forEach((k) => {
      if (k.match(regex)) {
        const temp = clone._params[k];
        delete clone._params[k];
        clone._params[k.replace(prevName, currentName)] = temp;
      }
    });
    return new ParameterSet(clone._id, clone._uuid, clone._name, clone._params, clone._descr);
  });

  mgr._priv.paramSetGroup = new ParameterSetGroup(paramSetGroup._id, paramSetGroup._name, newParamsets);
}

/**
 * Callback for Data Edit dialog-box.
 * @param {string} btnName "ok", "cancel"
 * @param {{ mgr: MpDataWorkflowGui.Manager,
*           ui: DatasetBubbleUi,
*           varName: variableName
*           bubble: Bubble,
*           dataset: Dataset,
*           displayed: ?(Object),
*           firstRow: int,
*           lastRow: int,
*           callerCallback: function }} data
* @returns {boolean} Return `false` to prevent dialog-box from closing.
* @private
*/
function _editDatasetCB(data, vue) {
  const { mgr } = data;
  const { bubble } = data;
  const varName = (data.isVarNameEdited === 1) ? _validateVarNameElm(data.varName, mgr, data.dataset, vue) : data.varName;

  if (varName == null) { return false; }

  const mpProduct = _getMpProduct({
    feed: data.feed, key: data.keyOrRoots, columns: data.cols, isKeySet: data.isKeySet,
  }, mgr, true, vue);

  if (mpProduct === false) {
    return false;
  }

  if (data.isVarNameEdited === 1) {
    _renameVarInPsg(mgr, data.prevVariableName, data.currentVariableName);
  }

  const isDsDiff = _saveDataset(data, varName, mpProduct, vue);
  let isBblDiff = false;
  const expTimeUi = data.expireSet;

  if (expTimeUi) {
    const expTime = data.expireTimeUi;
    const isExpSet = !!data.expireSet;
    bubble.data.expire_time = expTime;
    bubble.data.expire_set = isExpSet;
    // Using XOR operator ensures that all calls are executed even if the first one returns true.
    isBblDiff = bubble.data(SAVE_DEPENDENCY_EXPIRE_TIME, expTime)
    | bubble.data(SAVE_DEPENDENCY_EXPIRE_SET, isExpSet);
  }

  // let isBblDiffCorrection = false;
  // let { isEnableCorrection } = data;

  // if (isEnableCorrection !== null) {
  //   const { correctionDataUI } = data;
  //   const { isCurrentInputIsForwardCurve } = data;
  //   isEnableCorrection = !!data.isEnableCorrection;

  //   bubble.data.enable_correction = isEnableCorrection;
  //   bubble.data.correction_data = correctionDataUI;
  //   bubble.data.current_input_is_forward_curve = isCurrentInputIsForwardCurve;

  //   // Using XOR operator ensures that all calls are executed even if the first one returns true.
  //   isBblDiffCorrection = bubble.data('correction_data', correctionDataUI)
  //   | bubble.data('enable_correction', isEnableCorrection)
  //   | bubble.data('current_input_is_forward_curve', isCurrentInputIsForwardCurve);
  // }

  if (isDsDiff || isBblDiff) {
    if (isBblDiff) {
      mgr._priv.isModified = true;
    }

    // _notifChg(mgr);

    if (isDsDiff) {
      _scanFormulaErrors(mgr, vue, data.operation);
    }
  }

  // Delay in case an error occurs, preventing the dialog-box from closing
  // after everything is done.
  //  Functions.delay(function () {
  //      ui.mpProdSel.destroy();
  //      ui.wrap.remove();
  //  });
  return true;
}


/**
 * Creates a short string that represents an adjustment to
 * be applied to an IDate object.
 *
 * Example return values:
 * <ul>
 *     <li> "-1d" --> -1 day </li>
 *     <li> "-1D" --> -1 business day </li>
 *     <li> "-3H" --> -3 hours </li>
 *     <li> "-90m" --> -90 minutes </li>
 * </ul>
 * @param {RelativeDateValue} runDateRel
 * @returns {?string} `null` if the offset is not a integer.
 * @private
 */
function _getRelDateVal(runDateRel, offset, dir, unit) {
  if (Strings.isEmpty(offset)) { offset = '0'; } else if (!Numbers.isIntegerString(offset)) { return null; }

  return dir + offset + unit;
}

/**
 * Callback for Publish Edit dialog-box.
 * @param {{ mgr: MpDataWorkflowGui.Manager, ui: Object,
*           bubble: Bubble,
*           dataset: Dataset, displayed: Object,
*           firstRow: int, lastRow: int }} data
* @returns {boolean} Return `false` to prevent dialog-box from closing.
* @private
*/
function _editSaveCB(data, vue) {
  const { ui } = data;
  const { mgr } = data;
  const { bubble } = data;
  const { varToSave } = data;

  if (!Strings.isNonEmpty(varToSave)) {
    vue.showError = true;
    vue.errorMessage = Text.Edit.Save.varNotChosen;
    return false;
  }

  // Make up a local variable name, so user doesn't have to.
  const varName = (data.isVarNameEdited === 1) ? _goodVarName(varToSave, data.dataset, mgr) : data.varToSave;
  const mpProduct = _getMpProduct({
    feed: data.feed, key: data.keyOrRoots, columns: data.cols, isKeySet: data.isKeySet,
  }, mgr, true, vue);

  if (mpProduct === false) { return false; }

  const isCurve = (data.isKeySet ? (data.type === 'Type.ROOTS') : bubble.data(SAVE_AS_CONTRACTS));
  let relDateVal = null;

  if (isCurve) {
    relDateVal = _getRelDateVal(data.curveDate, data.offset, data.dir, data.unit);
    if (relDateVal === null) {
      const { offset } = data;
      const TextS = Text.Edit.Save;

      vue.showError = true;
      vue.errorMessage = TextS.badCurveDate.replace('[offset]', offset);
      return false;
    }
  }

  // Everything is validated, time to save.

  const isDsDiff = _saveDataset(data, data.varName, mpProduct, vue);
  let isBblDiff = false;

  if (bubble.data(SAVE_SRC_VAR, varToSave)) isBblDiff = true;
  if (bubble.data(SAVE_AS_CONTRACTS, isCurve)) isBblDiff = true;

  if (isCurve) {
    isBblDiff |= bubble.data(SAVE_DELIV_TYPE, data.delivType);
    isBblDiff |= bubble.data(SAVE_WITH_GAPS, data.withGaps);
    isBblDiff |= bubble.data(SAVE_CURVE_DATE_ADJ, relDateVal);
  } else {
    isBblDiff |= bubble.data(SAVE_DELIV_TYPE, null);
    isBblDiff |= bubble.data(SAVE_CURVE_DATE_ADJ, null);
  }

  _setBubbleError(bubble, false, vue);

  if (isBblDiff) {
    mgr._priv.isModified = true;
    _setModifiedFormula(bubble._ins[0]._src);
  }
  // if (isDsDiff || isBblDiff) {
  //   _notifChg(mgr);
  // }
  if (isDsDiff) {
    _scanFormulaErrors(mgr, vue, data.operation);
  }

  // Delay in case an error occurs, preventing the dialog-box from closing
  // after everything is done.
  // Functions.delay(() => {
  //   ui.mpProdSel.destroy();
  //   ui.wrap.remove();
  // });
  return true;
}


/**
 * Saves a dataset into this manager's cache and returns whether
 * anything was changed.
 *
 * @param {{ mgr: MpDataWorkflowGui.Manager, ui: Object,
 *           bubble: Bubble,
 *           singleVar: SingleVar, displayed: ?(string),
 *           firstRow: int, lastRow: int, callerCallback: function }} data
 * @param {string} varName
 * @param {?{dataType: VarType, value: string}} varProps
 * @returns {boolean}
 * @private
 */
function _saveSingleVar(data, varName, varProps, vue) {
  const sv = data.singleVar;
  const { mgr } = data;
  const paramName = CONSTANTS.PARAM_PREFIX_SINGLE_VAR + varName;
  let isDiff = false;
  const hasVarProps = !isVoid(varProps);

  _initPsg(mgr._priv.psgBuffer);

  if (!(sv instanceof SingleVar)) {
    // new variable being saved for the first time.
    _newSingleVar(data.bubble, varName, varProps.dataType);
    isDiff = true;
  } else if (sv._name !== varName) {
    // existing variable being renamed.
    const prevName = sv.paramName();

    _newSingleVar(sv._bbl, varName, (hasVarProps) ? varProps.dataType : sv._type);
    _renPsg(mgr, prevName, paramName);

    isDiff = true;
  } else if (hasVarProps && sv._type !== varProps.dataType) {
    // updating the data-type of an existing variable.
    _newSingleVar(sv._bbl, varName, varProps.dataType);
    isDiff = true;
  }

  if (hasVarProps) {
    // We're not checking whether the new value is different
    // than the previous one, because it's possible
    // the previous value only applied to some of the
    // selected rows, but we need to apply it to all selected rows.

    const { firstRow } = data;
    const { lastRow } = data;

    _updPsg(mgr, paramName, varProps.value, firstRow, lastRow);

    isDiff = true;
  }

  return isDiff;
}


/**
 * Gets the selected product.  If everything validates
 * correctly, this method returns a MpProduct object,
 * or `null` if user didn't change anything.
 *
 * This method returns `false` if an error occurred.
 * @param {SingleVarEdit} svUi
 * @param {MpDataWorkflowGui.Manager} mgr
 * @returns {?(boolean|{dataType: VarType, value: string})}
 * @private
 */
function _getSingleVarValue(svUi, mgr, vue) {
  if (!svUi) {
    return null;
  }
  const TextSV = Text.Edit.SingleVarEdit;
  if (!svUi.dataType) {
    vue.showError = true;
    vue.errorMessage = TextSV.invalid;
    return false;
  }

  const val = svUi.value;
  if (val.length > 500) {
    // Based on database field definition as of 2/1/2017: VARCHAR(512)
    vue.showError = true;
    vue.errorMessage = TextSV.tooLong;
    return false;
  }

  return {
    dataType: svUi.dataType,
    value: val,
  };
}


/**
 * Callback for SingleVar Edit dialog-box.
 * @param {{ mgr: MpDataWorkflowGui.Manager, ui: Object,
*                bubble: Bubble,
*                singleVar: SingleVar, displayed: ?(string),
*                firstRow: int, lastRow: int, callerCallback: function }} data
* @returns {boolean} Return `false` to prevent dialog-box from closing.
* @private
*/
function _editSingleVarCB(data, vue) {
  // const { ui } = data;

  const { mgr } = data;
  const varName = (data.isVarNameEdited === 1) ? _validateVarNameElm(data.singleVarEdit.varName, mgr, data.singleVar, vue)
    : data.singleVarEdit.varName;

  if (varName === null) { return false; }

  if (data.isVarNameEdited === 1) {
    _renameVarInPsg(mgr, data.prevVariableName, data.currentVariableName);
  }

  const varProps = _getSingleVarValue(data.singleVarEdit, mgr, vue);
  if (varProps === false) { return false; }

  if (_saveSingleVar(data, varName, varProps, vue)) {
    _scanFormulaErrors(mgr, vue, data.operation);
  }

  // Delay in case an error occurs, preventing the dialog-box from closing
  // after everything is done.
  // Functions.delay(() => {
  //   ui.singleVarEdit.destroy();
  //   ui.wrap.remove();
  // });
  return true;
}


function _editNotificationCB(data) {
  const { mgr } = data;
  const { bubble } = data;
  const { recipients } = data;
  let { body } = data;

  // TODO: Validate recipients

  // For now Crina wants to hard code the subj.
  const subject = 'QA Alerts {{workflow.name}} {{parameter-set.name}}';

  // Append the properties for errors to the body of the notification.
  body += `\n\n${ ERR_PROP_STR}`;

  mgr._priv.isModified = true;

  bubble.data(NOTIF_METHOD, 'smtp');
  bubble.data(NOTIF_SMTP_SUBJECT, subject);
  bubble.data(NOTIF_SMTP_TO, recipients);
  bubble.data(NOTIF_SMTP_MSG, body.trim());

  return true;
}


export default Manager;
export {
  _getFullSavedFormulaCode, _getDefaultFormulaCode, _getVarList, _isFreeformFormula, _listFormulaSteps, _newBubble, _newLink,
  _removeLink, _removeBubbleMenuCB, _editDatasetCB, _editSaveCB, _getVarsInFormula, _editSingleVarCB,
  _editNotificationCB, _saveFormulaCodeInBubble, _getValidatedFormulaUiCode,
  _formulaSection, _getValidatedUserCode, _getValidatedFreeformCode, _processScheduledJobResponse, _goodVarName,
  _availVars, _getSavingDataSetName, _getSaveBubbles, _getFullFormulaCodeWithoutAST, _scanFormulaErrors, _getFirstFormulaError,
  _buildPsg,
};
