import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import All from './views/All';
import Products from './views/Products';
import Research from './views/Research';
import Developer from './views/Developer';
import DataManagement from './views/DataManagement';
import ResearchReports from './components/Subpages/ResearchReports';
import Feeds from './components/Feeds/Feed';
import FeedList from './components/Feeds/FeedList';
import NewFeed from './components/Feeds/NewFeed';
import FeedDetails from './components/Feeds/FeedDetails';
import FeedGroup from './components/Feeds/FeedGroup';
import contentContainer from './views/ContentContainer';
import PageNotFound from './views/PageNotFound';
import Group from './components/Group/Group';
import WFDocs from './components/Guides/Workflow/WFDocs';
import Dashboard from './views/Dashboard';
import DashboardHome from './components/Dashboard/DashboardHome';
import { isLoggedIn } from './utils/authService';
import BBLoader from './components/Bloomberg/Loader';
import BBConfigList from './components/Bloomberg/ConfigList';
import BBNewConfig from './components/Bloomberg/NewConfig';
import BBConfigDetails from './components/Bloomberg/ConfigDetails';
import BBNewAccount from './components/Bloomberg/NewAccount';
import BBNewYellowKey from './components/Bloomberg/NewYellowKey';
import BBYellowKeyList from './components/Bloomberg/YellowKeyList';
import BBYellowKeyDetails from './components/Bloomberg/YellowKeyDetails';
import BBAccountList from './components/Bloomberg/AccountList';
import BBAccountDetails from './components/Bloomberg/AccountDetails';
import AuditLog from './components/AuditLog/AuditLog';
import AnalyticsHome from './components/Dashboard/AnalyticsHome';
import Catalog from './views/Catalog';
import NavigationHome from './components/Dashboard/NavigationHome';
import DataAnalytics from './views/DataAnalytics';
import Navigationv from './views/Navigationv';
import NavigationFeedHome from './components/Catalog/NavigationFeedHome';
import LayoutDefault from './components/Layouts/LayoutDefault';
import User from './components/User/User';
import Home from './components/CduiHome/Home';
import DataAlert from './components/AuditLog/DataAlert';
import Reports from './components/Reports/Reports';
import AuditReport from './components/Reports/AuditReport';
import AddInTemplate from './components/Subpages/AddInTemplate.vue';
import Scheduler from './components/Scheduler/Scheduler';
import WorkflowHome from './components/Workflow/WorkflowHome';
import WorkflowNotifications from './components/Workflow/WorkflowNotifications';
import JavaScriptFormula from './components/Workflow/JavaScriptFormula';
import FeedKeyDelete from './components/Catalog/FeedKeyDelete.vue';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', redirect: '/' },
    {
      path: '/all',
      name: 'all',
      component: All,
      props: true,
      meta: {
        title: 'All | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Home',
      },
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home | Zema Global Commodity Data, Zema Global Commodities and Energy',
        requiresAuth: false,
        heading: 'Home',
      },
    },
    {
      path: '/all/:productId',
      name: 'all',
      component: contentContainer,
      props: true,
      meta: {
        title: 'Products | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Products/',
      },
    },
    {
      path: '/all/excelAddIn/:type(templates|archive)',
      name: 'addInTemplate',
      component: AddInTemplate,
      meta: {
        title: 'Products | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Excel AddIn',
      },
    },
    {
      path: '/products',
      name: 'products',
      component: Products,
      props: true,
      meta: {
        title: 'Products | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Products',
      },
    },
    {
      path: '/products/:productId',
      name: 'products',
      component: contentContainer,
      props: true,
      meta: {
        title: 'Products | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Products',
      },
    },
    {
      path: '/research',
      name: 'research',
      component: Research,
      meta: {
        title: 'Research | Zema Global Commodity Data, Zema Global Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Research',
      },
    },
    {
      path: '/research/reports',
      name: 'research_reports',
      component: ResearchReports,
      props: true,
      meta: {
        title: 'Reports | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Research/Reports',
      },
    },
    {
      path: '/datamanagement',
      name: 'dataManagement',
      component: DataManagement,
      meta: {
        title: 'Data Management | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Data Management',
      },
    },
    {
      path: '/scheduler',
      component: Scheduler,
      meta: {
        title: 'Scheduler Configuration | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Scheduler Configuration',
      },
      children: [
      ],
    },
    {
      path: '/feeds/deleteKey',
      component: Navigationv,
      children: [
        {
          path: '',
          components: {
            home: FeedKeyDelete,
          },
          meta: {
            layout: LayoutDefault,
            title: 'FeedKeyDelete | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            heading: 'Feeds/Feed Key Delete',
          },
        },
      ],
    },
    {
      path: '/feeds',
      component: Feeds,
      meta: {
        title: 'Feed Management | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Feeds',

      },
      children: [
        {
          path: '',
          name: 'FeedList',
          component: FeedList,
          meta: {
            layout: LayoutDefault,
            heading: 'Feeds/Feed List',
          },
        },
        {
          path: 'new',
          name: 'NewFeed',
          component: NewFeed,
          meta: {
            layout: LayoutDefault,
            heading: 'Feeds/New Feed',
          },
        },
        {
          path: 'clone',
          name: 'CloneFeed',
          component: NewFeed,
          meta: {
            layout: LayoutDefault,
            heading: 'Feeds/Clone Feed',
          },
        },
        {
          path: 'feedGroup',
          name: 'feedGroup',
          component: FeedGroup,
          meta: {
            layout: LayoutDefault,
            heading: 'Feeds/Feed Group',
          },
        },
        {
          path: 'groups',
          name: 'groups',
          component: Group,
          meta: {
            title: 'Group | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Feeds/User Group',
          },
        },
        {
          path: ':feedName',
          name: 'FeedDetails',
          component: FeedDetails,
          meta: {
            layout: LayoutDefault,
            heading: 'Feeds/Feed Details',
          },
        },
      ],
    },
    {
      path: '/workflow',
      component: WorkflowHome,
      meta: {
        title: 'Workflow Configuration | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Workflow Configuration',
      },
      children: [
      ],
    },
    {
      path: '/workflowNotifications',
      component: WorkflowNotifications,
      meta: {
        title: 'Workflow Notifications | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Workflow Notifications',
      },
      children: [
      ],
    },
    {
      path: '/dataManagement/:productId',
      component: contentContainer,
      props: true,
      meta: {
        title: 'Data Management | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Data Management',
      },
    },
    {
      path: '/developer',
      name: 'developer',
      component: Developer,
      meta: {
        title: 'Developer | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Developer',
      },
    },
    {
      path: '/developer/:productId',
      component: contentContainer,
      props: true,
      meta: {
        title: 'Developer | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Developer',
      },
    },
    {
      path: '/javascriptFormula',
      component: JavaScriptFormula,
      meta: {
        title: 'Developer | Morningstar Commodity Data, Morningstar Commodities and Energy',
        layout: LayoutDefault,
        heading: 'JavaScript Formula',
      },
      children: [
      ],
    },
    {
      path: '/guides/workflow',
      name: 'WorkflowGuide',
      component: WFDocs,
      props: true,
      meta: {
        title: 'Workflow Guide | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Workflow Guide',
      },
    },
    {
      path: '/bb',
      component: BBLoader,
      meta: {
        title: 'Bloomberg Loader | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Bloomberg Loader',
      },
      children: [
        {
          path: '',
          name: 'BBConfigList',
          component: BBConfigList,
          meta: {
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Config List',
          },
        },
        {
          path: 'config/new',
          name: 'BBNewConfig',
          component: BBNewConfig,
          meta: {
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/New Config',
          },
        },
        {
          path: 'config/:configName',
          name: 'BBConfigDetails',
          component: BBConfigDetails,
          meta: {
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Config Details',
          },
        },
        {
          path: 'config/:configName/edit',
          name: 'BBEditConfig',
          component: BBNewConfig,
          meta: {
            title: 'Edit Config | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Edit Config',
          },
        },
        {
          path: 'account/new',
          name: 'BBNewAccount',
          component: BBNewAccount,
          props: true,
          meta: {
            title: 'New BB Account | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/New Account',
          },
        },
        {
          path: 'yellowkey/new',
          name: 'BBNewYellowKey',
          component: BBNewYellowKey,
          meta: {
            title: 'New Yellow Key | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/New Yellow Key',
          },
        },
        {
          path: 'yellowkey/list',
          name: 'BBYellowKeyList',
          component: BBYellowKeyList,
          meta: {
            title: 'Yellow Key List',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Yellow Key List',
          },
        },
        {
          path: 'yellowkey/:yellowKeyName',
          name: 'BBYellowKeyDetails',
          component: BBYellowKeyDetails,
          meta: {
            title: 'Yellow Key Details | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Yellow Key Details',
          },
        },
        {
          path: 'account/list',
          name: 'BBAccountList',
          component: BBAccountList,
          meta: {
            title: 'Account List | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Account List',
          },
        },
        {
          path: 'account/:accountName',
          name: 'BBAccountDetails',
          component: BBAccountDetails,
          meta: {
            title: 'Account Details | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            layout: LayoutDefault,
            heading: 'Bloomberg Loader/Account Details',
          },
        },
      ],
    },
    {
      path: '/auditLog',
      name: 'auditLog',
      component: AuditLog,
      meta: {
        title: 'AuditLog | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Audit Log',
      },
    },
    {
      path: '/user',
      name: 'user',
      component: User,
      meta: {
        title: 'User Management | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'User Management',
      },
    },
    {
      path: '/dataAlert',
      name: 'dataAlert',
      component: DataAlert,
      meta: {
        title: 'Data Alert | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Data Alert',
      },
    },
    {
      path: '/dataAnalytics',
      component: DataAnalytics,
      meta: { heading: 'Data Analytics/Overview' },
      children: [
        {
          path: '',
          components: {
            home: AnalyticsHome,
          },
          meta: {
            layout: LayoutDefault,
            title: 'Data Analytics | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            heading: 'Data Analytics/Overview',
          },
        },
        {
          path: 'pjm',
          components: {
            home: AnalyticsHome,
          },
          meta: {
            layout: LayoutDefault,
            heading: 'Data Analytics/PJM',
          },
        },
        {
          path: 'ercot',
          components: {
            home: AnalyticsHome,
          },
          meta: {
            layout: LayoutDefault,
            heading: 'Data Analytics/ERCOT',
          },
        },
      ],
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: {
        title: 'Reports | Zema Global Commodity Data, Zema Global  Commodities and Energy',
        layout: LayoutDefault,
        heading: 'Reports',
      },
    },
    {
      path: '/reports/viewReport/:company',
      name: 'View Report',
      component: AuditReport,
      meta: {
        layout: LayoutDefault,
        heading: 'Reports/ViewReport',
      },
    },
    {
      path: '/catalog',
      component: Catalog,
      children: [
        {
          path: '',
          components: {
            home: NavigationHome,
          },
          meta: {
            layout: LayoutDefault,
            title: 'Catalog | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            heading: 'Catalog',
          },
        },
      ],
    },
    {
      path: '/navigation',
      component: Navigationv,
      children: [
        {
          path: '',
          components: {
            home: NavigationFeedHome,
          },
          meta: {
            layout: LayoutDefault,
            title: 'Navigation | Zema Global Commodity Data, Zema Global  Commodities and Energy',
            heading: 'Catalog/Navigation',
          },
        },
      ],
    },
    {
      path: '/dashboard',
      component: Dashboard,
      children: [
        {
          path: '',
          components: {
            home: DashboardHome,
          },
          meta: {
            layout: LayoutDefault,
            heading: 'Dashboard',
          },
        },
      ],
    },
    { path: '*', component: PageNotFound },
  ],
});
// this is temporary solution, need to find out something to update heading inside meta only
function updateHeading(to) {
  if (to.params) {
    if (to.params.productId) {
      let { productId } = to.params;
      productId = productId.replace(/([A-Z])/g, ' $1');
      productId = productId.charAt(0).toUpperCase() + productId.slice(1);
      to.meta.heading = `Products/${ productId}`;
    } else if (to.params.type) {
      let { type } = to.params;
      type = type.replace(/([A-Z])/g, ' $1');
      type = type.charAt(0).toUpperCase() + type.slice(1);
      to.meta.heading = `Excel AddIn/${ type}`;
    }
  }
  return to;
}
async function seamlessAuth(to, next) {
  try {
    const authArr = atob(to.query.authToken).split(':');
    await store.dispatch('loginModule/doLogin', {
      userName: authArr[0],
      password: authArr[1],
    }).then((res) => {
      if (store.state.loginModule.accessToken
        && store.state.loginModule.isAuthenticated) {
        next();
      } else {
        store.dispatch('loginModule/signInFailure');
        store.dispatch('loginModule/signOut');
        next('/');
      }
    });
  } catch (error) {
    store.dispatch('loginModule/signInFailure');
    store.dispatch('loginModule/signOut');
  }
}

router.beforeEach(async (to, from, next) => {
  store.dispatch('homeModule/routerChanged', to.fullPath);
  store.dispatch('navigation/updateActiveHeading', updateHeading(to));
  if (to.query.authToken) {
    await seamlessAuth(to, next);
  } else {
    store.dispatch('homeModule/fetchUserInfo');
    if (isLoggedIn()) {
      if (to.fullPath === '/') {
        next('/all');
      } else {
        next();
      }
    } else if (to.path !== '/' || store.state.loginModule.idleTimeout) {
      const { fullPath } = from.fullPath.includes('return_to=%2F') || from.fullPath === '/' ? to : from;
      store.state.loginModule.idleTimeout = false;
      next(`/?session_expired=true&return_to=${fullPath}`);
    }
    // To DO need to implement alert on login page if session is expired
    /*  if(to.query.session_expired){
      store.state.loginModule.sessionExpired = true;
    }
  } */
    next();
  }
  document.title = to.meta.title ? to.meta.title : 'Commodity App';
});

export default router;
