<template>
  <div
    class="wf-edit-action"
  >
    <mds-notification-container v-if="showError">
      <!-- Tinted -->
      <mds-notification
        key="error-tinted"
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideError"
      >
        {{ errorMessage }}
      </mds-notification>
    </mds-notification-container>
    <mds-loader
      v-if="showLoader"
      size="small"
      aria-label="Small Loader"
    />
    <mds-form
      v-else-if="multiValue"
    >
      <mds-row>
        <mds-col :cols="2">
          <div style="padding-top: 5%;">
            <span><strong>Variable Name :</strong></span>
          </div>
        </mds-col>
        <mds-col :cols="4">
          <input
            v-model="variableName"
            maxlength="30"
            placeholder="Variable Name"
            :class="isNewNode === 'false' || isNewNode === false ? 'disabled mds-form__input' : 'mds-form__input'"
            @keydown.tab.prevent="disableTab"
            @click="nameError = false"
          >
          <span
            v-if="nameError"
            id="bubbleName"
            class="mds-form__field-error"
            role="alert"
          >
            <span
              class="mds-form__field-error-text"
              style="margin-bottom: 10px;"
            >
              Please enter valid variable name. (check entered bubble name already declered or empty)
            </span>
          </span>
        </mds-col>
        <mds-col />
      </mds-row>
      <mds-row>
        <div
          v-if="variableName !== ''"
          style="margin-top: 2%; margin-left: 1%;"
        >
          <span><strong>${{ variableName }}</strong></span>
        </div>
      </mds-row>
      <mds-row>
        <div style="margin-top: 1%; margin-left: 1%;">
          <div><span>Multiple products are defined across different input sets.</span></div>
          <div style="margin-top: 1%;">
            <mds-button-container left-aligned>
              <mds-button
                type="button"
                variation="secondary"
                @click="overwriteAllProducts()"
                @keydown.tab.prevent="disableTab"
              >
                Overwrite all products.
              </mds-button>
            </mds-button-container>
          </div>
        </div>
      </mds-row>
    </mds-form>
    <mds-form
      v-else
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <mds-row>
        <mds-col :cols="2">
          <div style="padding-top: 0.75%;">
            <span><strong>Variable Name :</strong></span>
          </div>
        </mds-col>
        <mds-col :cols="4">
          <input
            v-model="variableName"
            maxlength="30"
            placeholder="Variable Name"
            class="mds-form__input"
            @keydown.tab.prevent="disableTab"
            @click="nameError = false"
          >
          <span
            v-if="nameError"
            id="bubbleName"
            class="mds-form__field-error"
            role="alert"
          >
            <span
              class="mds-form__field-error-text"
              style="margin-bottom: 10px;"
            >
              Please enter valid variable name. (check entered bubble name already declered or empty)
            </span>
          </span>
        </mds-col>

        <mds-col />
      </mds-row>
      <div
        v-if="variableName !== ''"
        style="margin-top: 2%; margin-bottom: 2%;"
      >
        <span><strong>${{ variableName }}</strong></span>
      </div>
      <mds-row>
        <mds-col
          :cols="2"
          style="padding-top: 0.75%;"
        >
          <span><strong>Data-type :</strong></span>
        </mds-col>
        <mds-col :cols="4">
          <mds-combo-box
            v-model="variableDataTypeObj"
            placeholder="Select an Option"
            :data-set="[
              { text: 'String', value: 'string' },
              { text: 'Number', value: 'float' },
              { text: 'Date', value: 'date' },
              { text: 'Boolean', value: 'boolean' },
              { text: 'JSON', value: 'json' },
            ]"
            label=""
            @click="varTypeError = false"
            @input="typeChangeListener()"
            @keydown.tab.prevent="disableTab"
          />
          <span
            v-if="varTypeError"
            id="bubbleName"
            class="mds-form__field-error"
            role="alert"
          >
            <span
              class="mds-form__field-error-text"
              style="margin-bottom: 10px;"
            >
              Please select variable type.
            </span>
          </span>
        </mds-col>
        <mds-col />
      </mds-row>
      <div
        v-if="variableDataTypeObj[0] === 'float' || variableDataTypeObj[0] === 'number'"
      >
        <mds-row>
          <mds-col
            :cols="2"
            style="padding-top: 0.75%;"
          >
            <span><strong>Value :</strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-input
              v-model="variableValue"
              label=""
              @click="varValueError = false"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
      </div>
      <div v-if="variableDataTypeObj[0] === 'boolean'">
        <div style="margin-top: 2%;">
          <mds-row>
            <mds-col
              :cols="2"
              style="padding-top: 0.75%;"
            >
              <span><strong>Value :</strong></span>
            </mds-col>
            <mds-col :cols="4">
              <mds-fieldset
                variation="radio-group"
                horizontal
              >
                <mds-radio-button
                  v-model="variableValue"
                  value="true"
                  label=""
                  @click="varValueError = false"
                  @keydown.tab.prevent="disableTab"
                >
                  True
                </mds-radio-button>
                <mds-radio-button
                  v-model="variableValue"
                  value="false"
                  label=""
                  @click="varValueError = false"
                  @keydown.tab.prevent="disableTab"
                >
                  False
                </mds-radio-button>
              </mds-fieldset>
            </mds-col>
            <mds-col />
          </mds-row>
        </div>
      </div>
      <div v-if="variableDataTypeObj[0] === 'json'">
        <mds-row>
          <mds-col
            :cols="2"
            style="padding-top: 0.75%;"
          >
            <span><strong>Value :</strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-textarea
              v-model="variableValue"
              label=""
              @click="varValueError = false"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
      </div>
      <div v-if="variableDataTypeObj[0] === 'string'">
        <mds-row>
          <mds-col
            :cols="2"
            style="padding-top: 0.75%;"
          >
            <span><strong>Value :</strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-input
              v-model="variableValue"
              label=""
              @click="varValueError = false"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
      </div>
      <div v-if="variableDataTypeObj[0] === 'date'">
        <mds-row>
          <mds-col
            :cols="2"
            style="padding-top: 0.75%;"
          >
            <span><strong>Value :</strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-date-picker
              v-model="variableValue"
              :min-max-dates="minMaxDates"
              label=""
              @click="varValueError = false"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
      </div>
      <div>
        <mds-row>
          <mds-col :cols="2" />
          <mds-col :cols="4">
            <span
              v-if="varValueError"
              id="bubbleName"
              class="mds-form__field-error"
              role="alert"
              style="margin-top: 10px;"
            >
              <span
                class="mds-form__field-error-text"
              >
                Entered value is not valid per the selected data-type.
              </span>
            </span>
          </mds-col>
          <mds-col />
        </mds-row>
      </div>
    </mds-form>

    <div v-if="isEditable">
      <hr>
      <mds-row>
        <mds-col />
        <mds-col style="display: flex; justify-content: right;">
          <mds-button
            variation="primary"
            @click="saveForm()"
            @keydown.tab.prevent="disableTab"
          >
            Ok
          </mds-button>
        </mds-col>
      </mds-row>
    </div>
  </div>
</template>

<script>
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsDatePicker from '@mds/date-picker';
import MdsRadioButton from '@mds/radio-button';
import MdsTextarea from '@mds/textarea';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import _ from 'lodash';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import { _editSingleVarCB } from '../../../scripts/utils/Manager';

export default {
  name: 'WorkflowSingleVarEditAction',
  components: {
    MdsForm,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsInput,
    MdsDatePicker,
    MdsTextarea,
    MdsComboBox,
    MdsFieldset,
    MdsButtonContainer,
    MdsRadioButton,
    MdsNotification,
    MdsNotificationContainer,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeVarNameEdit: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    badFormulaBubbles: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showLoader: false,
      headers: [
        {
          text: 'Name',
          fieldName: 'name',
          style: {
            width: '150px',
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          style: {
            width: '150px',
          },
        },
      ],
      paramHeaders: [],
      inputData: [],
      toggle: false,
      currentInputToEdit: {
        name: '',
        value: '',
        type: '',
      },
      allUserDataSources: {},
      userFeeds: [],
      dataSetForSources: [],
      selectedSource: [],
      dataSetForFeed: [],
      selectedFeed: [],
      currentFeedDetails: {},
      dataSetForColumns: [],
      disableColumns: false,
      selectedColumns: [],
      currentFeedRoots: [],
      currentInputIsForwardCurve: false,
      selectedKeys: {},
      selectedRoots: [],
      currentInputIsVariableType: false,
      variableDataType: '',
      variableValue: '',
      minMaxDates: {
        min: new Date(1970, 0, 1),
        max: new Date(2050, 11, 31),
      },
      disableCopyButton: true,
      multiFeed: false,
      variableName: '',
      variableDataTypeObj: [],
      declaredVarNames: [],
      nameError: false,
      varValueError: false,
      varTypeError: false,
      manager: {},
      showError: false,
      paramSet: {},
      multiValue: false,
      prevVaribaleName: '',

      // Parameter model status
      updateParameterSetData: {
        add: [],
        update: [],
        delete: { psgIds: [], paramIds: [] },
        psgToRename: [],
        currentPsgVersion: '1',
      },
      updatedVariableMap: {},
      addVariableMap: {},
    };
  },
  computed: {
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots']),
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
    selectionStatus() {
      // filter your rows by the checked property
      const filtered = this.inputData.filter(row => row.checked === true);

      // isIndeterminate = there are more than one rows checked but not all
      // isAllSelected = all rows are checked
      return {
        isIndeterminate: filtered.length > 0 && filtered.length < this.inputData.length,
        isAllSelected: filtered.length === this.inputData.length,
      };
    },

  },
  beforeMount() {
    this.multiValue = false;
    this.paramSet = this.getEditableWorkflowDetails.paramSet;
    this.manager = this.getCurrentWorkflowManager;

    if (this.manager._priv) {
      this.updateParameterSetData = this.manager._priv.updateParameterSetData;
      if (this.updateParameterSetData.update.length > 0) {
        const updatedVariableMapObj = {};
        for (let i = 0; i < this.updateParameterSetData.update.length; i++) {
          const element = this.updateParameterSetData.update[i];
          const varName = element.propKey.replace('udef.var.', '').split('.')[0];
          if (updatedVariableMapObj[`${varName}`]) {
            updatedVariableMapObj[`${varName}`] = updatedVariableMapObj[`${varName}`];
          } else {
            updatedVariableMapObj[`${varName}`] = [];
          }
          updatedVariableMapObj[`${varName}`].push(i);
        }
        this.updatedVariableMap = updatedVariableMapObj;
      }

      if (this.updateParameterSetData.add.length > 0) {
        const addVariableMapObj = {};
        for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
          const element = this.updateParameterSetData.add[i];
          let varName = '';
          for (let j = 0; j < element.parameterModels.length; j++) {
            const e = element.parameterModels[j];
            // eslint-disable-next-line prefer-destructuring
            varName = e.propKey.replace('udef.var.', '').split('.')[0];
            if (addVariableMapObj[`${varName}`]) {
              addVariableMapObj[`${varName}`] = addVariableMapObj[`${varName}`];
            } else {
              addVariableMapObj[`${varName}`] = [];
            }
          }
          addVariableMapObj[`${varName}`].push(i);
        }
        this.addVariableMap = addVariableMapObj;
      }
    }

    this.inputData = JSON.parse(JSON.stringify(this.manager.inputData));
    this.inputData.forEach((row) => {
      row.visible = true;
    });
    this.paramHeaders = this.manager.paramHeaders;
    this.colTypes = this.manager.colTypes;
    this.rawPsgModel = this.manager.rawPsgModel;
    let updateBody = this.manager._priv.updateParameterSetData;
    if (!updateBody) {
      updateBody = {
        add: [], update: [], delete: { psgIds: [], paramIds: [] }, psgToRename: [],
      };
      this.manager._priv.updateParameterSetData = updateBody;
    } else {
      this.psgToAdd = updateBody.add;
      this.modifiedParameters = updateBody.update;
      this.deletedParameters = updateBody.delete;
      this.renamedRows = updateBody.psgToRename;
    }

    let lastValue = '';
    if (!this.isNewNode) {
      this.variableName = this.nodeVarNameEdit.replace(/[^a-zA-Z0-9_]/g, '');
      this.currentInputToEdit = this.inputData[0][this.variableName];
      this.currentInputIsVariableType = this.currentInputToEdit.isValueType;
      this.currentInputToEdit.name = this.variableName;
      this.currentInputToEdit.prevName = this.variableName;
      try {
        const element = this.inputData[0][this.variableName];
        this.variableValue = element.value;

        // finding the value of variable
        if (this.node.title === 'single_var') {
          for (let i = 0; i < this.workflow.ui.single_vars.length; i++) {
            const e = this.workflow.ui.single_vars[i];
            if (e.bubbleId === this.node.id) {
              this.variableDataType = e.varType.toLowerCase();
              this.variableDataTypeObj = [`${this.variableDataType}`];
            }
          }
        }
      } catch {
        this.variableDataType = 'date';
        this.variableDataTypeObj = ['date'];
        this.variableValue = this.inputData[0][this.variableName].value;
      }


      for (let i = 0; i < this.inputData.length; i++) {
        const element = this.inputData[i][this.variableName];

        if (this.inputData.length > 1) {
          if (i === 0) {
            lastValue = element.value;
          }

          if (lastValue !== element.value) {
            this.multiValue = true;
          }
          lastValue = element.value;
        } else {
          lastValue = element.value;
        }
      }
    } else {
      this.variableName = '';
      this.variableDataType = '';
      this.variableDataTypeObj = [];
      this.variableValue = '';
    }

    this.showLoader = false;
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    ...mapActions('workflowModule', [
      'getUserWorkflows',
      'getWorkflowStatus',
      'getFormulasForWorkflow',
      'setUserWorkflow',
      'getParameterSet',
      'setCurrentEditableWorkflow',
      'setNewlyCreateWorkflow',
      'setCurrentEditableWorkflow',
      'setCurrentWorkflowManager',
      'setCurrentEditParamSet',
      'setCurrentEditWorkflowUI',
    ]),
    hideError() {
      this.showError = false;
    },
    init() {

    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    overwriteAllProducts() {
      // toggling the view
      this.multiValue = !this.multiValue;
      this.variableDataType = '';
      this.variableDataTypeObj = [];
      this.variableValue = '';
    },
    saveForm() {
      if (this.declaredVarNames.includes(this.variableName) || this.variableName === '') {
        this.nameError = true;
        return;
      }
      if (this.variableValue === '') {
        this.varValueError = true;
        return;
      } if (
        (this.variableDataTypeObj[0] === 'float' || this.variableDataType === 'number')
        && !(new RegExp(/[+-]?([0-9]*[.])?[0-9]+$/).test(this.variableValue))
      ) {
        this.varValueError = true;
        return;
      } if (this.variableDataTypeObj[0] === 'json') {
        try {
          const jsonObject = JSON.parse(this.variableValue);
        } catch (error) {
          this.varValueError = true;
          return;
        }
      }

      if (this.variableDataTypeObj[0] === '') {
        this.varTypeError = true;
        return;
      }
      this.submitForm();
    },
    updateCurrentInput() {
      this.manager = this.getCurrentWorkflowManager;
      this.currentInputToEdit.name = this.variableName;
      this.currentInputToEdit.type = this.variableDataTypeObj[0].toUpperCase();

      if (this.variableDataTypeObj[0] === 'date') {
        this.currentInputToEdit.value = this.convertDateToSave(this.variableValue);
      } else {
        this.currentInputToEdit.value = this.variableValue;
      }

      // -----------------------------------------------
      let flag = 0;
      if (!this.isNewNode) {
        if (this.currentInputToEdit.prevName !== this.currentInputToEdit.name) {
          flag = 1;
        }
      } else {
        flag = 1;
      }
      const dataObj = {
        mgr: this.manager,
        ui: this.getEditableWorkflowUI.ui,
        varName: this.variableName.trim(),
        isVarNameEdited: flag,
        prevVariableName: this.currentInputToEdit.prevName,
        currentVariableName: this.currentInputToEdit.name,
        bubble: this.manager._priv.bubbles[this.node.id],
        dataset: this.manager._priv.singles,
        displayed: {},
        firstRow: 0,
        lastRow: Math.max(0, this.manager._priv.psgBuffer.length - 1),
        singleVarEdit: {
          value: this.currentInputToEdit.value,
          dataType: this.variableDataTypeObj[0].toUpperCase(),
          varName: this.variableName,
        },
        operation: 'edit',
      };
      if (!_editSingleVarCB(dataObj, this)) {
        return true;
      }
      return false;
    },
    braodcastFormulaErrorMsg() {
      this.$emit('gotFormulaError', this.badFormulaBubbles, this.errorMessage);
    },
    convertDateToSave(str) {
      const date = new Date(str);
      const mnth = (`0${ date.getMonth() + 1}`).slice(-2);
      const day = (`0${ date.getDate()}`).slice(-2);
      return [date.getFullYear(), mnth, day].join('-');
    },
    submitForm() {
      if (this.updateCurrentInput()) {
        return;
      }

      // Existing data bubble values Edited
      // 1. Variable Name changed
      // 2. feilds value changed
      if (!this.isNewNode) {
        const varsToAdd = {};
        this.updateParameterSetData.add.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              varsToAdd[p.propKey] = p;
            });
          }
        });

        const varsToUpdate = {};
        this.updateParameterSetData.update.forEach((p) => {
          varsToUpdate[p.propKey] = p;
        });

        // update if already present in updateParameterSetData.add
        if (Object.hasOwn(varsToAdd, `udef.var.${this.currentInputToEdit.prevName}`)) {
          this.updateParameterSetData.add.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels.forEach((p) => {
                if (p.propKey === `udef.var.${this.currentInputToEdit.prevName}`) {
                  p.propKey = `udef.var.${this.currentInputToEdit.name}`;
                  p.propValue = this.currentInputToEdit.value;
                }
              });
            }
          });
        }
        // update if already present updateParameterSetData.update
        if (Object.hasOwn(varsToUpdate, `udef.var.${this.currentInputToEdit.prevName}`)) {
          this.updateParameterSetData.update.forEach((p) => {
            if (p.propKey === `udef.var.${this.currentInputToEdit.prevName}`) {
              p.propKey = `udef.var.${this.currentInputToEdit.name}`;
              p.propValue = this.currentInputToEdit.value;
            }
          });
        } else {
          // if not present then add in updateParameterSetData.update if param has id
          this.parameterSetData.parameterSetModels.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels.forEach((p) => {
                if (p.propKey === `udef.var.${this.currentInputToEdit.prevName}`) {
                  p.propKey = `udef.var.${this.currentInputToEdit.name}`;
                  p.propValue = this.currentInputToEdit.value;
                  if (Object.hasOwn(p, 'id')) {
                    this.updateParameterSetData.update.push(p);
                  }
                }
              });
            }
          });
        }

        // update this.parameterSetData.parameterSetModels and inputData rows
        this.parameterSetData.parameterSetModels.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey === `udef.var.${this.currentInputToEdit.prevName}`) {
                p.propKey = `udef.var.${this.currentInputToEdit.name}`;
                p.propValue = this.currentInputToEdit.value;
              }
            });
          }

          const index = this.inputData.findIndex(row => row.name === psg.name);
          if (index >= 0 && Object.hasOwn(this.inputData[index], '_currentServerModel')) {
            this.inputData[index]._currentServerModel.parameterModels.forEach((p) => {
              if (p.propKey === `udef.var.${this.currentInputToEdit.prevName}`) {
                p.propKey = `udef.var.${this.currentInputToEdit.name}`;
                p.propValue = this.currentInputToEdit.value;
              }
            });
            this.inputData[index]._isModified = true;
          }
        });
        this.$emit('variableBubbleEdited', this.parameterSetData, this.currentInputToEdit, this.node);
      } else {
        const currentupdateParameterSetData = {
          add: this.manager._priv.updateParameterSetData.add,
        };
        for (let i = 0; i < this.parameterSetData.parameterSetModels.length; i++) {
          let currentupdateParameterSetDataObj = {};
          if (Object.hasOwn(this.parameterSetData.parameterSetModels[i], 'id')) {
            currentupdateParameterSetDataObj = {
              id: this.parameterSetData.parameterSetModels[i].id,
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              uuid: this.parameterSetData.parameterSetModels[i].uuid,
              parameterModels: [],
            };
          } else {
            currentupdateParameterSetDataObj = {
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              parameterModels: [],
            };
          }
          const prop = { propKey: '', propValue: '' };

          prop.propKey = `udef.var.${this.currentInputToEdit.name}`;
          prop.propValue = this.currentInputToEdit.value;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          // eslint-disable-next-line vue/no-mutating-props
          this.parameterSetData.parameterSetModels[i].parameterModels.push(...currentupdateParameterSetDataObj.parameterModels);
          if (Object.hasOwn(currentupdateParameterSetDataObj, 'id')) {
            currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
          } else {
            const index = currentupdateParameterSetData.add.findIndex(p => p.name === currentupdateParameterSetDataObj.name);
            if (index >= 0) {
              currentupdateParameterSetData.add[index].parameterModels = _.unionBy(currentupdateParameterSetDataObj
                .parameterModels, currentupdateParameterSetData.add[index].parameterModels, 'propKey');
            } else {
              currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
            }
          }
          const index = this.inputData.findIndex(row => row.name === currentupdateParameterSetDataObj.name);
          if (index >= 0 && Object.hasOwn(this.inputData[index], '_currentServerModel')) {
            this.inputData[index]._currentServerModel.parameterModels.push(...currentupdateParameterSetDataObj.parameterModels);
            this.inputData[index]._isModified = true;
          }
        }
        this.updateParameterSetData.add = currentupdateParameterSetData.add;
        this.$emit('newVariableBubbleAdded', this.parameterSetData, this.currentInputToEdit, this.node);
      }
      this.multiValue = false;
    },
    isEditedAgain() {
      if (this.updateParameterSetData.add.length > 0) {
        const arr = [];
        for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
          for (let j = 0; j < this.updateParameterSetData.add[i].parameterModels.length; j++) {
            const element = this.updateParameterSetData.add[i].parameterModels[j];
            if ((this.currentInputToEdit.name === this.currentInputToEdit.prevName)
              && element.propKey.startsWith(`udef.var.${this.currentInputToEdit.name}`)) {
              return 0;
            }
            if (element.propKey.startsWith(`udef.var.${this.currentInputToEdit.prevName}`)) {
              arr.push(i);
              break;
            }
          }
        }
        this.updateParameterSetData.add = this.updateParameterSetData.add.filter((_, index) => !arr.includes(index));
        return 1;
      }
      return -1;
    },
    typeChangeListener() {
      // eslint-disable-next-line prefer-destructuring
      this.variableDataType = this.variableDataTypeObj[0];
      this.variableValue = '';
    },
    disableTab(event) {
      // Prevent the default behavior for the tab key
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    },
  },
};
</script>
<style>
.stp-table {
  border: 1px solid #515151;
  padding: 2%;
  border-radius: 3px;
  height: 300px;
  overflow-y: scroll;
}
#fullscreen .mds-select___Mcd-ui {
  margin-top: 2% !important;
}
.wf-edit-action {
  height: 96% !important;
  padding: 1%;
  overflow-y: auto;
  overflow-x: hidden;
}
.wf-edit-action form {
  height: 92% !important;
}
#fullscreen .btn-row {
  padding-top: 1%;
}
#fullscreen .disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.5;
  display: block !important;
}
#fullscreen .mds-textarea___Mcd-ui {
  height: 300px;
}
</style>
